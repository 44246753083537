import common from 'infra/utils/common';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import {
    getTvInfo,
    getSegmentIds,
    createAudienceTargetTaxonomy,
    createAudienceTargetUserList,
    createAmplifiedAudience,
    createAlwaysOnAudience,
    createDeterministicAudience,
    createUserListForDeterministicAudience,
    getFullDemographicsDataWithGenderAgeBySegment,
    getTvNetworksMetaData,
    getDataContract,
    getTaxonomyCategory,
    getAmplifiedEstimatedReachGoal,
} from '../../react/services/AudienceInsightsService';
import { isActivateAudienceEnabled, isDeterministicDynamicActivationAllowed, deterministicPermissionName } from "../../data/audience-segment-builder-helper";

const angular = require("angular"),
    BaseWidget = require("../base_widget");

AudienceTvWidgetController.$inject = ['context', 'audienceTableStructure', 'util', 'dspService', 'notificator',
    'abiPermissions', '$timeout', 'ssoService', '$q'];

function AudienceTvWidgetController(context, audienceTableStructure, util, dspService, notificator,
                                    abiPermissions, $timeout, ssoService, $q) {
    let $scope = this.$scope;
    this.channel = context.current.audience_app.current_channel.value;
    const audienceAppForChannel = context.current.audience_app[this.channel];
    this.segment = audienceAppForChannel.audience_segment;
    $scope.channel = this.channel;
    $scope.audienceSegment = this.segment;
    $scope.audienceId = audienceAppForChannel.audience_id;
    $scope.audienceName = audienceAppForChannel.audience_name;
    $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled;
    $scope.isActivateAudienceEnabled = false;
    $scope.activateAudienceDisabledText = '';
    $scope.isActivateAudienceVisible = false;
    $scope.isActivateTargetEnabled = audienceAppForChannel.is_activate_target_enabled;
    $scope.isAudienceDeterministicActivated = audienceAppForChannel.is_audience_deterministic_activated;
    $scope.isAudienceAmplifiedActivated = audienceAppForChannel.is_audience_amplified_activated;
    $scope.isAudienceAlwaysOnActivated = audienceAppForChannel.is_audience_always_on_activated;
    $scope.activatedAmplifiedThreshold = audienceAppForChannel.activated_amplified_threshold;
    $scope.activatedAlwaysOnThreshold = audienceAppForChannel.activated_always_on_threshold;
    $scope.activatedMarket = audienceAppForChannel.activated_market;
    $scope.activatedAdvertiser = audienceAppForChannel.activated_advertiser;
    $scope.activatedDataContractId = audienceAppForChannel.activated_data_contract_id;
    $scope.activatedDataContractText = audienceAppForChannel.activated_data_contract_text;
    $scope.activatedCategoryId = audienceAppForChannel.activated_category_id;
    $scope.getSegmentIds = getSegmentIds;
    $scope.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = createAudienceTargetUserList;
    $scope.createAmplifiedAudience = createAmplifiedAudience;
    $scope.createAlwaysOnAudience = createAlwaysOnAudience;
    $scope.createDeterministicAudience = createDeterministicAudience;
    $scope.createUserListForDeterministicAudience = createUserListForDeterministicAudience;
    $scope.dspService = dspService;
    $scope.notificator = notificator;
    $scope.ssoService = ssoService;
    $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
    $scope.getDataContract = getDataContract;
    $scope.getTaxonomyCategory = getTaxonomyCategory;
    $scope.isDeterministicDynamicAllowed = isDeterministicDynamicActivationAllowed(this.segment);
    $scope.allowDeterministicSegment = abiPermissions.hasPermission(deterministicPermissionName);
    $scope.getAmplifiedEstimatedReachGoal = getAmplifiedEstimatedReachGoal;
    updateAudienceActivationUi();

    const audienceTables = audienceTableStructure.tableStructure;
    const demographicsPromise = getFullDemographicsDataWithGenderAgeBySegment(this.segment, {channel: context.current.audience_app.current_channel.value});
    const tooWideMsg = 'The audience you have selected is too wide for TV shows analysis. Please refine your audience criteria.';
    const tooNarrowMsg = 'The audience you have selected is too narrow for TV shows analysis. Please refine your audience criteria.';
    const networksDropdownMsg = 'Filter insights by broadcasting network';
    const outputTypes = [
        {value: 'bars', icon_v2: 'thumbnail', label: '', width: "16px", height: "14px"},
        {value: 'table', icon_v2: 'table', label: '', width: "16px", height: "14px"}
    ];
    const sortByTypes = [
        {
            value: 'interest-portion',
            label: 'Consumption',
            tooltip: 'Sort by how popular the TV show is among the audience'
        },
        {
            value: 'skew-score',
            label: 'Skew',
            tooltip: 'Sort by the extent to which the TV show is over-indexed within the audience'
        }
    ];
    const tableSearchTypes = [
        {
            value: 'tv',
            label: 'TV Shows'
        },
        {
            value: 'genres',
            label: 'Genres'
        }
    ];
    const debounceFilterDataBySelectedNetworks = _.debounce((selectedNetworks) => filterDataBySelectedNetworks(selectedNetworks), 1500);
    const isDebugUser = $scope.$root.user.userType === 'debug';
    const shouldShowNetwork = ['smart_tv_inscape', 'tivo', 'hisense'].includes(this.channel) ||
                              (isDebugUser && this.channel === 'articles');
    let prevData = {};
    let networksDropdownValues = [];
    if (shouldShowNetwork) {
        tableSearchTypes.push({value: 'networks', label: 'Networks'});
    }

    $scope.outputTypes = outputTypes;
    $scope.sortByTypes = sortByTypes;
    $scope.sortBy = 'skew-score';
    $scope.scoreView = 'interest-portion';
    $scope.selectedView = 'bars';
    $scope.query = _.cloneDeep(audienceTables.tv);
    $scope.tableSearchTypes = tableSearchTypes;
    $scope.tableData = {};
    $scope.navBackModal = { isOpen: false, onSubmitClicked: $scope.navToAudienceBuilder, onCancelClicked: $scope.navToPrevTab };
    $scope.tvType = this.channel === 'articles' ? 'Online' : 'TV';
    $scope.filterByNetworksDropdown = { selectedValues: [], isOpen: undefined, prevSelectedValues: [] };
    $scope.showNetworksDropdown = abiPermissions.hasPermission('inventory filter') && ['smart_tv_inscape', 'tivo', 'hisense'].includes(this.channel);
    $scope.networksDropdownTooltip = networksDropdownMsg;

    $scope.onSortChange = (type, sortTVOnly = true) => {
        $timeout(() => {
            MixpanelAudience.trackViewChange(type, 'Audience TV widget', '', $scope.channel);
            $scope.sortBy = type;
            if (_.isEmpty($scope.tableData)) return;
            const sortKeys = sortTVOnly ? ['tv'] : ['tv', 'genres', 'networks'];
            _.each(sortKeys, val => {
                $scope.tableData[val] = _.orderBy($scope.tableData[val], type, 'desc');
            });
        });
    };

    $scope.onSearchTypeChange = (type) => {
        if(type !== 'tv') {
            $scope.selectedView = 'table';
        }
        MixpanelAudience.trackTVShowsViewershipType(type, $scope.channel);
        MixpanelAudience.trackTVShowsView($scope.selectedView, $scope.channel);
        if (_.isEmpty($scope.tableData[type])) return;
        _.extend($scope.query, audienceTables[type]);
        $scope.query.title = type;
        $scope.$watch('::query', (q) => { if(q) q.show($scope.tableData[type]) });
    };

    $scope.onOutputViewsChange = (type) => {
        if(type === 'bars') {
            $scope.searchType = 'tv';
            MixpanelAudience.trackTVShowsViewershipType($scope.searchType, $scope.channel);
            MixpanelAudience.trackTVShowsView($scope.selectedView, $scope.channel);
            refreshTvShowImageView();
        } else {
          $scope.onSearchTypeChange($scope.searchType); //To refresh table according to selected
        }
    };

    // Temp fix performance issue of rendering too many shows
    $scope.getTopShows = () => (($scope.tableData.tv || []).slice(0,50));

    $scope.exportControl.getAudienceTvExportDependencies = () => {
        return Promise.all([$scope.loadingPromise, demographicsPromise, $q.resolve($scope.filterByNetworksDropdown.selectedValues)])
    };

    $scope.$root.createExcelWorkbook = $scope.exportControl.export;

    async function getData(selectedNetworks = null) {
        const data = await getTvInfo($scope.audienceSegment, $scope.channel, shouldShowNetwork, selectedNetworks)

        try {
            if(!data) throw 'noData';
            if(data.status === 'noData') throw 'noData';
            if(data.status === 'segment too wide') throw 'tooWide';
            if(data.status === 'insufficient data') throw 'tooNarrow';
        }
        catch(reason){
            return handleMissingData(reason, !_.isEmpty(selectedNetworks));
        }

        $scope.filterByNetworksDropdown.prevSelectedValues = $scope.filterByNetworksDropdown.selectedValues;
        prevData = data;

        return Promise.resolve(data).then(handleResponseData)
    }

    $scope.loadingPromise = getData();

    $scope.tvNetworksMetadataPromise = Promise.all([$scope.loadingPromise, getTvNetworksMetaData(this.channel)]).then(([data, networks]) => {
        networksDropdownValues = networks;
        return networks;
    });

    async function updateAudienceActivationUi() {
        const channel = $scope.channel;
        const channelAudience = context.current.audience_app[channel];
        const tempAudience = {
            id: channelAudience.audience_id,
            name: channelAudience.audience_name,
            segment: channelAudience.audience_segment,
            activation: channelAudience.audience_activation,
        };
        $scope.isActivateAudienceEnabled = false;
        $scope.activateAudienceDisabledText = '';
        const {isEnabled, isVisible, disabledText} = await isActivateAudienceEnabled(abiPermissions, channel, tempAudience, $scope.$root.user.id);
        $scope.isActivateAudienceEnabled = isEnabled;
        if ($scope.isActivateAudienceEnabled) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');

        $scope.isActivateAudienceVisible = isVisible;
        $scope.activateAudienceDisabledText = disabledText;
        $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled || isVisible;
        $scope.$digest();
    }

    $scope.updateAudienceDeterministicActivated = function (activatedMarket, activatedAdvertiser) {
        common.updateAudienceDeterministicActivated(activatedMarket, activatedAdvertiser, context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.updateAudienceAmplifiedActivated = function (activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold) {
        common.updateAudienceAmplifiedActivated(activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold,
                                                context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.updateAudienceAlwaysOnActivated = function (activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                       activatedAlwaysOnThreshold) {
        common.updateAudienceAlwaysOnActivated(activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                               activatedAlwaysOnThreshold, context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.onNetworkChange = (selectedNetworks) => {
        $scope.filterByNetworksDropdown.selectedValues = selectedNetworks;
        $scope.$apply();
        if (_.isEmpty(selectedNetworks) || selectedNetworks.length == networksDropdownValues.length) return debounceFilterDataBySelectedNetworks(null);
        debounceFilterDataBySelectedNetworks(selectedNetworks);
    };

    async function filterDataBySelectedNetworks(selectedNetworks) {
        $scope.filterByNetworksDropdown.isOpen = false;
        $scope.loadingPromise = getData(selectedNetworks);
        $scope.$apply();
        await $scope.loadingPromise;
        $scope.filterByNetworksDropdown.isOpen = undefined;
        $scope.networksDropdownTooltip = (selectedNetworks || []).length > 1 ? _.map(selectedNetworks, 'label').join(', ') : networksDropdownMsg;
        $scope.$apply();
    }

    function normalizeDataScores(data, correctTooltip = false) {
        _.each(data, item => {
            item['skew-score'] = item['uniqueness-index'];
            if (correctTooltip) item['phrase'] = item['title']; // For correct tooltip lookup
        });
        util.normalize(data, 'uniqueness-index', 'skew-score');
        util.normalize(data, 'interest-portion', 'interest-portion-normalized');
    }

    async function handleResponseData(data) {
        normalizeDataScores(data.shows, true);
        normalizeDataScores(data.genres);
        normalizeDataScores(data.networks);

        $scope.$watch('::query', (q) => { if(q?.show) q.show(data.shows) });
        $scope.tableData.tv = data.shows;
        $scope.tableData.genres = data.genres;
        $scope.tableData.networks = data.networks;
        $scope.onSortChange($scope.sortBy, false);
        if ($scope.selectedView === 'table' && $scope.searchType != 'tv') $scope.onSearchTypeChange($scope.searchType);

        return $scope.tableData;
    }

    function openNavBackModal(msg, title, isSelectedNetworks) {
        $scope.showNoDataBG = !isSelectedNetworks;
        $scope.navBackModal = {
            isOpen: true,
            title: title,
            message: `${msg} ${isSelectedNetworks ? 'Please change or expand your filter selection to see insights.' : 'Please refine your audience criteria.'}`,
            submitLabel: isSelectedNetworks ? 'Back' : 'Refine Audience',
            cancelLabel: isSelectedNetworks ? null : 'Back',
            onSubmitClicked: isSelectedNetworks ? closeNavBackModal : $scope.navToAudienceBuilder,
            onCancelClicked: isSelectedNetworks ? null : $scope.navToPrevTab
        };
        $scope.$apply();
    }

    function handleMissingData(reason, isSelectedNetworks) {
        switch(reason) {
            case 'noData':
            case 'tooNarrow':
                openNavBackModal(tooNarrowMsg, 'Audience is too narrow', isSelectedNetworks);
                break;
            case 'tooWide':
                openNavBackModal(tooWideMsg, 'Audience is too wide', isSelectedNetworks);
                break;
        }
    }

    function refreshTvShowImageView() {
        angular.element(document).find('tv-show-info .dotdotdot').each(() => {
            $(this).dotdotdot();
        })
    }

    function prevStateOfFilteredNetworks () {
        handleResponseData(prevData);
        $scope.filterByNetworksDropdown.selectedValues = $scope.filterByNetworksDropdown.prevSelectedValues;
    }

    function closeNavBackModal () {
        handleResponseData(prevData);
        $scope.filterByNetworksDropdown.selectedValues = $scope.filterByNetworksDropdown.prevSelectedValues;
        $scope.navBackModal.isOpen = false;
        $scope.$apply();
    }
}

module.exports = require("angular").module(__filename, [
    require('common/modals/confirmation/confirm-action.modal.service').name,
])
    .directive("audienceTvWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-tv-widget.html"),
        scope: {
            handleActivatingAudience: '&',
            handleActivatingTarget: '&',
            navToPrevTab: '&',
            navToAudienceBuilder: '&',
            exportControl: '=',
            selectedTab: '=',
        },
        controller: AudienceTvWidgetController,
    })]);