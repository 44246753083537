ViewSwitcherController.$inject = ['$scope', '$state', '$rootScope', 'abiPermissions', 'tabsService'];

function ViewSwitcherController($scope, $state, $rootScope, abiPermissions, tabsService) {
  $scope.$state = $state;
  this.$state = $state;

  var init = () => $scope.tabs = tabsService.getTabsByApp($scope.app);

  $scope.tabClicked = (tab) => {
    $rootScope.helpPage = tab.helpPage;
    $state.go($scope.app + '.' + tab.name);
  };

  $scope.isSelected = (tab) => {
    return $state.current.name.includes("grid") && tab.name.includes("grid") ||
           $state.includes($scope.app + '.' + tab.name);
  }

  $scope.moreThanOneButton = function() {
    let activeButtons = _.filter($scope.tabs, (s) => abiPermissions.hasPermission(s.permission) && !s.hideTab);
    return activeButtons.length > 1;
  };

  $scope.$watch(() => tabsService.getWatchAttributesByApp($scope.app), init);

}

module.exports = angular.module(__filename, []).directive('amViewSwitcher', [function() {
  return {
    restrict:'E',
    template:require('./view-switcher.html'),
    scope: {
      app: '='
    },
    controller: ViewSwitcherController
  }
}]

).service('tabsService', ['context', 'abiPermissions', function(context, abiPermissions){

    return {getTabsByApp, getWatchAttributesByApp};

    function getTabsByApp(app) {
      switch(app){
        case 'discovery':
          return getDiscoveryTabs();
        case 'insights':
          return getInsightsTabs();
        default:
          return [];
      }
    }

    function getWatchAttributesByApp(app) {
      switch(app) {
        case 'discovery':
          return context.current.gridChannels?.value;
        case 'insights':
          return context.current.insightsChannels?.value;
        default:
          return app;
      }
    }

    function getDiscoveryTabs() {
      return [
        {
          name: 'bubbles',
          display: 'Trending Interests',
          permission: 'trending interests',
          helpPage: 'Trending_Interests'
        },
        {
          name: 'streams',
          display: 'Content Streams',
          permission: 'content streams',
          helpPage: 'Content_Streams'
        },
        {
          name: 'grid.phrases.web',
          display: 'Channel Insights',
          permission: 'channel insights',
          helpPage: 'Channel_Insights.',
          hideTab: context.current.gridChannels.value === 'facebook'
        },
        {
          name: 'grid.interests.facebook',
          display: 'Channel Insights',
          permission: 'channel insights',
          helpPage: 'Channel_Insights.',
          hideTab: context.current.gridChannels.value !== 'facebook'
        }
      ];
    }

    function getInsightsTabs() {
      return [
        {
          name: 'consumption',
          display: 'Consumption Trends',
          permission: 'consumption trends',
          helpPage: '4_3_Consumption_Trends',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'facebook channel',
                                                       'sg telco channel', 'au telco channel'])
        },
        {
          name: 'association',
          display: 'Associations',
          permission: 'associations',
          helpPage: '4_6_Associations',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel', 'au telco channel'])
        },
        {
          name: 'sentiment',
          display: 'Sentiment',
          permission: 'sentiment',
          helpPage: '4_5_Sentiment',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'facebook channel',
                                                       'sg telco channel', 'au telco channel'])
        },
        {
          name: 'timing',
          display: 'Timing',
          permission: 'timing',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel'])
        },
        {
          name: 'landscape',
          display: 'Landscape',
          permission: 'landscape',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'facebook channel', 'sg telco channel'])
        },
        {
          name: 'topic',
          display: 'Topics',
          permission: 'topics',
          helpPage: '4_4_Topics',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'video channel', 'sg telco channel',
                                                       'au telco channel'])
        },
        {
          name: 'channels',
          display: 'Channels',
          permission: 'channels',
          hideTab: !abiPermissions.hasSomePermissions(['web channel', 'sg telco channel', 'au telco channel'])
        },
        {
          name: 'geo',
          display: 'Geos',
          displayForChannels: ['articles', 'sg_telco', 'sg_bidstream', 'bidstream', 'au_telco'],
          permission: 'geos',
          rootScopeField: 'geoInsightsChannelsFilter',
          hideTab: !abiPermissions.hasSomePermissions(['geos.US', 'sg telco channel', 'au telco channel']) }
      ];
    }
  }]);
