/**
 * Created by Pery on 27/01/2015.
 */
module.exports = angular.module(__filename, [])
    .service('utils', ["$rootScope", "$timeout", "$window", 'authentication', function ($rootScope, $timeout, $window, authentication) {
        var transitionEvent = findTransitionEvent();
        var redirectUrlArr = [];

        /*API*/
        return {
            findPagehUrl: findPagehUrl,
            triggerResize: triggerResize,
            triggerResizeOnTransitionEnd: triggerResizeOnTransitionEnd
        };

          //go thru the state tree and extract the url param
          //uses it to build the actual page url EX: intelligence.amobee.com/#/warroom/groups
          function findPagehUrl(key, val) {
            if(key == "url"){
              var path = (val == "^")? "#" : val
              buildRedirectUrl(path)
            }

            var value = val['parrent'];
            if (key == "parent") {
                $.each(val, function(key, value) {
                    findPagehUrl(key, value)
                });
            }

          }

          function buildRedirectUrl(path){
            redirectUrlArr.push(path)
            //end of cycle - now we have full path
            if(path == "#"){
              var fullRedirectUrl = document.location.origin + "/" + redirectUrlArr.reverse().join("");
              authentication.setRedirectUrl(fullRedirectUrl);
              redirectUrlArr = [];
            }

          }

        function findTransitionEvent() {
            var el = document.createElement('fakeelement');
            var transitions = {
                'transition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd'
            };

            for (var t in transitions) {
                if (el.style[t] !== undefined) {
                    return transitions[t];
                }
            }
        }

        function triggerResize() {
            $timeout(function () {
                angular.element($window).trigger('resize');
                $rootScope.$broadcast('resizeTransitionEnd');
            }, 1);
        }

        function triggerResizeOnTransitionEnd($element) {
            if (transitionEvent) {
                $element.one(transitionEvent, function () {
                    triggerResize()
                })
            } else {
                triggerResize();
            }
        }
    }]
);
