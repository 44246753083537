import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import { getDemographicsDataForPreviewBySegment } from '../../react/services/AudienceInsightsService';
import { isRequestCancelled } from '../../react/services/AudienceServiceHelper';

module.exports = angular.module(__filename, []).directive("audiencePreviewWidget", [
        '$state', 'context',
        function ($state, context) {
        return {
            restrict: 'E',
            scope: {
                channel: "=",
                onAudienceDataLoaded: "=",
                marketsAndAdvertisersPromise: "=",
                audienceId: "=",
                audienceName: "=",
                audienceSegment: "=",
                isActivateEnabled: "=",
                isActivateVisible: "=",
                isActivateTargetEnabled: "=",
                isActivateAudienceEnabled: "=",
                isActivateAudienceVisible: "=",
                activateAudienceDisabledText: "=",
                onTargetAudienceSubmit: "=",
                isTargetModalProcessing: "=",
                getSegmentIds: "=",
                createAudienceTargetTaxonomy: "=",
                createAudienceTargetUserList: "=",
                createAmplifiedAudience: "=",
                createAlwaysOnAudience: "=",
                createDeterministicAudience: "=",
                createUserListForDeterministicAudience: "=",
                notificator: "=",
                dspService: "=",
                updateAudienceDeterministicActivated: "=",
                updateAudienceAmplifiedActivated: "=",
                updateAudienceAlwaysOnActivated: "=",
                isAudienceDeterministicActivated: "=",
                isAudienceAmplifiedActivated: "=",
                isAudienceAlwaysOnActivated: "=",
                activatedAmplifiedThreshold: "=",
                activatedAlwaysOnThreshold: "=",
                activatedMarket: "=",
                activatedAdvertiser: "=",
                activatedDataContractId: "=",
                activatedDataContractText: "=",
                activatedCategoryId: "=",
                ssoService: "=",
                getAmplifiedEstimatedReachGoal: "=",
                handleActivatingAudience: "=",
                handleActivatingTarget: "=",
                hasAmplificationModeSelector: "=",
                getDataContract: '=',
                getTaxonomyCategory: '=',
                isDeterministicDynamicAllowed: '=',
                isContextualUniquenessError: '=',
                allowDeterministicSegment: '='
            },
            template: require("./audience-preview-widget.html"),
            link: function ($scope) {
                $scope.audienceDistributionService = getDemographicsDataForPreviewBySegment;
                $scope.isRequestCancelledService = isRequestCancelled;
                $scope.userId = context.current.u_id;

                this.context = context;

                $scope.exploreAudience = function() {
                    context.current.audience_segment_target_ratio = null;
                    MixpanelAudience.trackExploreAudience({
                        channel: $scope.channel,
                        segment: (context.current.audience_app || {})[$scope.channel],
                    });
                    $state.go('audience-explorer');
                };

                $scope.trackTooNarrow=() => {
                    const snapshot = angular.copy(this.context.current);
                    MixpanelAudience.trackTooNarrow(snapshot);
                }

            }
        }
    }]);
