'use strict';
let mouseX = 0;

module.exports = angular.module(__filename, []).constant('CHART', {
    /* chartjs. to use:
     ******************
     1. new chart: let chart = CHART.NEW_CHART(canvas, params);
     2. update chart: update its params manually, then do: CHART.UPDATE_CHART(chart);
    */
    DEFAULT_OPTIONS: {
        legend: {
            display: false
        },
        tooltips: {
            mode: 'x-axis',
            enabled: false,
            custom: customTooltip
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#cacaca',
                    fontSize: 14,
                    fontFamily: 'helvetica',
                    maxRotation: 0,
                    maxTicksLimit: 18,
                    callback: (value, index, values) => value.substring(value.indexOf("-") + 1).replace("-", "/")
                },
                gridLines: {
                    color: '#373a44',
                    display: false
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#cacaca',
                    fontSize: 14,
                    fontFamily: 'helvetica',
                    callback: function (value, index, values) {
                        let val = Math.round(value * 100) / 100;
                        if (value >= 1000000) {
                            val = val / 1000000 + "M";
                        } else if (value >= 1000) {
                            val = val / 1000 + "K";
                        }
                        return "  " + val + " ──"; //round by 2 points
                    }
                },
                gridLines: {
                    color: ['#373a44'],
                    drawBorder: false,
                    zeroLineColor: '#373a44'
                }
            }]
        }
    },
    TERM_CLASSES: ["term-A", "term-B", "term-C", "term-D", "term-E", "term-F", "term-G"],
    NEW_CHART: function (canvas, params) {
        //dataset point style
        this.set_datasets_style(params.data.datasets);

        //create the chart
        const chart = new Chart(canvas, params), parent = $("ui-view").eq(0).find("ui-view").eq(0);

        //mouse-move
        const ev = $._data(parent[0], 'events');
        if (!ev || !ev.mousemove) {
            parent.bind("mousemove", function (e) {
                mouseX = e.clientX;
                // native tooltip mouseout fails, so:
                const offset = canvas.offset(),
                    inside = mouseX >= offset.left && mouseX <= offset.left + canvas.width() &&
                        e.clientY >= offset.top && e.clientY <= offset.top + canvas.height();

                if (!inside) {
                    $('#chartjs-tooltip').hide();
                }
            });
        }

        return chart;
    },
    UPDATE_CHART: function (chart) {
        this.set_datasets_style(chart.config.data.datasets);
        chart.update();
    },
    set_datasets_style: function (datasets) { //private function
        datasets.forEach(function (dat) {
            dat.pointBackgroundColor = dat.borderColor;

            if (undefined === dat.borderWidth) {
                dat.borderWidth = 2;
            }
            if (undefined === dat.pointRadius) {
                dat.pointRadius = 0;
            }
            if (undefined === dat.pointHoverRadius) {
                dat.pointHoverRadius = 3;
            }
            if (undefined === dat.pointHitRadius) {
                dat.pointHitRadius = 10;
            }
        });
    }
}).constant('SIGNS', {
    POST: "u:",
    MENTION: "@",
    PROGRAM_INTEREST: "pi:",
    EDITED: "*"
}).constant('DATE_FORMAT', {
    DDMMYYHHmm: "DD_MM_YY_HH_mm"
}).constant('WARROOM_GEOS', {
    geos: [
        {label: 'France', id: '250', cc: 'FR'},
        {label: 'Germany', id: '276', cc: 'DE'},
        {label: 'Italy', id: '380', cc: 'IT'},
        {label: 'Netherlands', id: '528', cc: 'NL'}
    ]
}).constant('TARGET_CONSTS', {
    DEFAULT_TARGET_TYPE:          'trends',
    AUDIENCE_TARGET_TYPE:         'audience_interests',
    INVENTORY_LISTS_TARGET_TYPE:  'inventory_lists'
}).constant('CTX_TARGETS_SKEW', {
    HIGH_SKEW: 2.5,
    MEDIUM_SKEW: 1.4,
    CONSUMPTION: 10,
    TOTAL_COUNT: 50,
    HIGH_SKEW_COUNT: 10
}).constant('CHANNEL', {
    name: function (channel) {
        const value = channel?.value ? channel.value : channel;
        return value === 'articles' ? 'web' : value;
    },
    nameDisplay: function (target) {
        switch (target.channel) {
            case 'social':
                return 'Social';
            case 'web':
                let AUDIENCE_TARGET_TYPE = 'audience_interests';
                //declared here too as we cant access its value from here
                if (target.target_type === AUDIENCE_TARGET_TYPE) {
                    return 'All';
                } else {
                    return 'Web';
                }
            case 'data_spark':
                return 'Web - SG Telco';
            case 'sg_bidstream':
                return 'Web - SG Bidstream';
            case 'bidstream':
                return 'Historical Bidstream';
            case 'snbb':
                return 'SG Telco Desktop';
            default:
                const display = target.channel.replace("_"," ");
                return display[0].toUpperCase() + display.slice(1);
        }
    }

}).constant('SENTIMENT', {
    setRowData: function (row) {
        const data = {
            pos: (row.pos || 0).toFixed(1),
            neg: (row.neg || 0).toFixed(1),
            net_sent: row.net_sent
        };

        if (Math.abs(data.net_sent) < 4) {
            data.overall = ((data.pos + data.neg) < 20 || row.uniq < 5) ? "Indifferent" : "Controversial";
        }

        if (data.net_sent >= 4) {
            data.overall = data.net_sent > 7.07 ? "Mostly Positive" : "Slightly Positive";
        } else if (data.net_sent <= -4) {
            data.overall = data.net_sent < -7.07 ? "Mostly Negative" : "Slightly Negative";
        }

        return data;
    }
}).constant('TIMES', {
  ONE_HOUR: {text: "1H", amount: 1, unit: "hour", displayText: '1 hour'},
  THREE_HOURS: {text: "3H", amount: 3, unit: "hour", displayText: '3 hours'},
  SIX_HOURS: {text: "6H", amount: 6, unit: "hour", displayText: '6 hours'},
  TWELVE_HOURS: {text: "12H", amount: 12, unit: "hour", displayText: '12 hours'},
  ONE_DAY: {text: "1D", amount: 1, unit: "day", displayText: '1 day'},
  THREE_DAYS: {text: "3D", amount: 3, unit: "day", displayText: '3 days'},
  ONE_WEEK: {text: "1W", amount: 7, unit: "day", displayText: '1 week'},
  ONE_MONTH: {text: "1M", amount: 1, unit: "month", displayText: '1 month'},
  THREE_MONTHS: {text: "3M", amount: 3, unit: "month", displayText: '3 months'},
  SIX_MONTHS: {text: "6M", amount: 6, unit: "month", displayText: '6 months'},
// when dealing with a year, the request is to compare current month to last year month, so amount = 13.
  ONE_YEAR: {text: "1Y", amount: 13, unit: "month", displayText: '1 year'},

  INSIGHTS_OFFSET: [18, 'month'],
  DISCOVERY_OFFSET: [3, 'month'],
  DISCOVERY_CUSTOM_OFFSET: [45, 'days'],
  EXTENDED_DISCOVERY_OFFSET: [15, 'month'],

  DISCOVERY_MAX_SPAN: [3, 'month'],
  DISCOVERY_CUSTOM_MAX_SPAN: [45, 'days'],

  SENTIMENT_MIN_DATE: '19_09_22_00_00',

  FORMAT: "DD_MM_YY_HH_mm",

  getTime: function(arg) {
    const ALL_TIMES = [this.ONE_HOUR, this.THREE_HOURS, this.SIX_HOURS, this.TWELVE_HOURS, this.ONE_DAY,
                       this.THREE_DAYS, this.ONE_WEEK, this.ONE_MONTH, this.THREE_MONTHS, this.SIX_MONTHS,
                       this.ONE_YEAR];
    return (this[arg] ? this[arg] : _.find(ALL_TIMES, (t) => t.text === arg || t.displayText === arg));
  },

  getTimes: function(times) {
    const getTimeFunc = this.getTime.bind(this);
    return _.map(times, getTimeFunc);
  }
}).constant('MARKET_CONTEXT', {
    ALL_MARKETS_ID: 0,
    ALL_ADVERTISERS_ID: -1
}).constant('INSIGHTS_TIMELINE', {
    DEFAULT_INTERVAL: 'Hourly',
    DEFAULT_TIMEZONE: { label: 'UTC', value: 0 }
});

function customTooltip(tooltip) {
    // chartjs is great but its tooltip code is large...
    let tip = $('#chartjs-tooltip');

    if (!tip.length) {
        $("body").append('<div id="chartjs-tooltip"></div>');
        setTimeout(function () {
            tip = $('#chartjs-tooltip').css({
                position: 'absolute',
                zIndex: 999,
                left: 0,
                top: 0,
                width: 'auto',
                font: '13px helvetica bold',
                color: '#fff',
                textAlign: 'center',
                pointerEvents: 'none'
            });
        });
    }

    if (tooltip.body) {
        let str =
            '<div id="chartjs-tooltip-main" style="background:#151719;border-radius:4px;padding:10px;text-align:left;">' +
            '<div style="display:inline-block;vertical-align:top;">' + tooltip.title[0].replace("| ", "") + '&nbsp;</div>' +
            '<div style="display:inline-block;border-left:1px solid #444">';

        let sets = this._chart.config.data.datasets;
        const tooltipHeight = tooltip.body.length * 16;
        tooltip.body.forEach(function (body, i) {
            const label = body.lines[0].substring(0, body.lines[0].indexOf(":"));
            let set = sets.filter((s) => s.label === label)[0];

            const borderType = !set.borderDash ? 'solid' : (set.borderDash[0] >= 3 ? 'dashed' : 'dotted');
            const style = 'border: 0px ' + borderType + ' ' + set.borderColor + ';';
            const seperator = tooltip.body.length === 1 ? '' :
            '<div style="display:inline-block;width:20px;height:2px;padding-bottom:4px;border-top-width:1px !important;' + style + '"></div> ';

            str += '&nbsp;' + seperator + body.lines[0] + '<br>';
        });

        str += '</div></div>';

        let canvas = $(this._chart.canvas),
            offset = canvas.offset(),
            bottom = 35,
            top = 70;

        str += '<div id="chartjs-tooltip-line" style="display:inline-block; width:1px; height:0; background:#cacaca;"></div>';

        tip.html(str);
        setTimeout(function () {
            const lineHeight = canvas.height() - tooltipHeight;
            $("#chartjs-tooltip-line").css("height", (lineHeight + bottom) + 'px');

            tip.css({
                display: 'block',
                left: (mouseX - $("#chartjs-tooltip").width() / 2) + 'px',
                top: (offset.top - top) + 'px'
            });
        });
    } else {
        tip.css("display", 'none');
    }
}