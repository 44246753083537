import config from 'infra/config';
import c from 'infra/utils/common';
import * as TargetComplianceService from 'react/services/TargetComplianceService';
import * as MixpanelDiscovery from '../../react/infra/mixpanel/MixpanelDiscovery';

const audienceSegmentBuilderHelper = require('data/audience-segment-builder-helper');

require("pages/targets/targets-common");
require("data/audience-skew-service");

gridController.$inject = ["$scope", "$http", 'audienceMgmt', 'filtersPartition', 'TargetsCommon', 'context', 'Grid', 'GRID_QUERIES', 'CHANNEL', 'SENTIMENT', 'MARKET_CONTEXT', 'alertsService', '$state',
    '$rootScope', '$filter', '$timeout', 'Excel', 'ModalService', 'sentimentsPopupService', 'notificator', 'util', 'geoService', 'abiPermissions', 'audienceSkewService', 'TIMES', 'TARGET_CONSTS'];
function gridController(scope, $http, audienceMgmt, filtersPartition, TargetsCommon , context, Grid, GRID_QUERIES, CHANNEL, SENTIMENT, MARKET_CONTEXT, alertsService, $state, $rootScope, $filter, $timeout,
                        Excel, ModalService, sentimentsPopupService, notificator, util, geoService, permissions, audienceSkewService, TIMES, TARGET_CONSTS) {

    $rootScope.helpPage = 'Channel_Insights';

    scope.openComplianceModal = false;
    scope.nonCompliantPhrases = {};
    scope.nonCompliantPhrasesByTab = {};
    scope.sentimentsPopupService = sentimentsPopupService;
    scope.$state = $state;
    scope.Math = window.Math;
    scope.prediction = {};
    scope.channelName = CHANNEL.name;
    scope.tab = null;
    scope.tabs = [];
    scope.query = null;
    scope.Grid = Grid;
    scope.currentTarget = {};
    scope.targets = [];
    scope.contentDriversTargets = true;
    if (context.current.gridChannels == null ||
        (context.current.gridChannels.permission != null &&
         !permissions.hasPermission(context.current.gridChannels.permission))) {
        context.current.gridChannels = c.getAvailableChannelFromContext($rootScope.gridChannelsFilter, permissions);
        if(!context.current.gridChannels.value) {
          context.current.gridChannels = $rootScope.gridChannelsFilter[0];
        }
    }

    const NA = 'N/A';

    scope.sentimentsPopupService.reset($(".main-content-page quick-table"));

    //compliance
    scope.onCloseCompliance = ()=>{
        scope.openComplianceModal = false;
        $timeout(() => scope.$apply());
    }

    scope.saveWithNonCompliant = () => {
        if(scope.query.selected.length){
            TargetsCommon.addToCurrentTarget({state: $state, scope, skipCompliance: true});
            TargetComplianceService.addNonCompliantPhrases(scope.nonCompliantPhrases);
        } else {
            TargetsCommon.clearSelection(scope);
        }
        scope.onCloseCompliance();
    }

    scope.saveWithoutNonCompliant = () => {
        scope.query.selected = scope.query.selected.filter((p) => {
            let phrase = TargetComplianceService.entityText(p, scope.tab);
            let phraseObj = scope.complianceResults[phrase];
            return TargetComplianceService.isPhraseObjCompliance(phraseObj);
        });
        scope.nonCompliantPhrases = {};
        scope.saveWithNonCompliant();
    }

    //audience
    scope.customSegments = [];
    scope.lifestyles = audienceSkewService.getAudienceLifeStyle(filtersPartition, scope.test_mode);
    scope.lifestyleOrig = {label: 'Select Audience',value: ''};
    scope.lifestyle = [scope.lifestyleOrig];

    scope.setQuery = function () {
        MixpanelDiscovery.trackPageView('grid');
        //reset query params when switching tabs
        //gridChannelsFilter
        const channel = CHANNEL.name(context.current.gridChannels),
              geos = context.current.geo,
              language = context.current._language_mold._value? context.current._language_mold._value.value : context.current._language_mold.getDefaultLanguage();
        let tab = $state.current.name.replace("discovery.grid.", "").split(".")[0];

        if (tab === scope.tab && scope.oldChannel === channel &&
            language === scope.oldLanguage && _.isEqual(scope.oldGeos, geos)) { //same tab, language & geos
            return;
        }

        scope.oldChannel = channel;
        scope.oldLanguage = language;
        scope.oldGeos = geos;

        if (!GRID_QUERIES.QUERIES[channel][tab]) {
            //use 1st tab
            const obj = GRID_QUERIES.QUERIES[channel];
            tab = Object.keys(obj)[0];
        }

        const newQuery = GRID_QUERIES.QUERIES[channel][tab];
        GRID_QUERIES.postProcessQuery(newQuery, $state, context);

        localStorage["grid_tab_" + channel] = tab;
        scope.tab = tab;
        scope.tabs = Object.keys(GRID_QUERIES.QUERIES[channel]);

        if (null == scope.query) {
            scope.query = $.extend(true, {}, newQuery);
        } else {
            for (var i in newQuery) {
                scope.query[i] = newQuery[i];
            }
        }

        if (undefined != scope.query.reset) {
            scope.query.reset();
        }

        scope.toggleSelected = function (row, $event) {
            TargetsCommon.toggleSelected(scope, row, $event);
            scope.validateAddToTarget();
        };

    };

    //events

    scope.$watch('context.current.gridChannels', function (channel, oldChannel) {
        var tab = localStorage['grid_tab_' + CHANNEL.name(channel)] || 'phrases',
            newState = 'discovery.grid.' + tab + '.' + CHANNEL.name(channel);

        scope.tabs = Object.keys(GRID_QUERIES.QUERIES[CHANNEL.name(channel)]);

        try {
            $state.go(newState);
        } catch (er) {
            //some tabs are like hashtags, not... hashtags.social
            newState = newState.substring(0, newState.lastIndexOf("."));
            $state.go(newState);
        }

        scope.$parent.getCustomAudienceSegments(scope.context.program, scope.lifestyles, channel.value).then((segments) => {
            scope.customSegments = segments;
        });
        updateTargetsList();
    });

    const updateTargetsHandlerWatch = scope.$root.$on('updateTargets', function (event, obj) {
        TargetsCommon.updateTargetsHandler(obj, scope);
    });

    scope.$on('$destroy', function () { //exit grid
        Grid.clearCache();
        updateTargetsHandlerWatch(); //clears watch
        TargetComplianceService.unloadTarget();
    });

    scope.$watch('context.program', function (program) {
        if (!program) return;

        scope.$parent.getCustomAudienceSegments(program, scope.lifestyles, context.current.gridChannels.value).then((segments) => {
            scope.customSegments = segments;
        });
        updateTargetsList();
    });

    const refreshViewListener = $rootScope.$on('refresh-view', function () {
        scope.refreshGrid();
    });

    scope.$on('mailExport', function () {
        notificator.notify('extracting all audiences, will send you a download link shortly.');
    });



    //audience skew

    scope.onLifestyleChange = (value) => {
        scope.lifestyle = [value || scope.lifestyleOrig];
        let show = value,
            col = scope.getAudienceSkewCol();

        if (col) {
            col.hidden = !show;
        }

        if (scope.query) {
            scope.postProcessQuery(scope.query, {'search-type': 'aw'});
        }

        $timeout(() => scope.$apply())

        MixpanelDiscovery.trackAudience(value);
    };

    scope.showAudienceToggle = function() {
        return !_.isEmpty(scope.customSegments) && ['articles', 'sg_telco', 'au_telco'].includes(c.getChannels($state, context)[0]);
    };

    scope.getAudienceSkewCol = function(){
        if(scope.query && scope.query.columns){
            let cols = scope.query.columns.filter((col) => col.id === 'uniqueness-index');
            if (cols.length) {
                return cols[0];
            }
        }
        return null;
    };

    scope.$on('column-selection-changed', function (event, col) {

        if (col.id === 'uniqueness-index' && col.hidden) {
            scope.lifestyle = [];
            scope.postProcessQuery(scope.query);
        }
    });

    scope.getSkew = function(arr, reqDataOptions){

        if(!scope.lifestyle.length || !arr.length){
            return;
        }
        let reqData = _.assign({}, audienceSkewService.DEFAULTS, reqDataOptions),
            segment = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(scope.lifestyle[0].segment, 'articles', scope.$root.Geos.geos);

        reqData['filter1'] = segment;
        reqData['pre-chosen-words'] = _.map(arr, function(o) { return o.id; });

        scope.query.loading = true;

        //clear skew until it loads
        arr.forEach((row) =>{row['uniqueness-index'] = '';});

        let promise = $http({
                url: audienceSkewService.AUDIENCE_INSIGHTS_URL,
                method: 'POST',
                cache: true,
                data: reqData
        }).then(function(res) {

            if(!res.data.words){
                notificator.notify({body: 'Sorry, insufficient data for this audience'});
            }

            let skews = (res.data.words||[]).reduce(function(result, item) {
              result[item['phrase-id']] = item['uniqueness-index'];
              return result;
            }, {});

            let skew,
                skewColId = scope.query.columns[0].id,
                audienceLabel = scope.lifestyle[0].label;

            scope.query.dataArray.forEach(setSkew);


            function setSkew(row){
                let skew = skews[row.id];

                row['uniqueness-index'] = (skew || 0).toFixed(2);
                row['display-uniqueness-index'] = skew == undefined ? NA : row['uniqueness-index'];

                row['title-uniqueness-index'] = 'Consumers of '+row[skewColId]+ ' are ';

                if (skew == undefined) {
                    row['title-uniqueness-index'] =
                    'There is not enough data regarding '+audienceLabel+'\'s skew towards '+row[skewColId];
                } else if (skew === 0) {
                    row['title-uniqueness-index'] += 'unlikely to be '+audienceLabel;
                } else if (skew < 1) {
                    row['title-uniqueness-index'] += 'x' +(1/row['uniqueness-index']).toFixed(2)+ ' times less likely to be '+
                        audienceLabel+' than the average consumer';
                } else if (skew === 1){
                    row['title-uniqueness-index'] += 'as likely to be '+audienceLabel+' the same as any other consumer';
                }else if (skew > 1){
                    row['title-uniqueness-index'] += 'x' +row['uniqueness-index']+ ' times more likely to be '+
                        audienceLabel+' than the average consumer';
                }
            }
            scope.query.loading = false;
        });
    };

    scope.postProcessQuery = function(query ,reqDataOptions){
        if (!query || !query.dataArray || !query.dataArray.length){
            return;
        }

        //audience skew
        if(scope.showAudienceToggle()){
            if (scope.lifestyle.length && scope.lifestyle[0].value) {
                scope.getSkew(query.dataArray, reqDataOptions);
            } else {
                function emptySkew(row){
                    row['uniqueness-index'] = NA;
                    row['display-uniqueness-index'] = NA;
                    row['title-uniqueness-index'] = NA;

                }
                query.dataArray.forEach(emptySkew);
            }
        }
        GRID_QUERIES.postProcessQuery(scope.query, $state, context);
    };


    // Targets
    function updateTargetsList() {
        TargetsCommon.getTargets({scope:scope, $state: $state});
    }

    scope.openTargets = function() {
        TargetsCommon.openTargets({scope: scope});
    };

    scope.createTargetWindow = function(phrases){
        scope.trendsIsFirstParty = hasFirstPartyAudience();
        let channel   = CHANNEL.name(context.current.gridChannels),
            timeframe = context.current._discovery_timeframe_mold._value.slice(0);

        //send: 1 day | 3 days | ... | custom
        if (isNaN(timeframe[0])){
            timeframe = "custom";
        } else {
            if (1 != timeframe[0]) {
                timeframe[1] += "s";
            }
            timeframe = timeframe.join(" ");
        }

        Grid.query_params.timeframe = timeframe;

        let results = {[scope.tab]: phrases};

        TargetsCommon.createTargetWindow({
            target_type: TargetsCommon.TargetData.getTargetType($state, scope.tab),
            name: '',
            channel: channel,
            seeds: Grid.config.terms,
            query: Grid.query_params,
            results: results,
            hasPhrases: phrases.length
        });
    };

    scope.validateAddToTarget = function() {
        scope.trendsIsFirstParty = hasFirstPartyAudience();
        scope.allowedAddToTarget = TargetsCommon.validateAddToTarget($state, scope);
        if (!scope.allowedAddToTarget) {
          const advertiser_id = scope.currentTarget.advertiser_id;
          const advertiser = (advertiser_id == MARKET_CONTEXT.ALL_ADVERTISERS_ID) ? "all advertisers" : `advertiser ${advertiser_id}`;
          notificator.notify({body: `Adding 1st Party audience phrases will change this Target type. This Target is activated to ${advertiser} and can not be changed at the moment.`});
        }
    };

    scope.addToCurrentTarget = function(){
        scope.trendsIsFirstParty = hasFirstPartyAudience();
        TargetsCommon.addToCurrentTarget({state: $state, scope});
    };

    scope.editTarget = function () {
        if (scope.currentTarget){
            TargetsCommon.edit({target: scope.currentTarget, tab: scope.tab, $scope: scope});
        }
    };

    /******************************************************************/

    scope.refreshGrid = function () {
        if (!scope.query.loading) {
            Grid.clearCache();
            scope.sendAnotherRequest();
        }
    };

    scope.$watchCollection(function () {
        return getRelevantConfig();
    }, function (newConfig, oldConfig) {
        determineTimeframeVars();
        /****************************************************
         * sent one time when coming into the page
         * and in every change of the context's value
         ****************************************************/
        scope.sendAnotherRequest();
    });

    function determineTimeframeVars() {
        const times = ['1D', '3D', '1W', '1M'];
        if (context.current.gridChannels.value !== 'facebook') {
            times.push('3M');
        } else if (_.isEqual(context.current.discovery_timeframe, [3, 'month'])) {
            context.current.discovery_timeframe = [1, 'month'];
        }

        if (!_.includes(['sg_telco', 'au_telco'], context.current.gridChannels.value)) {
            times.unshift('1H');
        } else if (_.isEqual(context.current.discovery_timeframe, [1, 'hour'])) {
            context.current.discovery_timeframe = [3, 'day'];
        }

        const offset = permissions.hasPermission('discovery timeframe extension')
          ? TIMES.EXTENDED_DISCOVERY_OFFSET
          : TIMES.DISCOVERY_OFFSET;
        $rootScope.$broadcast('timeframe-vars-update', times, offset, false, false, false, TIMES.DISCOVERY_MAX_SPAN);
    }

    // customFilter = true needs deep watch
    let i, filters = getRelevantConfig();

    for (i in filters) {
        if (filters[i] && filters[i].customFilter) {
            scope.$watch('context.current.' + i, function (newVal, oldVal) {
                scope.sendAnotherRequest();
            }, true);
        }
    }

    scope.sendAnotherRequest = function (justExport) {
        if (scope.$root) scope.$root.topicsDistribution = null;
        if (scope.isFacebookDemoUser()) {
          //Hack for facebook demo user because of different tab calculation
          let page = $state.current.name.replace("discovery.", "").split(".")[0];
          if (page != 'grid') return;
        }

        scope.setQuery();
        scope.query.rowSelected = null;
        scope.query.loading = true;

        context.done.then(function () {
            Grid.config = getRelevantConfig(true);
            Grid.config.geos = geoService.geosForChannel(Grid.config.geos, $state, context);
            Grid.config.sub_geos = geoService.subGeosForChannel(Grid.config.sub_geos, $state, context);
            if (context.current.gridChannels.value == 'sg_telco' && !permissions.hasPermission('sg telco audience filters')) {
              Grid.config.audience = [];
            }
            if (context.current.gridChannels.value != 'articles' || !permissions.hasPermission('first party segments')) {
              Grid.config.user_first_party_audience = [];
            }

            scope.query.selected = [];
            scope.ts = (new Date().getTime());
            window.gridKdebugData = {docsUsed1: 0, docsUsed2: 0, elapsed: 0};

            Grid.list(scope.ts).then(function (data) {

                if (!scope.$root) return; // request is irrelevant - user switched tab
                let channel = context.current.gridChannels.value;
                let socialTerms = [];
                if (channel !== "facebook") {
                    socialTerms = _(context.current.terms).filter(
                      (term) => _.includes(['post', 'hashTag', 'mention'], term.type) || util.isSocialBooleanLogic(term)
                    ).map('text').value();
                    if (socialTerms.length) {
                        notificator.notify('Sorry, @mentions, @posts and #hashtags are supported only for Facebook channel ('
                            + _(socialTerms).map(util.getTermDisplayShort).join() + ')');
                    }
                }

                if (!data['data']) data = {data: [], topicsDistribution: {}, ts: 0};
                const returnedPhrases = _(data.data).map('seed').value();
                let insufficientPhrases = _(context.current.terms).filter(
                  (term) => returnedPhrases.indexOf(term.text) === -1 && socialTerms.indexOf(term.text) === -1
                ).map('display').value();
                if (!_.isEmpty(insufficientPhrases)) {
                    notificator.notify('Sorry, insufficient content about ' + insufficientPhrases.join(', ') + '. Please try more general interests or filters');
                }
                scope.$root.topicsDistribution = data.topicsDistribution;

                //show only current query
                if (scope.ts === data.ts && scope.query.show) {
                    scope.postProcessQuery(data['data']);
                    if (data['data'].length === 0) scope.query.loading = false;
                    $timeout(() => scope.query.show(data['data']));
                }

                let gridKdebugData = window.gridKdebugData || {
                    docsUsed1: 0,
                    docsUsed2: 0,
                    elsapsed: 0
                };

                scope.gridKdebugData = {
                    docsUsed1: gridKdebugData.docsUsed1,
                    docsUsed2: gridKdebugData.docsUsed2,
                    elapsed: gridKdebugData.elapsed + "s"
                };

                $timeout(() => delete window.gridKdebugData);

                return gridKdebugData['data'];
            });
        });
    };

    function hasFirstPartyAudience() {
        return _.isArray(context.current.firstPartyAudience) && context.current.firstPartyAudience.length > 0;
    }

    function getRelevantConfig(removeFullySelectedAudience) {
        let ctx = context.current;
        let ctx_audience = c.getAudience(ctx, ctx.gridChannels.value);
        let audience = removeFullySelectedAudience ? util.removeFullySelectedAudienceSegments(ctx_audience, true) : ctx_audience;

        //Hack for facebook demo user - for some reason, for all other users terms are changed on changing tabs even when they're not...
        let tab = scope.isFacebookDemoUser() ?  $state.current.name.replace("discovery.grid.", "").split(".")[0] : localStorage["grid_tab_" + CHANNEL.name(ctx.gridChannels)];
        //geos & sub_geos are changed outside to not trigger a watch
        return {
            language: ctx._language_mold.getLanguage($state, context).value,
            terms: ctx.terms,
            topics: ctx.topics,
            audience: audience,
            geos: ctx.geo,
            sub_geos: ctx.discoverySubGeos,
            isKeyTrends: ctx.keyTrends,
            clustering: false,
            subGeosExportTitle: scope.subGeosExportTitle,
            channels: ctx.gridChannels,
            grid_tab: tab || 'phrases',
            program: context.program,
            timeframe: ctx.discovery_timeframe,
            gridAdvanced: ctx.gridAdvanced || {customFilter: true},
            user_first_party_audience: ctx.firstPartyAudience
        }
    }

    scope.mixpanelTrackSort = function (params) {
        MixpanelDiscovery.trackSort(params);
    };

    scope.sentimentsTooltip = function (row, event) {
        let data = SENTIMENT.setRowData(row);
        scope.sentimentsPopupService.activate($(event.currentTarget), data, {});
    };

    scope.openContentDrivers = function (row, event, toggle) {
        scope.query.rowSelected = row;
        MixpanelDiscovery.trackContentDriveClick(row);
        scope.$broadcast('openContentDrivers', "content_drivers");
    };

    scope.noTerms = function () {
        return context.current.terms.length == 0 && !context.current.keyTrends;
    };

    scope.predictionOpen = function () {
        if (!scope.query.selected.length) {
            return;
        }

        scope.prediction.phrases = scope.query.selected.map(function (phrase) {
            return {
                class: phrase.term.class,
                id: phrase.id,
                text: phrase.phrase,
                display: phrase.phrase,
                origin: phrase.seed,
                invalid: false,
                type: 'term'
            };
        });
        scope.prediction.channel = scope.context.current.gridChannels.value;
        scope.prediction.open({phrases: scope.query.selected});
    };

    function currentTab() {
        return $state.current.url.substring(1);
    }

    scope.updateKeyTrends = function (isOn) {
        context.current.keyTrends = isOn;
        $timeout(() => scope.$apply());
    };

    /*****************************
     * Alerts section
     *****************************/

    function createAlertObj() {
        //preparing the alert object data
        let selected = $.extend(true, [], scope.query.selected);
        let display_interests = [];

        $.each(selected, function (i, sel) {
            //for other tabs if needed
            let text = "";
            switch (currentTab()) {
                case 'web':
                case 'social':
                    text = sel.phrase;
                    break;
                case 'hashtags':
                    text = sel.hashtag;
                    break;
                case 'influencers':
                case 'audience':
                    text = sel.handle;
                    break;
                case 'emojis':
                    //TBD
                    break;
            }
            let obj = {
                active: false,
                class: sel.term.class,
                display: sel.phrase,
                id: sel.id,
                origin: sel.seed,
                text: text,
                type: sel.term.type == 'programBL' || sel.term.type == 'booleanLogic' ?
                    'term' : sel.term.type //doesnt support non-terms for now
            };

            display_interests.push(obj);
        });

        scope.alert = {
            predefined: true,
            display_interests: display_interests
        };
    }

    function getCurrentAlerts() {
        let currentAlertsNames = [];
        alertsService.thinList().then(function (data) {
            if (data || data.length > 0) {
                data.forEach(function (alert) {
                    currentAlertsNames.push(alert.name.toLowerCase());
                });
            }

            alertsService.getSettings().then(function (data) {
                scope.settingsMissing = !data;
                openAlertDialog(currentAlertsNames);
            });
        });
    }

    function openAlertDialog(currentAlertsNames) {
        ModalService.showModal({
            template: require('../alerts/alert-dialog/alert-dialog.html'),
            inputs: {alert: angular.copy(scope.alert), currentAlertsNames: currentAlertsNames, settingsMissing: scope.settingsMissing},
            controller: 'alertDialogController'
        }).then(function (modal) {
            modal.close.then(function () {
            });
        });
    }

    scope.alertOpen = function () {
        if (!scope.query.selected.length) {
            return;
        }
        createAlertObj();
        getCurrentAlerts();
    };

    scope.isMailExport = function () {
        if (!scope.gridKdebugData) {
            return false;
        }
        const ratio = scope.gridKdebugData.docsUsed1 / scope.gridKdebugData.docsUsed2;
        return ratio <= 0.9 && scope.tab === 'audience';
    };

    scope.$root.createExcelWorkbook = function () {
        if (scope.isMailExport()) { //backend will handle export
            return {isMailExport: true};
        }

        let workbook = Excel.builder.createWorkbook();
        workbook.fileName = 'Grid ' + scope.query.title + ' ' + moment().format('YYYY-MM-DD HH:mm') + '.xlsx';

        Grid.generateSummarySheet(workbook);

        const desc = {Seed: {key: 'term.text', width: 16}};

        scope.query.columns.forEach(function (col) {
            if (!col.hidden || col.export) {
                desc[col.title] = {key: col.displayValue || col.id, width: 16};
            }
        });

        Excel.addTableSheet(workbook, scope.query.viewArray, desc, {name: util.capitalFirst(scope.query.title)});

        workbook.loading = scope.query.loading;
        MixpanelDiscovery.trackExport();
        return workbook;
    };
}

function correctState(channel) {
    switch (channel) {
        case 'articles':
            return 'discovery.grid.phrases.web';
        case 'sg_telco':
            return 'discovery.grid.phrases.sg_telco';
        case 'facebook':
            return 'discovery.grid.interests.facebook';
    }
}

/** Pages **/
module.exports.stateConfig = {
    name: "grid",
    url: "/grid",
    reload: false,
    template: require("./grid.discovery.html"),
    display: "Discovery",
    data: {
        permissions: ['channel insights']
    },
    controller: gridController,
    redirectTo: ['context', function (context) {
        var channel = context.current.gridChannels.value;
        return {state: correctState(channel)};
    }],
    children: [
        {
            stateConfig: {
                name: "phrases",
                url: "/phrases",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                },
                children: [
                    {
                        stateConfig: {
                            name: "social",
                            url: "/social",
                            display: "Discovery",
                            context: {},
                            data: {
                              permissions: ['channel insights']
                            }
                        }
                    },
                    {
                        stateConfig: {
                            name: "web",
                            url: "/web",
                            display: "Discovery",
                            context: {},
                            data: {
                              permissions: ['channel insights']
                            }
                        }
                    },
                    {
                        stateConfig: {
                            name: "sg_telco",
                            url: "/sg_telco",
                            display: "Discovery",
                            context: {},
                            data: {
                              permissions: ['channel insights']
                            }
                        }
                    }
                ]
            }
        },
        {
            stateConfig: {
                name: "hashtags",
                url: "/hashtags",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                }
            }
        },
        {
            stateConfig: {
                name: "influencers",
                url: "/influencers",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                }
            }
        },
        {
            stateConfig: {
                name: "audience",
                url: "/audience",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                }
            }
        },
        {
            stateConfig: {
                name: "emojis",
                url: "/emojis",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                }
            }
        },
        {
            stateConfig: {
                name: "pages",
                url: "/pages",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                },
                children: [
                    {
                        stateConfig: {
                            name: "facebook",
                            url: "/facebook",
                            display: "Discovery",
                            context: {},
                            data: {
                              permissions: ['channel insights']
                            }
                        }
                    }

                ]
            }
        },
        {
            stateConfig: {
                name: "interests",
                url: "/interests",
                display: "Discovery",
                context: {},
                data: {
                  permissions: ['channel insights']
                },
                children: [
                    {
                        stateConfig: {
                            name: "facebook",
                            url: "/facebook",
                            display: "Discovery",
                            context: {},
                            data: {
                              permissions: ['channel insights']
                            }
                        }
                    }
                ]
            }
        }
    ],

    context: {}
};
