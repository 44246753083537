import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';
import { ToggleButton } from '@amobee/component-library';
import classNames from 'classnames';
import './SelectToggle.scss';
import Icon from '../Icon/Icon';

const SelectToggle = ({ values, selectedValues, onChange, className }) => {
  const [selectedItems, setSelectedItems] = useState(selectedValues);

  const isItemSelected = ({ value }) => Boolean(find(selectedItems, { value }));

  const onToggleClick = useCallback(
    (item, isSelected) => {
      const itemCurrentlyIncluded = isItemSelected(item);
      if (itemCurrentlyIncluded && !isSelected) {
        setSelectedItems(filter(selectedItems, ({ value }) => value !== item.value));
      } else if (!itemCurrentlyIncluded && isSelected) {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  useEffect(() => onChange(selectedItems), [selectedItems]);

  return (
    <div className="select-toggle-wrapper">
      {values.map((item) => (
        <ToggleButton
          key={item.value}
          compact={true}
          className={classNames('toggle', className)}
          active={isItemSelected(item)}
          onToggle={(isSelected) => onToggleClick(item, isSelected)}
        >
          {item.icon && <Icon iconId={item.icon} width="13px" height="14px" name={item.icon} />}
          {item.label}
        </ToggleButton>
      ))}
    </div>
  );
};

const valuesType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
  })
);

SelectToggle.propTypes = {
  values: valuesType.isRequired,
  selectedValues: valuesType,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SelectToggle.defaultProps = {
  values: [],
  selectedValues: [],
};

export default SelectToggle;
