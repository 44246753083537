import moment from 'moment';
import _, { cloneDeep, remove, isArray, isEqual, compact, map } from 'lodash'; // Required for test coverage purpose
import { getAudienceSize, isAudienceSizeTooSmall } from "../react/services/AudienceInsightsService";

const md5 = require('js-md5');
const KEY_NAME_MAP = {"geo": "countries", "age": "ages", "gender": "genders", "children": "children", "income": "new-incomes", "ethnicity": "new-races"};
const DEMOGRAPHICS_FIELDS = ['age', 'geo', 'income', 'ethnicity', 'gender', 'children', 'states'];
const TV_SHOWS_FIELDS = ['networks', 'genres', 'tv'];
const NEW_QUERY_TV_SHOWS_FIELDS = ['genres'];
const SG_TELCO_CHANNELS = ['snbb', 'data_spark', 'sg_bidstream'];
const LEVEL_OF_INTENT_MAP = {"searches": 'iw', "frequent-keywords": 'xw', "keywords": 'aw'};
const COMMERCIALS_MAPPING_MAP = {
    AD: 'ad-ids', //IDS
    PARENTS: 'parent-ids', // IDS
    BRANDS: 'brand-ids', // IDS
    EXPOSURE: 'exposure',
};
const COMMERCIALS_DESC_MAP = {'creatives': COMMERCIALS_MAPPING_MAP.AD, 'brandParents': COMMERCIALS_MAPPING_MAP.PARENTS, 'brands': COMMERCIALS_MAPPING_MAP.BRANDS,
                              'channel': 'table-name', 'exposure': COMMERCIALS_MAPPING_MAP.EXPOSURE};
const COMMON_MAPPING_MAP = {
    START_TIME: 'start-time',
    END_TIME: 'end-time',
};
const TV_SHOWS_MAPPING_MAP = {
    NETWORK: 'pnws', // IDS
    PROGRAM_SERIES: 'program-series-ids', // IDS
    SHOW: 'show-ids', // IDS
    GENRE: 'genres', //IDS
    EXPOSURE: 'exposure',
};
const TV_SHOWS_FILTER_MAPPING_MAP = {'networks': TV_SHOWS_MAPPING_MAP.NETWORK, 'exposure': TV_SHOWS_MAPPING_MAP.EXPOSURE};
const ADVANCED_TV_SHOWS_MAPPING_MAP = {
    NETWORK: 'pnws', // IDS
    PROGRAM_SERIES: 'program-series-ids', // IDS
    SHOW: 'show-ids', // IDS
    GENRE: 'genre-ids', //IDS
    QUALIFIED_CAPPING: 'qualified-capping',
    EXPOSURE: 'exposure',
    NUMBER_OF_OBJECTS: 'number-of-objects',
    VIEWERSHIP_MODES: 'viewership-mode',
    TYPE: 'type',
};
const ADVANCED_TV_SHOWS_FILTER_MAPPING_MAP = {'genres': ADVANCED_TV_SHOWS_MAPPING_MAP.GENRE, 'tv': ADVANCED_TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES, 'episodes': ADVANCED_TV_SHOWS_MAPPING_MAP.SHOW, 'networks': ADVANCED_TV_SHOWS_MAPPING_MAP.NETWORK,
                                              'exposure': ADVANCED_TV_SHOWS_MAPPING_MAP.EXPOSURE, 'viewershipModes': ADVANCED_TV_SHOWS_MAPPING_MAP.VIEWERSHIP_MODES,
                                              'viewEventQual': ADVANCED_TV_SHOWS_MAPPING_MAP.QUALIFIED_CAPPING};
const TV_CHANNELS = ['smart_tv_inscape', 'tivo'];
const AND_DELIMITER = ' <ii>and</ii> ', OR_DELIMITER = ' <ii>or</ii> ', NOT_DELIMITER = ' <ii>without</ii> ';
const SEGMENT_MAP = {
    lifestyle: {label: "Lifestyles", icon: "icon-lifestyle"},
    demographics: {label: "Demographics", icon: "icon-profile"},
    interests: {label: "Interests", icon: "icon-interests"},
    websites: {label: "Websites", icon: "icon-tv-monitor"},
    tvShows: {label: "TV Shows", icon: "icon-tv-shows"},
    advancedTvShows: {label: "TV Shows", icon: "icon-tv-shows"},
    '1st party': {label: "1st Party", icon: "icon-1st-party"},
    linkedinDemographics: {label: "Demographics", icon: "icon-profile"},
    linkedinIndustries: {label: "Industries", icon: "icon-industries"},
    linkedinCompanies: {label: "Companies", icon: "icon-companies"},
    linkedinJobs: {label: "Jobs", icon: "icon-jobs"},
    smartTvDemographics: {label: "Demographics", icon: "icon-profile"},
    apps: {label: "Apps", icon: "icon-industries"},
    smartTvCommercials: {label: "Commercials", icon: "icon-commercials"}
};
const AND_LOGICAL_OPERAND = {value: 'and', label: 'ALL of the attributes below'};
const OR_LOGICAL_OPERAND = {value: 'or', label: 'ANY of the attributes below'};
const LOGICAL_OPERANDS = [AND_LOGICAL_OPERAND, OR_LOGICAL_OPERAND];

const TV_SHOWS_SEGMENT_TYPES = ['tvShows', 'advancedTvShows'];
const COMMERCIALS_SEGMENT_TYPES = ['smartTvCommercials'];
const DEMOGRAPHICS_SEGMENT_TYPES = ['demographics', 'smartTvDemographics'];

const GENDER_VALUES = [
  { value: 'audience_s12', label: 'Male', icon: 'male', summary: 'male' },
  { value: 'audience_s11', label: 'Female', icon: 'female', summary: 'female' },
  { value: 'all', label: 'Both', icon: 'male_female', summary: 'all' },
];

const AGES = ["13-17", "18-20", "21-24", "25-34", "35-44", "45-49", "50-54", "55-64", "65+"];
const AUDIENCE_AGE_VALUES = map(AGES, (age) => ({label: age, summary: age, value: age}));
const SPECIFIC_AGES = ["18-20", "21-24", "45-49", "50-54"];

//TODO - remove ethnicity from filters-partition and use this instead
const ETHNICITY_VALUES = [
  { label: 'African American', summary: 'African American', value: 'black' },
  { label: 'Asian American', summary: 'Asian American', value: 'asian' },
  { label: 'Caucasian', summary: 'Caucasian', value: 'white' },
  { label: 'Hispanic', summary: 'Hispanic', value: 'hispanic' },
];

const SG_ETHNICITY_VALUES = [
  { label: 'Chinese', summary: 'Chinese', value: 'chinese' },
  { label: 'Malay', summary: 'Malay', value: 'malay' },
  { label: 'Indian', summary: 'Indian', value: 'indian' },
  { label: 'Other', summary: 'Other ethnicities', value: 'other' },
];

//TODO - remove income from filters-partition and use this instead
const INCOME_VALUES = [
  { label: '0-25K', summary: '0-25k', value: ['0-15k', '15-25k'] },
  { label: '25-50K', summary: '25-50k', value: ['25-35k', '35-50k'] },
  { label: '50-75K', summary: '50-75k', value: '50-75k' },
  { label: '75-100K', summary: '75-100k', value: '75-100k' },
  { label: '100-150K', summary: '100-150k', value: '100-150k' },
  { label: '150-200K', summary: '150-200k', value: '150-200k' },
  { label: '200K+', summary: '200k+', value: '200k+' },
];

const DISPLAY_INCOME_VALUES = [
  { value: 0, label: '$0K' },
  { value: 25, label: '$25K' },
  { value: 50, label: '$50K' },
  { value: 75, label: '$75K' },
  { value: 100, label: '$100K' },
  { value: 150, label: '$150K' },
  { value: 200, label: '$200K' },
  { value: 250, label: 'Over $200K' },
];

const LEVELS_OF_EXPOSURE = [
  { value: 'low', label: 'Low'},
  { value: 'medium', label: 'Medium'},
  { value: 'high', label: 'High'},
];

const VIEWERSHIP_MODES = [
  { label: 'Live', value: 'L' },
  { label: 'Viewed on same day', value: 'L+SD' },
  { label: 'Viewed 1-3 days later', value: 'L+3' },
  { label: 'Viewed 4-7 days later', value: 'L+7' },
  { label: 'Viewed 8 or more days later', value: ['L+30', 'L+X'] },
];

const VIEWING_EVENT_QUALIFIERS = [
  { label: 'Less than 1 minute', value: 0 },
  { label: '1 minute and up', value: 1 },
  { label: '3 minutes and up', value: 3 },
  { label: '5 minutes and up', value: 5 },
  { label: '7 minutes and up', value: 7 },
  { label: '10 minutes and up', value: 10 },
];

const VIEWING_TYPES = [
  { label: 'Both', value: 'both' },
  { label: 'Streaming', value: 'streaming' },
  { label: 'Linear', value: 'linear' },
];

const isAllLevelsOfExposure = (levels) => isArray(levels) && levels.length >= LEVELS_OF_EXPOSURE.length;

const TABLE_PREFIX_BY_CHANNEL = {
  smart_tv_inscape: 'inscape',
  tivo: 'tivo_ccp',
  hisense: 'hisense',
};
const ADVANCED_TV_TYPES = {
    tivo: {
        both: "tv-advanced-amplify",
        linear: "tv-advanced",
        streaming: "tv-amplify",
    },
    hisense: {
        both: "tv-advanced",
        linear: "tv-advanced",
        streaming: "tv-advanced",
    },
};
const MOMENT_DATE_FORMAT = 'MM-DD-YY';

const dateToString = (date) => moment(new Date(date)).format(MOMENT_DATE_FORMAT);

const LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP =
  'Choose the exposure tertiaries of your audience to the selected brands and creatives';
const LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP =
  'Choose the exposure tertiaries of your audience to the selected TV content';

const isAllValuesSelected = (selectedValues, values) =>
  !selectedValues.length || selectedValues.length === values.length;

const summaryTextBuilderByValues = (selectedValues) =>
  selectedValues.length === 1 ? selectedValues[0]['label'] : selectedValues.map((value) => value.label).join(', ');
const genericSummaryTextBuilder = (selectedValues, values, allValuesText) =>
  isAllValuesSelected(selectedValues, values) ? allValuesText : summaryTextBuilderByValues(selectedValues);
const getSummaryTextBuilder = (defaultText) => ((selectedValues, values) =>
  genericSummaryTextBuilder(selectedValues, values, defaultText))

const deterministicPermissionName = 'create a deterministic segment';
module.exports = {
    convertAudienceSegmentToLogicStatement,
    convertAudienceSegmentToFilterMapping,
    geoByChannel,
    getSegmentValuesSummary,
    hasActivateAudiencePermission,
    isActivateAudienceEnabled,
    isSmartTvChannel,
    isAdvancedTvChannel,
    isAllLevelsOfExposure,
    convertTvShowsToNewLogicStatement,
    SEGMENT_MAP,
    LOGICAL_OPERANDS,
    AND_LOGICAL_OPERAND,
    TV_CHANNELS,
    TV_SHOWS_SEGMENT_TYPES,
    COMMERCIALS_SEGMENT_TYPES,
    GENDER_VALUES,
    AUDIENCE_AGE_VALUES,
    ETHNICITY_VALUES,
    SG_ETHNICITY_VALUES,
    INCOME_VALUES,
    DISPLAY_INCOME_VALUES,
    LEVELS_OF_EXPOSURE,
    LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP,
    LEVEL_OF_EXPOSURE_TV_SHOWS_TOOLTIP,
    VIEWERSHIP_MODES,
    VIEWING_TYPES,
    VIEWING_EVENT_QUALIFIERS,
    getGeoFromAudienceSegment,
    isDeterministicDynamicActivationAllowed,
    parseToNumber,
    mergeRangesForSummary,
    dateToString,
    getSummaryTextBuilder,
    deterministicPermissionName,
};

const getStatementOperand = (statement) => Array.isArray(statement) ? statement[0] : 'none';

const isAndStatement = (statement) => getStatementOperand(statement) == 'and';
const isNotStatement = (statement) => getStatementOperand(statement) == 'not';
const isNoneStatement = (statement) => getStatementOperand(statement) == 'none';

function optimizeStatement(statement) {
    // [and, filter1, [and, filter2, filter3]] => [and, filter1, filter2, filter3]
    if (isNoneStatement(statement)) return statement;

    if (isAndStatement(statement)) {
        if (statement.length == 2 && !isNoneStatement(statement[1])) return statement[1];
        let optimizedLogicStatement = ["and"];
        statement.slice(1).forEach(function (s) {
            if (isAndStatement(s)) {
                optimizedLogicStatement = optimizedLogicStatement.concat(s.slice(1))
            }
            else {
                optimizedLogicStatement.push(optimizeStatement(s));
            }
        });
        return optimizedLogicStatement;
    }

    for (var i = 0; i < statement.length; i++) {
        statement[i] = optimizeStatement(statement[i])
    }

    return statement;
}

function convertAppsSegmentToLogicStatement(apps) {
    const filterType = 'app';
    let filters = _.pick(apps, ['required', 'included', 'excluded']);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'id'));
    filters = _.mapValues(filters, (filterVal) => (
        _.map(filterVal, val => ({[filterType]: val.toLowerCase()}))
    ));

    const logicStatement = [];
    if (filters["required"]) logicStatement.concat(filters["required"]);
    if (filters["included"]) logicStatement.push(["or"].concat(filters["included"]));
    if (filters["excluded"]) logicStatement.push(["not", ["or"].concat(filters["excluded"])]);

    return logicStatement.length == 1 ? logicStatement[0] : ["and"].concat(logicStatement);
}

function convertInterestsAndWebsitesSegmentToLogicStatement(interests) {
    const filterType = interests.type == "interests" ? interests.levelOfIntent.value : "domains";
    let filters = _.pick(interests, ['required', 'included', 'excluded']);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'id'));
    filters = _.mapValues(filters, (filterVal) => _.map(filterVal, val =>
                                                  ({[filterType]: (filterType == 'domains') ? val.toLowerCase() : val})));
    let logicStatement = [];
    if (filters["required"] && filters["required"].length > 0) logicStatement = logicStatement.concat(filters["required"]);
    if (filters["included"] && filters["included"].length > 0) logicStatement.push(["or"].concat(filters["included"]));
    if (filters["excluded"] && filters["excluded"].length > 0) logicStatement.push(["not", ["or"].concat(filters["excluded"])]);

    return logicStatement.length == 1 ? logicStatement[0] : ["and"].concat(logicStatement);
}

function convertInterestsToLogicStatement(interests) {
    const levelOfIntentKey = LEVEL_OF_INTENT_MAP[interests.levelOfIntent.value];
    return convertInterestsAndWebsitesSegmentToLogicStatement(_.extend({}, interests, {levelOfIntent: {value: levelOfIntentKey}}));
}

function convertTvShowsToLogicStatement(tvShows, channel) {
    let filters = _.pick(tvShows, TV_SHOWS_FIELDS);
    filters = _.mapKeys(filters, (filterVal, filterType) => filterType === 'networks' ? (channel === 'articles' ? 'networks' : TV_SHOWS_MAPPING_MAP.NETWORK) : filterType);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'value'));
    filters = _.map(filters, function (filterVal, filterType) {
        let values = _.map(filterVal, v => ({[filterType]: v}));
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });
    return filters.length == 1 ? filters[0] : ["and"].concat(filters);
}

function getFilterMappingWithShowAndSeriesIds(filterMapping, tvShows) {
    if(!_.isEmpty(tvShows.tv)) {
        const tvShowsByIsTypeSeries = _.groupBy(tvShows.tv, (show) => show.type === 'series' ? ADVANCED_TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES : ADVANCED_TV_SHOWS_MAPPING_MAP.SHOW);
        for (let key in tvShowsByIsTypeSeries){
            filterMapping[key] = tvShowsByIsTypeSeries[key];
        };
    }
    return filterMapping;
}

function getFilterMappingWithGenreIds(filterMapping, tvShows) {
    if(!_.isEmpty(tvShows.genres)) {
        filterMapping[ADVANCED_TV_SHOWS_MAPPING_MAP.GENRE] = tvShows.genres;
    }
    return filterMapping;
}

export function convertTvShowsToNewLogicStatement(tvShows, channel) {
    let filters = _.pick(tvShows, NEW_QUERY_TV_SHOWS_FIELDS);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'value'));
    filters = _.map(filters, function (filterVal, filterType) {
        let values = _.map(filterVal, v => ({[filterType]: v}));
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });

    let filterMapping = _.pick(tvShows, Object.keys(TV_SHOWS_FILTER_MAPPING_MAP));
    filterMapping = _.mapKeys(filterMapping, (filterVal, filterType) => TV_SHOWS_FILTER_MAPPING_MAP[filterType]);
    filterMapping = getFilterMappingWithShowAndSeriesIds(filterMapping, tvShows);
    filterMapping = getFilterMappingWithGenreIds(filterMapping, tvShows);
    filterMapping = _.mapValues(filterMapping, (filterVal, filterType) => _.flatMap(filterVal, 'value'));
    const isFilterMappingEmpty = _.isEmpty(filterMapping) || !_.some(filterMapping, (v) => !_.isEmpty(v));

    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, tvShows);
    if (filters.length > 0 && !isFilterMappingEmpty) return ['and', filters[0], {'place-holder': convertTvShowsSegmentToMd5(channel, filterMapping)}];
    if (filters.length > 0) return filters[0];
    if (!isFilterMappingEmpty) return {'place-holder': convertTvShowsSegmentToMd5(channel, filterMapping)};
}

export function convertAdvancedTvShowsToLogicStatement(tvShows, channel) {
    const filterMapping = getAdvancedTvShowsFilterMapping(tvShows, channel);
    const isFilterMappingEmpty = _.isEmpty(filterMapping) || !_.some(filterMapping, (v,k) => !['start_date', 'end_date'].includes(k) &&  !_.isEmpty(v));
    if (!isFilterMappingEmpty) return {'place-holder': convertAdvancedTvShowsSegmentToMd5(channel, filterMapping)};
}

function convertCommercialsSegmentToLogicStatement(commercials, channel) {
    let filters = _.pickBy(commercials, (value, key) => {
        if (commercials.queryKeys && ['creatives', 'brands', 'brandParents'].includes(key)) return commercials.queryKeys.includes(key);
        return Object.keys(COMMERCIALS_DESC_MAP).includes(key);
    });

    filters = _.mapKeys(filters, (filterVal, filterType) => COMMERCIALS_DESC_MAP[filterType]);
    filters = _.mapValues(filters, (filterVal, filterType) => {
        if ([COMMERCIALS_MAPPING_MAP.AD, COMMERCIALS_MAPPING_MAP.PARENTS, COMMERCIALS_MAPPING_MAP.BRANDS, COMMERCIALS_MAPPING_MAP.EXPOSURE].includes(filterType)) {
            if (filterType == 'exposure' && isAllLevelsOfExposure(filterVal)) return [];
            return [...new Set(_.flatMap(filterVal, 'value'))];
        }
        if (filterType === 'table-name' && TABLE_PREFIX_BY_CHANNEL[channel]){
            return `${TABLE_PREFIX_BY_CHANNEL[channel]}_ads`;
        }
        return filterVal;
    });

    //filters['type'] = (isAdvancedTvChannel(channel)) ? "tv-advanced" : undefined;
    filters['type'] = (channel == "hisense") ? "tv-advanced" : "tv"; // temp change for Dima
    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filters, commercials);
    if (Object.keys(filters).length === 0) return {};
    return {'place-holder': convertCommercialsSegmentToMd5(channel, filters)};
}

function replaceValues(values, oldValue, newValues) {
    if (!_.includes(values, oldValue)) return;
    values.splice(values.indexOf(oldValue), 1);
    _.each(newValues, (newVal) => values.push(newVal));
}

function rebuildIncome(income) {
    let newIncome = [];
    _.each(income, (val) => _.isArray(val) ? _.each(val, vv => newIncome.push(vv)) : newIncome.push(val));
    return newIncome;
}

function convertDemographicsSegmentToLogicStatement(demographics, channel, allPermittedGeos, isBidStream, isDeterministic) {
    let filters = _.pick(demographics, DEMOGRAPHICS_FIELDS);

    filters = _.mapValues(filters, function (filterVal, filterType) {
        if (_.includes(['age', 'gender'], filterType)) return _.map(filterVal, 'summary').map(f => f == "13-17" ? "12-17" : f);
        if (_.includes(['children'], filterType)) return _.map(filterVal, children => children.label.toLowerCase());
        if (_.includes(['ethnicity', 'income', 'states'], filterType)) return _.map(filterVal, 'value');
        if (_.includes(['geo'], filterType)) return geoByChannel(filterVal, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    replaceValues(filters.income, "0-25k", ["0-15k", "15-25k"]);
    replaceValues(filters.income, "25-50k", ["25-35k", "35-50k"]);
    if (filters.income) {
        // Flatten income params - to accommodate server api
        filters.income = rebuildIncome(filters.income)
    }

    filters = _.mapKeys(filters, function (val, key) {
        if (key == "ethnicity") return SG_TELCO_CHANNELS.includes(channel) ? "sg-races" : "new-races";
        return KEY_NAME_MAP[key] || key;
    });
    filters = _.map(filters, function (filterVal, filterType) {
        const values = _.map(filterVal, (v) => {
            const type = (filterType == 'ages' && SPECIFIC_AGES.includes(v)) ? 'specific-ages' : filterType;
            return {[type]: v};
        });
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });
    return filters.length == 1 ? filters[0] : ["and"].concat(filters);
}

function convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic) {
    if (DEMOGRAPHICS_SEGMENT_TYPES.includes(segment.type)) return convertDemographicsSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    if (segment.type == "interests") return convertInterestsToLogicStatement(segment);
    if (segment.type == "websites") return convertInterestsAndWebsitesSegmentToLogicStatement(segment);
    if (segment.type == "lifestyle") return {intentions: segment.value};
    if (segment.type == "tvShows" && isSmartTvChannel(channel)) return convertTvShowsToNewLogicStatement(segment, channel);
    if (segment.type == "tvShows") return convertTvShowsToLogicStatement(segment, channel);
    if (segment.type == "advancedTvShows") return convertAdvancedTvShowsToLogicStatement(segment, channel);
    if (segment.type == "1st party") return {'place-holder': segment.value};
    if (segment.type === 'apps') return convertAppsSegmentToLogicStatement(segment);
    if (segment.type === 'smartTvCommercials') return convertCommercialsSegmentToLogicStatement(segment, channel);
}

export function convertAudienceSegmentToFilterMapping(audienceSegment, channel) {
    const filterMapping = audienceSegment.reduce((filterMapping, segment) => {
        if (segment.type === 'smartTvCommercials')
            return {...filterMapping, ...convertCommercialsSegmentToFilterMapping(segment, channel)};

        if (segment.type === 'tvShows' && isSmartTvChannel(channel) && ['networks', 'tv'].some(k => k in segment))
            return {...filterMapping, ...convertTvShowsSegmentToFilterMapping(segment, channel)};

        if (segment.type === 'advancedTvShows' && (TV_SHOWS_FIELDS.concat(_.keys(ADVANCED_TV_SHOWS_FILTER_MAPPING_MAP))).some(k => k in segment))
            return {...filterMapping, ...convertAdvancedTvShowsSegmentToFilterMapping(segment, channel)};

        if (segment.type === '1st party') return {...filterMapping, ...convertFirstPartySegmentToFilterMapping(segment)};

        return filterMapping;
    }, {});
    return _.isEmpty(filterMapping) ? null : filterMapping;
}

function convertCommercialsSegmentToFilterMapping(commercials, channel) {
    let filters = _.pickBy(commercials, (value, key) => {
        if (commercials.queryKeys && ['creatives', 'brands', 'brandParents'].includes(key)) return commercials.queryKeys.includes(key);
        return Object.keys(COMMERCIALS_DESC_MAP).includes(key);
    });

    filters = _.mapKeys(filters, (filterVal, filterType) => COMMERCIALS_DESC_MAP[filterType]);

    const filterMapping = _.mapValues(filters, (filterVal, filterType) => {
        if ([COMMERCIALS_MAPPING_MAP.AD, COMMERCIALS_MAPPING_MAP.PARENTS, COMMERCIALS_MAPPING_MAP.BRANDS, COMMERCIALS_MAPPING_MAP.EXPOSURE].includes(filterType)) {
            if (filterType == COMMERCIALS_MAPPING_MAP.EXPOSURE && isAllLevelsOfExposure(filterVal)) return [];
            return [...new Set(_.flatMap(filterVal, 'value'))];
        }
        if (filterType === 'table-name' && TABLE_PREFIX_BY_CHANNEL[channel]){
            return `${TABLE_PREFIX_BY_CHANNEL[channel]}_ads`;
        }
        return filterVal;
    });

    //filterMapping['type'] = (isAdvancedTvChannel(channel)) ? "tv-advanced" : undefined;
    filterMapping['type'] = (channel == "hisense") ? "tv-advanced" : "tv"; // temp change for Dima
    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, commercials);

    return {[convertCommercialsSegmentToMd5(channel, filterMapping)]: filterMapping};
}

function convertTvShowsSegmentToFilterMapping(tvShows, channel) {
    let filters = _.pick(tvShows, Object.keys(TV_SHOWS_FILTER_MAPPING_MAP));
    filters = _.mapKeys(filters, (filterVal, filterType) => TV_SHOWS_FILTER_MAPPING_MAP[filterType]);
    filters = getFilterMappingWithShowAndSeriesIds(filters, tvShows);

    const filterMapping = _.mapValues(filters, (filterVal, filterType) => {
        if ([TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES, TV_SHOWS_MAPPING_MAP.SHOW, TV_SHOWS_MAPPING_MAP.NETWORK, TV_SHOWS_MAPPING_MAP.GENRE, TV_SHOWS_MAPPING_MAP.EXPOSURE].includes(filterType)) {
            if (filterType == TV_SHOWS_MAPPING_MAP.EXPOSURE && isAllLevelsOfExposure(filterVal)) return [];
            return _.flatMap(filterVal, 'value');
        }
        return filterVal;
    });
    if (TABLE_PREFIX_BY_CHANNEL[channel]) {
        filterMapping['table-name'] = `${TABLE_PREFIX_BY_CHANNEL[channel]}_media`;
    }
    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, tvShows);

    return {[convertTvShowsSegmentToMd5(channel, filterMapping)]: filterMapping};
}

function getAdvancedTvShowsFilterMapping(tvShows, channel) {
    let filters = _.pick(tvShows, Object.keys(ADVANCED_TV_SHOWS_FILTER_MAPPING_MAP));
    filters = _.mapKeys(filters, (filterVal, filterType) => ADVANCED_TV_SHOWS_FILTER_MAPPING_MAP[filterType]);

    const filterMapping = _.mapValues(filters, (filterVal, filterType) => {
        if ([ADVANCED_TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES, ADVANCED_TV_SHOWS_MAPPING_MAP.SHOW, ADVANCED_TV_SHOWS_MAPPING_MAP.EXPOSURE].includes(filterType)) {
            if (filterType === ADVANCED_TV_SHOWS_MAPPING_MAP.EXPOSURE && isAllLevelsOfExposure(filterVal)) return [];
            return _.map(filterVal, 'value');
        }
        if (filterType === ADVANCED_TV_SHOWS_MAPPING_MAP.NETWORK) {
            return _.map(filterVal, 'label');
        }
        if (filterType === ADVANCED_TV_SHOWS_MAPPING_MAP.VIEWERSHIP_MODES) {
            return _.flatten(_.map(filterVal, 'value'));
        }
        if (filterType === ADVANCED_TV_SHOWS_MAPPING_MAP.QUALIFIED_CAPPING) {
            return filterVal.value;
        }
        if (filterType === ADVANCED_TV_SHOWS_MAPPING_MAP.GENRE) {
            return _.flatten(_.map(filterVal, 'value'));
        }
        return filterVal;
    });
    filterMapping['type'] = ADVANCED_TV_TYPES[channel][tvShows.viewingType];
    filterMapping['viewing-type'] = tvShows.viewingType;
    filterMapping['table-name'] = `${TABLE_PREFIX_BY_CHANNEL[channel]}_media`;
    addDateRangeToFilterMapping(filterMapping, tvShows);
    if(tvShows.minViewedProgs) {
        filterMapping[ADVANCED_TV_SHOWS_MAPPING_MAP.NUMBER_OF_OBJECTS] = {min: tvShows.minViewedProgs};
    }

    return filterMapping;
}

function convertAdvancedTvShowsSegmentToFilterMapping(tvShows, channel) {
    const filterMapping = getAdvancedTvShowsFilterMapping(tvShows, channel);
    return {[convertAdvancedTvShowsSegmentToMd5(channel, filterMapping)]: filterMapping};
}

function convertFirstPartySegmentToFilterMapping(segment) {
    const isNotNullValue = (val) => val && val != 'null';
    const hasPresetFile = isNotNullValue(segment.s3_bucket) && isNotNullValue(segment.s3_path);
    const filterMapping = {
        type: 'file',
        bucket: hasPresetFile ? segment.s3_bucket : 'kona-turn',
        "file-key": hasPresetFile ? segment.s3_path : `bidstream-1st-party-audience/${segment.value}`
    };
    return {[segment.value]: filterMapping};
}

function hasSegmentTimeframe(segment) {
    return segment.startDate && segment.endDate;
}

function hasFilterMappingTimeframe(filterMapping) {
    return filterMapping[COMMON_MAPPING_MAP.START_TIME] && filterMapping[COMMON_MAPPING_MAP.END_TIME];
}

function getTimeframePlaceholder(filters) {
    return `_${filters[COMMON_MAPPING_MAP.START_TIME]}-${filters[COMMON_MAPPING_MAP.END_TIME]}`;
}

function addDateRangeToFilterMapping(filterMapping, segment) {
    if (!hasSegmentTimeframe(segment)) return;

    const formatDate = (dateString) => moment(new Date(dateString)).format('YYYY-MM-DD');
    if (segment.timeframe) {
      const [value, unit] = segment.timeframe.split(' ');
      filterMapping[COMMON_MAPPING_MAP.START_TIME] = moment().subtract(value, unit).format('YYYY-MM-DD');
      filterMapping[COMMON_MAPPING_MAP.END_TIME] = moment().format('YYYY-MM-DD');
    } else {
      filterMapping[COMMON_MAPPING_MAP.START_TIME] = formatDate(segment.startDate);
      filterMapping[COMMON_MAPPING_MAP.END_TIME] = formatDate(segment.endDate);
    }
}

function convertCommercialsSegmentToMd5(channel, commercials) {
    let commercialsStr = `commercials_${channel}`;
    _.forEach(commercials, (value, key) => {
        const mdValue = Array.isArray(value) ? _.join(value.sort(), '_') : value;
        commercialsStr += `_${key}_${mdValue}`;
    });
    if (hasFilterMappingTimeframe(commercials)) commercialsStr += getTimeframePlaceholder(commercials);
    commercialsStr += `_${_.join((commercials['exposure'] || []).sort(), '_')}`;
    return md5(commercialsStr);
}

function convertTvShowsSegmentToMd5(channel, tvShows) {
    let tvShowsStr = `tv_${channel}_${_.join((tvShows[TV_SHOWS_MAPPING_MAP.SHOW] || []).sort(), '_')}_${_.join((tvShows[TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES] || []).sort(), '_')}_${_.join((tvShows[TV_SHOWS_MAPPING_MAP.NETWORK] || []).sort(), '_')}_${_.join((tvShows[TV_SHOWS_MAPPING_MAP.GENRE] || []).sort(), '_')}_${_.join((tvShows[TV_SHOWS_MAPPING_MAP.EXPOSURE] || []).sort(), '_')}`;
    if (hasFilterMappingTimeframe(tvShows)) tvShowsStr += getTimeframePlaceholder(tvShows);
    return md5(tvShowsStr);
}

function convertAdvancedTvShowsSegmentToMd5(channel, tvShows) {
    let tvShowsStr = `tv_${channel}_${tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.TYPE]}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.SHOW] || []).sort(), '_')}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.PROGRAM_SERIES] || []).sort(), '_')}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.NETWORK] || []).sort(), '_')}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.GENRE] || []).sort(), '_')}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.EXPOSURE] || []).sort(), '_')}_${_.join((tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.VIEWERSHIP_MODES] || []).sort(), '_')}_${tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.QUALIFIED_CAPPING]}_${JSON.stringify(tvShows[ADVANCED_TV_SHOWS_MAPPING_MAP.NUMBER_OF_OBJECTS] || {})}`;
    if (hasFilterMappingTimeframe(tvShows)) tvShowsStr += getTimeframePlaceholder(tvShows);
    return md5(tvShowsStr);
}

export function convertAudienceSegmentToLogicStatement(audienceSegment, channel, allPermittedGeos, isTv = null, isBidStreamChannel = null, isDeterministic = null) {
    audienceSegment = _.cloneDeep(audienceSegment);
    _.each(audienceSegment, (segment) => segment.operand = segment.operand || {value: "and"});

    // When user "exclude" demographics with country, delete all geos from all segments and add an "require" segment with this geo
    const geo = _.get(_.filter(audienceSegment, (segment) => DEMOGRAPHICS_SEGMENT_TYPES.includes(segment.type))[0], 'geo');

    _.each(audienceSegment, (segment) => delete segment.geo);
    _.remove(audienceSegment, (segment) => (DEMOGRAPHICS_SEGMENT_TYPES.includes(segment.type)) && _.isEmpty(_.pick(segment, DEMOGRAPHICS_FIELDS)));
    audienceSegment.push({geo, type: "demographics", operand: {value: "and"}});

    var segmentByOperand = _.groupBy(audienceSegment, 'operand.value');
    const isBidStream = isBidStreamChannel || _.some(audienceSegment, {type: '1st party'});

    let andStatements = _.map(segmentByOperand["and"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    let orStatements = _.map(segmentByOperand["or"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    let notStatements = _.map(segmentByOperand["not"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });

    if (orStatements.length) orStatements = [["or"].concat(orStatements)];
    if (notStatements.length) notStatements = [["not"].concat(notStatements.length == 1 ? notStatements : [["or"].concat(notStatements)])];

    let logicalStatement = ["and"].concat(andStatements).concat(orStatements).concat(notStatements);
    if ((isTv || _.some(audienceSegment, {type: 'tvShows'})) && !TV_CHANNELS.includes(channel)) logicalStatement.push({"any-tv": "yes"});

    return optimizeStatement(logicalStatement);
}

export function geoByChannel(geo, channel, allPermittedGeos = null, isBidStream = null, isDeterministic = null) {
    if (channel === 'data_spark') return ["sg:data_spark_https"];
    if (channel === 'sg_bidstream') return ["sg:bid_stream"];
    if (SG_TELCO_CHANNELS.includes(channel)) return [`sg:${channel}`];
    if (channel === 'smart_tv_inscape') return compact(["us:bid_stream_inscape", isDeterministic && "us:inscape"]);
    if (channel === 'tivo') return ["us:bid_stream_tivo_ccp"];
    if (channel === 'hisense') return ["us:bid_stream_hisense"];
    if (channel === 'au_telco') return ["au:optus"];

    const channelAddition = isBidStream ? ':bid_stream' : '';
    const geos = _.isEmpty(geo) ? allPermittedGeos : geo;
    return _.map(geos, (value, key) => value.cc.toLowerCase() + channelAddition);
}

export function parseToNumber(str) {
    return _.toNumber(_.replace(str, /\D/g, ''));
}

export function mergeRangesForSummary(ranges) {
    if (_.isEmpty(ranges)) return [];
    ranges = _.sortBy(ranges);
    const START = 0, END = 1;
    let mergedRanges = [];
    let currentRange = _.split(ranges[START], '-');

    if (currentRange.length === 1) currentRange.push(currentRange[START]);
    for (let i = 1; i < ranges.length; i++) {
        let nextRange = _.split(ranges[i], '-');

        if (nextRange.length === 1) nextRange.push(nextRange[START]);
        if (_.isEqual(parseToNumber(currentRange[END]), parseToNumber(nextRange[START]))
            || _.isEqual(parseToNumber(currentRange[END]) + 1, parseToNumber(nextRange[START]))) {
            currentRange = [currentRange[START], nextRange[END]];
        } else {
            mergedRanges.push(currentRange);
            currentRange = [nextRange[START], nextRange[END]];
        }
    }
    mergedRanges.push(currentRange);

    return _.map(mergedRanges, (range) => _.uniq(range))
}

function getRangeValueSummary(ranges, rangeText) {
    const mergedRanges = mergeRangesForSummary(_.map(ranges, (range) => range.label));
    return "<ii>" + rangeText + " between</ii> " + _.join(_.map(mergedRanges, (range) => _.join(range, '-')), ' and ');
}

function getIncomeValueSummary(income, filtersPartition) {
    const has_first_income = _.isEqual(income[0].value, filtersPartition.newIncome[0].value);
    const has_last_income = _.isEqual(income[income.length - 1].value, filtersPartition.newIncome[filtersPartition.newIncome.length - 1].value);
    let summary = "<ii>Income ";
    if (!has_first_income && !has_last_income) {
        summary += "between</ii> " + income[0].label.split('-')[0] + "-" + income[income.length - 1].label.split('-')[1];
    } else if (has_first_income) {
        summary += "below</ii> " + income[income.length - 1].label.split('-')[1];
    } else {
        summary += "over</ii> " + income[0].label.split('-')[0] + "K";
    }

    summary = summary.replace(/\+k/i, '').replace(/99k/i, '100K');
    return summary;
}

function multipleItemsSummary(items, concatenateSymbol, itemsToDisplay = 1) {
    const arrOfItems = [...items];
    const extra = arrOfItems.length - itemsToDisplay > 0 ? arrOfItems.length - itemsToDisplay : 0;
    const labels = arrOfItems.map((item) => _.isString(item) ? item : item.label);
    let summary = labels.slice(0, itemsToDisplay).join(` <ii>${concatenateSymbol}</ii> `);

    if (extra) {
        const extraItems = labels.slice(itemsToDisplay, labels.length).join(` ${concatenateSymbol} `);
        summary += `<span title="${extraItems}" am-tooltip="top center to bottom center"> <ii>${concatenateSymbol}</ii> ${extra} other${extra === 1 ? '' : 's'} </span>`
    }

    return summary;
}

function getDemographicsValueSummary(demographics, filtersPartition) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.income) summary.push(getIncomeValueSummary(demographics.income, filtersPartition));
    if (demographics.children) summary.push(_.upperFirst(demographics.children[0].summary));
    if (demographics.geo && demographics.geo[0]?.label) summary.push(getGeoSummary(demographics.geo[0].label, demographics.states));
    if (demographics.ethnicity && demographics.ethnicity.length) summary.push(demographics.ethnicity.map(e => e.label).join(OR_DELIMITER));
    return summary.join(', ');
}

function getGeoSummary(geoLabel, states) {
    let summary = geoLabel;
    if (!_.isEmpty(states)) {
        if (states.length > 3) {
            summary += ` (${states[0].value}, ${states[1].value} & ${states.length - 2} others)`;
        } else if (states.length > 1) {
            summary += ` (${_.map(states.slice(0, states.length - 1), 'value').join(', ')} & ${_.last(states).value})`;
        } else {
            summary += ` (${states[0].value})`;
        }
    }
    return summary;
}

function getInterestsAndWebsitesValueSummary(interests) {
    let summary = [];
    if (interests.required && interests.required.length > 0) summary = summary.concat(_.map(interests.required, 'text').join(AND_DELIMITER));
    if (interests.included && interests.included.length > 0) summary = summary.concat(_.map(interests.included, 'text').join(OR_DELIMITER));
    if (interests.excluded && interests.excluded.length > 0) summary = summary.concat(NOT_DELIMITER + _.map(interests.excluded, 'text').join(OR_DELIMITER));
    if (interests.levelOfIntent) summary = summary.concat("<ii>Level of Intent</ii> " + interests.levelOfIntent.label);
    return summary.join(",&nbsp;&nbsp;&nbsp;");
}

function getLinkedinDemographicsValueSummary(demographics) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.country) summary.push(multipleItemsSummary(demographics.country, 'and'));
    if (demographics.state) summary.push(multipleItemsSummary(demographics.state, 'and'));
    if (demographics.regions) summary.push(multipleItemsSummary(demographics.regions, 'and'));

    return summary.join(', ');
}

function getLinkedinIndustriesValueSummary(industries) {
    let summary = [];
    if (industries.industries) {
        industries.industries.forEach(industry => summary.push(industry.label));
    }
    return summary.join(', ');
}

function getLinkedinCompaniesValueSummary(companies) {
    let summary = [];
    if (companies.sizes) summary.push(getRangeValueSummary(companies.sizes, "Company size"));
    if (companies.names) summary = summary.concat(_.map(companies.names, 'text').join(OR_DELIMITER));
    return summary.join(', ');
}

function getLinkedinJobsValueSummary(jobs) {
    let summary = [];
    if (jobs.seniorities) jobs.seniorities.forEach(seniority => summary.push(seniority.label));
    if (jobs.functions) jobs.functions.forEach(func => summary.push(func.label));
    if (jobs.jobTitles) jobs.jobTitles.forEach(jobTitle => summary.push(jobTitle.label));
    return summary.join(', ');
}

function getTvShowsSummary(tvShows, isAudienceInfoTooltip = false) {
    let summary = [];
    if (tvShows.networks) summary = summary.concat("<ii>Networks</ii> " + multipleItemsSummary(tvShows.networks, 'or', 3));
    if (tvShows.tv) summary = summary.concat("<ii>Watched either</ii> " + multipleItemsSummary(tvShows.tv, 'or', 2));
    if (tvShows.episodes) summary = summary.concat("<ii>Watched either</ii> " + multipleItemsSummary(tvShows.episodes, 'or', 2));
    if (!_.isEmpty(tvShows.exposure) && tvShows.exposure.length < LEVELS_OF_EXPOSURE.length) {
        summary = summary.concat("<ii>Level of Exposure</ii> " + _.join(_.map(tvShows.exposure, 'label'), ', '));
    }
    if (tvShows.genres) summary = summary.concat("<ii>Genres</ii> " + multipleItemsSummary(tvShows.genres, 'or', 3));
    if (isAudienceInfoTooltip && tvShows.startDate && tvShows.endDate) summary = summary.concat("<ii>Timeframe</ii> " + tvShows.startDate + " - " + tvShows.endDate);
    if (!_.isEmpty(tvShows.viewershipModes)) summary = summary.concat("<ii>Viewership Modes</ii> " + multipleItemsSummary(_.map(tvShows.viewershipModes, 'label'), 'or', 2));
    if (tvShows.viewEventQual) summary = summary.concat("<ii>Viewing Event Qualifier</ii> " + tvShows.viewEventQual.label);
    if (tvShows.minViewedProgs) {
        summary = summary.concat("<ii>Minimum Number of Viewed Programs</ii> " + tvShows.minViewedProgs);
    }
    return summary.join(', ');
}

function getCommercialsValueSummary(commercials, isAudienceInfoTooltip = false) {
    let summary = [];
    if (commercials.brands) summary = summary.concat("<ii>Was exposed to</ii> " + multipleItemsSummary(commercials.brands, 'or', 2));
    if (!_.isEmpty(commercials.exposure) && commercials.exposure.length < LEVELS_OF_EXPOSURE.length) {
        summary = summary.concat("<ii>Level of Exposure</ii> " + _.join(_.map(commercials.exposure, 'label'), ', '));
    }
    if (isAudienceInfoTooltip && commercials.startDate && commercials.endDate) summary = summary.concat("<ii>Timeframe</ii> " + commercials.startDate + " - " + commercials.endDate);
    return summary.join(', ');
}

export function getSegmentValuesSummary(segment, filtersPartition, isAudienceInfoTooltip = false) {
    const {type, label} = segment;
    const SUMMARY_FUNC_BY_TYPE = {
        lifestyle: () => label,
        custom_segment: () => label,
        '1st party': () => label,
        demographics: () => getDemographicsValueSummary(segment, filtersPartition),
        interests: () => getInterestsAndWebsitesValueSummary(segment),
        websites: () => getInterestsAndWebsitesValueSummary(segment),
        linkedinDemographics: () => getLinkedinDemographicsValueSummary(segment),
        linkedinIndustries: () => getLinkedinIndustriesValueSummary(segment),
        linkedinCompanies: () => getLinkedinCompaniesValueSummary(segment),
        linkedinJobs: () => getLinkedinJobsValueSummary(segment),
        tvShows: () => getTvShowsSummary(segment, isAudienceInfoTooltip),
        advancedTvShows: () => getTvShowsSummary(segment, isAudienceInfoTooltip),
        smartTvDemographics: () => getDemographicsValueSummary(segment, filtersPartition),
        apps: () => getInterestsAndWebsitesValueSummary(segment),
        smartTvCommercials: () => getCommercialsValueSummary(segment, isAudienceInfoTooltip),
    };
    return SUMMARY_FUNC_BY_TYPE[type]?.();
}

function hasActivateAudiencePermission(abiPermissions, channel) {
    switch (channel) {
        case 'articles':
            return abiPermissions.hasPermission('web');
        case 'smart_tv_inscape':
        case 'tivo':
        case 'hisense':
            return abiPermissions.hasPermission('tv activation');
        default:
            return false;
    }
}

/**
 * @param abiPermissions - permissions manager
 * @param channel - valid channels can be 'articles'/'smart_tv_inscape'/'tivo'.
 * @param audience
 * @param userId
 * @returns {Promise<{isEnabled: boolean, isVisible: boolean, disabledText: string}|{isEnabled: boolean, isVisible: boolean}>}
 * reasons:
 *   channel - the channel can not be activated
 *   no geo - the segment must contain a geo section
 *   unsupported geo - the geo section must contain supported country codes (currently only US)
 *   active - audience is already active (or fully-active, in case of smart_tv)
 *   demographics - the resulting demographics will be too small for the audience to be activated
 */
async function isActivateAudienceEnabled(abiPermissions, channel, audience, userId) {
    const hasPermission = hasActivateAudiencePermission(abiPermissions, channel);
    if (!hasPermission) return {isEnabled: false, isVisible: false};

    const isSupportedChannel = channel === 'articles' || isSmartTvChannel(channel);
    if (!isSupportedChannel) return {isEnabled: false, isVisible: false};

    const hasAlwaysOnPermission = abiPermissions.hasPermission('audience activation - always on');
    const canActivateCustom = !audience.activation?.amplified || (channel !== 'articles' && !audience.activation?.deterministic);
    const canActivateAlwaysOn = hasAlwaysOnPermission && !audience.activation?.always_on;
    if (!canActivateCustom && !canActivateAlwaysOn) return {
        isEnabled: false,
        isVisible: true,
        disabledText: 'This segment has been activated. To activate again please clone and activate this audience.'
    };

    // smart_tv audiences are always set to US, no need to check demographics
    if (channel === 'articles') {
        const geo = getGeoFromAudienceSegment(audience.segment);
        if (!geo) return {
            isEnabled: false,
            isVisible: true,
            disabledText: 'Please select a Geo to enable Audience Targeting.'
        };

        const amplificationGeos = ['US', 'AU', 'GB'];
        const isSupportedGeo = amplificationGeos.includes(geo);
        if (!isSupportedGeo) return {
            isEnabled: false,
            isVisible: true,
            disabledText: 'We currently do not support Audience Targeting in the selected Geos.'
        };
    } else if (['tivo', 'smart_tv_inscape'].includes(channel) && Boolean(_.find(audience.segment, { type: '1st party' }))) {
        return {
            isEnabled: false,
            isVisible: true,
            disabledText: 'We currently do not support Audience Targeting for TV audiences with first party segment criteria.'
        };
    }

    const isBidstream = channel === 'articles';
    const minimalSize = 500;
    const isDemographicsTooNarrow = await checkDemographicsTooNarrow(audience.segment, channel, userId, isBidstream, minimalSize);
    if (isDemographicsTooNarrow) return {isEnabled: false, isVisible: true, disabledText: 'The audience you have selected is too narrow for Audience Targeting. Please expand your audience criteria.'};

    return {isEnabled: true, isVisible: true};
}

async function checkDemographicsTooNarrow(segment, channel, userId, isBidstream, minimalSize) {
    const size = await getAudienceSize(segment, {
        channel,
        userId,
        isBidstream,
        minimalSize,
        filterBidstreamDomains: isBidstream,
    });
    return isAudienceSizeTooSmall(size) && channel !== "hisense"; // Temp fix allow activate small audience in Hisense
}

function isSmartTvChannel(channel) {
    return ['smart_tv_inscape', 'tivo', 'hisense'].includes(channel);
}

function isAdvancedTvChannel(channel) {
    return ['tivo', 'hisense'].includes(channel);
}

export function getGeoFromAudienceSegment(segment) {
    const geo = segment.find(({ type }) => /demographics/i.test(type))?.geo || {};
    return _.isArray(geo) ? geo[0].cc : geo?.cc;
}

export function isDeterministicDynamicActivationAllowed(segment) {
    return !_.some(segment || [], (s) => (TV_SHOWS_SEGMENT_TYPES + COMMERCIALS_SEGMENT_TYPES).includes(s.type) &&
                                         !s.timeframe);
}
