"use strict";
require("pages/targets/targets-common");
import config from 'infra/config';
import {uniqBy, cloneDeep} from "lodash";
import ComplianceService from 'react/services/ComplianceService';
import * as TargetsService from "../../../react/services/TargetsService";
import * as TargetComplianceService from 'react/services/TargetComplianceService';
import * as MixpanelDiscovery from '../../../react/infra/mixpanel/MixpanelDiscovery';

const dynamicTrendsEditHelper = require("pages/targets/dynamic-trends-edit-helper"),
      modalQueries = require('pages/discovery/modal/grid.queries.modal');

const get = (path, object) =>
  path.reduce((xs, x) =>
    (xs && xs[x]) ? xs[x] : null, object)

editTargetCtrl.$inject = ['$scope', '$state', '$http', '$q', '$timeout', 'parentTab', 'CHANNEL', 'CTX_TARGETS_SKEW', 'TARGET_CONSTS', 'MARKET_CONTEXT', 'abiPermissions', 'context', 'TargetsCommon',  'dataType', 'currentTarget', 'Excel', 'ssoService', 'dspService', 'close', 'ModalService', 'notificator', 'sentimentsPopupService', 'SENTIMENT', 'CHART', 'phrasesPromise', 'setContextualUniquenessError'];
function editTargetCtrl($scope, $state, $http, $q, $timeout, parentTab, CHANNEL, CTX_TARGETS_SKEW, TARGET_CONSTS, MARKET_CONTEXT, abiPermissions , context, TargetsCommon,  dataType, currentTarget, Excel, ssoService, dspService, close, ModalService, notificator , sentimentsPopupService, SENTIMENT, CHART, phrasesPromise, setContextualUniquenessError) {

    $scope.CHANNEL = CHANNEL;
    $scope.editTargetCtrl = this;
    $scope.Math = window.Math;
    $scope.hasPermission = abiPermissions.hasPermission;
    $scope.isSavedTargetActivated = currentTarget.activated_to && currentTarget.activated_to.length;
    $scope.prevMarketId = currentTarget.market_id;
    $scope.queries = modalQueries;
    $scope.manualPhrases = null;
    $scope.openComplianceModal = false;
    $scope.nonCompliantPhrases = {};
    $scope.nonCompliantPhrasesByTab = {};
    $scope.phrasesData = {};
    $scope.nonCompliantTopics = [];
    $scope.isNewTarget = currentTarget.target_type === TARGET_CONSTS.AUDIENCE_TARGET_TYPE && !currentTarget._id;
    $scope.isCloneEnabled  = !$scope.isNewTarget;
    $scope.isDeleteEnabled = !$scope.isNewTarget;
    $scope.currentTarget = currentTarget;
    $scope.allGeos = $scope.Geos.serverValue();

    let me = this, closeMode = '';

    this.editingName = false;
    this.showDynamicMessage = false;
    this.targsetSeeds = "";
    this.targetConnected = null;

    $scope.init = async function () {

        $scope.setTab();
        $scope.marketContext = {};
        $scope.wasAlreadyFlaggedForComplianceCheck = TargetComplianceService.isActivated($scope.currentTarget.activated_to);
        $scope.wasAlreadyExclusion = $scope.currentTarget.incOrExc === "exclude";

        //topics tree
        if($scope.hasManualTopics){
            $scope.dataTrees = { manualTopicsTree: $.extend(true, {} , {allSelectedLabel: 'All Categories',
                children: $scope.$root.topicsFilter.slice(0), checkedArray: []})
            };

            $scope.dataTrees.manualTopicsTree.saveChecked = (checkedArray)=>{
                $scope.currentTarget.topics = checkedArray.map((c)=>{return c.id;});
                $scope.currentTarget.limitTopics = $scope.dataTrees.manualTopicsTree.checkedArray.length ? true : false;
                $scope.currentTarget.topical = $scope.currentTarget.limitTopics;
                $scope.$broadcast("change-topics", checkedArray.map((t)=>t.id));
            }
        }

        $scope.$on('fixedMarket', (e, value) => { $scope.market_context_fixed = value });

        if($scope.isNewTarget){
            $scope.currentTarget.results = await me.getNewTargetPhrases();
            $scope.currentTarget.results.phrases = $scope.currentTarget.results.phrases.filter(phrase => phrase['uniqueness-index'] >= CTX_TARGETS_SKEW.MEDIUM_SKEW)

            if (!me.isTargetUnique()) {
                setContextualUniquenessError(true);
                me.close();

                return;
            }
        }

        me.loadData();
    };

    this.isTargetUnique = function () {
        if ($scope.currentTarget.results[$scope.tab].length < CTX_TARGETS_SKEW.TOTAL_COUNT) {
            return false;
        }

        const results_skew_distribution = $scope.currentTarget.results[$scope.tab].reduce((acc, phrase) => {
            const skew_type = phrase['uniqueness-index'] >= CTX_TARGETS_SKEW.HIGH_SKEW ? 'high' : 'medium';
            const consumption_type = phrase['interest-portion'] * 100 >= CTX_TARGETS_SKEW.CONSUMPTION ? 'consumption' : 'no_consumption';
            acc[skew_type][consumption_type] += 1;

            return acc
        }, { high: { consumption: 0, no_consumption: 0 }, medium: { consumption: 0, no_consumption: 0 }});
        const total_high_skew = results_skew_distribution.high.consumption + results_skew_distribution.high.no_consumption;
        const total_medium_skew = results_skew_distribution.medium.consumption + results_skew_distribution.medium.no_consumption;
        const total_consumption = results_skew_distribution.high.consumption + results_skew_distribution.medium.consumption;
        const is_high_skew_exists = total_high_skew >= CTX_TARGETS_SKEW.HIGH_SKEW_COUNT && 
            total_high_skew - CTX_TARGETS_SKEW.HIGH_SKEW_COUNT + total_medium_skew >= CTX_TARGETS_SKEW.TOTAL_COUNT - CTX_TARGETS_SKEW.HIGH_SKEW_COUNT;
        const is_consumption_exists = total_consumption >= CTX_TARGETS_SKEW.TOTAL_COUNT;

        return is_high_skew_exists || is_consumption_exists;
    }

    this.getNewTargetPhrases = async function(){
        try{
            const phrasesResults = await phrasesPromise();
            const phrases = phrasesResults.words;
            const audience_id = me.getAudienceID();
            phrases.forEach((phrase) => {
                phrase.audience_id = audience_id;
                phrase.id = phrase["phrase-id"]
            });
            return {phrases};
        }catch(error){
            return {phrases: []};
            notificator.error({body: "Error fetching target phrases"});
        }
    }

    this.toggleExclude = function(isOn){
        me.exclusionTarget = isOn;
        $scope.currentTarget.incOrExc = isOn? "exclude" : "include";

        $scope.editingCurrentTarget = !$scope.editingCurrentTarget;

        if(!isOn && $scope.wasAlreadyExclusion){
            $scope.wasAlreadyFlaggedForComplianceCheck = false;
        }
        $timeout(()=>{
            $scope.$apply();
            $scope.$broadcast('toggle-exclude', $scope.currentTarget.incOrExc);
            TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Exclude Btn", $scope.currentTarget.incOrExc);
        });

        TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Exclude Btn", $scope.currentTarget.incOrExc);
    };

    this.toggleViewTarget = function(isOn) {
        me.viewTarget = isOn;
        $scope.currentTarget.viewTarget = isOn;
        $scope.editingCurrentTarget = !$scope.editingCurrentTarget;
        $timeout(() => TargetsCommon.mixpanelTargets.collectUpdatedTargetData("View Target Btn", $scope.currentTarget.viewTarget));
    };

    $scope.dynamicTrendsURL = function(){
        return config.USER_MGMT_API + '/targets/'+$scope.currentTarget._id+'/dynamic';
    };

    $scope.isEditing = function() {
      return $scope.editingCurrentTarget || (me.form && me.form.$dirty);
    };

    $scope.isDoneEnabled = ()=>{
        const enabled = $scope.isEditing() && !$scope.targetLoading && !$scope.nameError;
        if($scope.currentTarget.target_type === TARGET_CONSTS.AUDIENCE_TARGET_TYPE){
            return enabled && $scope.marketContext.market_id;
        }
        return enabled;
    }

    $scope.isManualPhrases = function(){
        return $scope.tab == 'phrases' || $scope.tab == 'websites';
    }

    $scope.targetIdText = ()=> currentTarget._id ? ('ID: ' + currentTarget._id + ',') : '';

    $scope.setTab = function (tab) {

        if(tab){
            $scope.tab = tab;
        }else if(parentTab){
            $scope.tab = parentTab;
        }else{
            //get 1st tab
            var tabs = Object.keys(currentTarget.results);
            $scope.tab = 'phrases';

            for(var i=0;i<tabs.length;i++){
                var tab = tabs[i];
                if($scope.queries[$scope.currentTarget.target_type+"_"+$scope.currentTarget.channel+"_"+tab]){
                    $scope.tab = tab;
                    break;
                }
            }

        }

        $scope.tabs = [];
        $scope.hasManualPhrases = $scope.isManualPhrases();
        $scope.hasManualTopics = TargetsCommon.TargetData.supportManualTopics($scope.currentTarget);


        //push tabs for this channel
        for(var i in $scope.queries){
            //example: i = trends_sg_telco_phrases
            let channel = i.substring(0, i.lastIndexOf("_"));
            for(let j in TARGET_CONSTS){
                channel = channel.replace(TARGET_CONSTS[j]+"_","");
            }

            if(i!="trends" && i!="dynamic" &&
                i.includes($scope.currentTarget.target_type+"_") &&
                channel == $scope.currentTarget.channel){
                $scope.tabs.push({channel_tab:i, tab: i.substring(i.lastIndexOf("_")+1)});
            }
        };

        let queryID = $scope.currentTarget.target_type+"_"+$scope.currentTarget.channel+"_"+$scope.tab;

        $scope.query = $scope.queries[queryID];
        let columns = $scope.query.columns.slice(0);
        const isUsOnly = _.isEqual(_.map(context.current.geo, 'cc'), ['US']);
        if (!isUsOnly) {
            _.remove(columns, 'usOnly');
        }
        $scope.queries['trends'].columns = columns;
        $scope.queries['trends'].order = $scope.query.order;
        $scope.queries['trends'].reverse = $scope.query.reverse;
        $scope.queries['dynamic'].columns = columns;
        $scope.queries['trends'].title = $scope.tab;
        $scope.queries['dynamic'].title = $scope.tab;

        //will place a callback function on each sort tracked column.
        if(undefined !== $scope.queries[queryID].sortCB){
            $scope.queries['trends'].sortCB = $scope.queries[queryID].sortCB;
            $scope.queries['dynamic'].sortCB = $scope.queries[queryID].sortCB;
        }

        if(tab){
            $scope.showTables();
        }
    }

    $scope.showTables = function () {
        var trends = [] , dynamic = [],
            query = $scope.currentTarget.results[$scope.tab] || [];

        $.each(query, function (i, cell) {
            cell.ellipsis = true;
            if(cell.dynamic){
                dynamic.push(cell);
            }else{
                trends.push(cell);
            }
        });

        $timeout(function(){

            $scope.queries['dynamic'].dataArray = dynamic.slice(0);//shows on "view current trends"
            $scope.queries['trends'].show(trends);
            var table = $("quick-table#trends");
            if(table.length==1){
                $scope.sentimentsPopupService.reset(table);
            }
        });
    };

    $scope.dynamicTrendsWindow  = function() {

        if(!$scope.currentTarget.dynamic) return;

        me.loadingTrends = true;

        if(me.isDynamicTempDataEmpty()){
            TargetsService.getDynamicTrends($scope.dynamicTrendsURL()).then(function(res){
                $scope.currentTarget = $.extend(true, {}, res);
                $scope.currentTarget.results[$scope.tab] = uniqBy($scope.currentTarget.results[$scope.tab].concat($scope.queries.trends.dataArray), "id");
                $scope.currentTarget.dynamic = true;
                $scope.showTables();
                TargetsCommon.TargetData.targetToUI({
                  target: $scope.currentTarget, context: context, action: 'edit-target'
                });
                me.openDynamicTrends();
            });
        }else{
            me.openDynamicTrends();
        }
    }

    this.selectAdvertisers = function() {
      return ($scope.currentTarget.target_type != TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE);
    }

    this.isFixedMarkets = function() {
        //in websites inventory, once you select a market, its fixed
        return $scope.market_context_fixed || ($scope.currentTarget.target_type ==
            TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE &&
            $scope.currentTarget.market_id &&
            $scope.prevMarketId);
    }

    this.isDynamicTempDataEmpty = function(){
        return _.isEmpty(me.dynamicTrendsEditHelper.dynamicTempData);
    }

    this.openDynamicTrends = function(){
       let editingCurrentTarget = $scope.editingCurrentTarget;

        $timeout(()=>{
                me.loadingTrends = false;
                me.dynamicTrendsOpened = true;
                ModalService.showModal({
                    template: require('./dynamic-trends.html'),
                    controller: require('./dynamic-trends.js'),
                    controllerAs: 'dynamicTrendsCtrl',
                    inputs: {
                        parentScope: $scope
                    }
                });
        });

        //prevent enabling 'done' if you just clicked 'view trends'
        $timeout(()=>{$scope.editingCurrentTarget = editingCurrentTarget;});
    }

    $scope.manualPhrasesWindow  = function() {

        me.addManualPhrasesOpened = true;
        ModalService.showModal({
            template: require('./manual-phrases.html'),
            controller: require('./manual-phrases.js'),
            controllerAs: 'manualPhrasesCtrl',
            inputs: {
                parentScope: $scope
            }
        });
    }

    $scope.sentimentsPopupService = sentimentsPopupService;

    $scope.sentimentsTooltip = function (row, event) {
        var data = SENTIMENT.setRowData(row);
        $scope.sentimentsPopupService.activate($(event.target), data, {});
    };

    $scope.manualPhrasesClosed = function(){
        me.addManualPhrasesOpened = false;
    }

    $scope.currentTarget = currentTarget;
    this.isActivated = false; //set by markets list
    this.limitTopicsOpened = false;
    this.dynamicTrendsOpened = false;
    this.dynamicTrendsEditOpened = false;
    this.addManualPhrasesOpened = false;
    this.dynamic = currentTarget.dynamic || false;
    this.exclusionTarget = currentTarget.incOrExc == "exclude";
    this.viewTarget = currentTarget.viewTarget == null ? !$scope.user.email.endsWith("@amobee.com") : currentTarget.viewTarget

    this.targetModes = [
        {title: 'Dynamic', value: 'dynamic'},
        {title: 'Static', value: 'static'}
    ];

    this.close = function () {
        if(me.dynamicTrendsEditHelper){
            me.dynamicTrendsEditHelper.resetData();
        }
        close({action: closeMode, target: $scope.currentTarget});
        TargetsCommon.mixpanelTargets.resetUpdatedData();
    };

    this.clicked = function(){
        if(me.limitTopicsOpened){
            me.limitTopicsOpened = false;
        }
    }

    this.validate = function(){

        if(0==$("#target-name").val().length){

            notificator.notify({body: "please enter target name."});
            return false;

        }else if($scope.hasManualTopics && $scope.currentTarget.limitTopics && !$scope.dataTrees.manualTopicsTree.checkedArray.length){

            notificator.notify({body: "please select at least one topic, or uncheck 'Limit to categories'."});
            return false;

        }else if(
            $scope.currentTarget.activated_to &&
            $scope.currentTarget.activated_to.length &&
            $scope.currentTarget.activated_to[0] !=='demo' &&
            typeof $scope.marketContext.market_id==='undefined'){

            notificator.notify({body: "Target not saved! choose a market and then click the Done button."});
            return false;
        }else if(
            $scope.currentTarget.activated_to &&
            $scope.currentTarget.activated_to.length &&
            $scope.currentTarget.activated_to[0] !=='demo' &&
            $scope.currentTarget.market_id != MARKET_CONTEXT.ALL_MARKETS_ID &&
            typeof $scope.marketContext.advertiser_id==='undefined'){

            notificator.notify({body: "Target not saved! choose an advertiser and then click the Done button."});
            return false;
        }

        return true;
    }

    this.setCurrentTargetsMarketContext = function(){
      let selected = $scope.currentTarget.activated_to.includes("turn");
      if (selected) {
        if (!(_.isNil($scope.currentTarget.market_id))) {
          $scope.marketContext.market_id = parseInt($scope.currentTarget.market_id);
          if (!(_.isNil($scope.currentTarget.advertiser_id))) {
            $scope.marketContext.advertiser_id = parseInt($scope.currentTarget.advertiser_id);
          } else if ($scope.isSupportAllAdvertisers) {
            $scope.marketContext.advertiser_id = MARKET_CONTEXT.ALL_ADVERTISERS_ID;
          }
        }
      }
    };

    this.loadData = function(){
        $scope.queries['trends'].loading = true;
        let self = this;

        $timeout(function(){

            try{
                TargetsCommon.TargetData.targetToServer({target: $scope.currentTarget});
                TargetsCommon.mixpanelTargets.initLimitTopics($scope.currentTarget);

                if($scope.hasManualTopics){
                    $scope.dataTrees.manualTopicsTree.checkedArray = ($scope.currentTarget.topics || []).map(function(t){return {id: t};});
                    $scope.dataTrees.manualTopicsTree.show();
                }

                $scope.queries['trends'].loading = false;

                //activated_to
                $scope.isSupportAllMarkets = $scope.currentTarget.target_type !=
                    TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE &&
                    !$scope.currentTarget.first_party &&
                    $scope.hasPermission('activate to all markets');

                $scope.isSupportAllAdvertisers = !$scope.currentTarget.first_party;

                me.setCurrentTargetsMarketContext();

                me.dynamicTrendsEditHelper = dynamicTrendsEditHelper;
                me.dynamicTrendsEditHelper.init($scope, me, CHART);
                me.name = $scope.currentTarget.name;

                me.isShowDynamicMessage();

                TargetsCommon.TargetData.targetToUI({
                    target: $scope.currentTarget, context: context, action: 'edit-target'
                });

                $scope.showTables();

                $scope.$watch('currentTarget', function(newVal, oldVal) {
                    if(undefined === $scope.editingCurrentTarget){
                        $scope.editingCurrentTarget = false; //so it wont call on 1st load
                    }else{
                        $scope.editingCurrentTarget = true;
                    }
                }, true);


                if (self.isShowTargetConnected()) {
                  if($scope.isNewTarget){
                    me.targetConnected = false;
                    return;
                  }

                  TargetsCommon.hasAssignedObjects($scope.currentTarget).then(
                    (res) => { me.targetConnected = res; },
                    (err) => { consloe.log("error: ", err); }
                  );
                }

                $scope.$broadcast("current-target-changed", $scope.currentTarget);
            }catch(error){
                notificator.error({body: "Error saving target '" + $scope.currentTarget.name + "'"});
            }
        });
    };

    this.setTargetMarket = function () {
      if (_.isNil($scope.marketContext.market_id)) {
        return;
      }
      $scope.currentTarget.market_id = $scope.marketContext.market_id;
      $scope.currentTarget.advertiser_id = $scope.marketContext.advertiser_id;
      me.isActivated = true;
      $scope.currentTarget.activated_to = ['turn'];
      TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Activated To", true);
    }

    this.checkCompliance = function(){
        $scope.targetLoading = true;
        let compliancePhrases = TargetComplianceService.getComplianceQueryPhrases($scope.currentTarget, $scope.phrasesData);

        //check target for GDPR compliance
        return new Promise(resolve => {
            try{
                const compliancePromisesArray = [];
                const phrasesCompliancePromise = ComplianceService.checkPhrases({
                    phrases: compliancePhrases,
                    feature: 'target',
                    checkGDPR: true,
                    checkNAI: false});
                compliancePromisesArray.push(phrasesCompliancePromise);
                const topics = get(['dataTrees', 'manualTopicsTree', 'checkedArray'], $scope) || [];
                const topicsCompliancepromise = ComplianceService.checkPhrases({
                    phrases: topics.map(topic => topic.name),
                    feature: 'target',
                    checkGDPR: true,
                    checkNAI: false});
                compliancePromisesArray.push(topicsCompliancepromise);
                Promise.all(compliancePromisesArray).then((resArray)=>{
                    const required = TargetComplianceService.isTargetRequireCompliance($scope.currentTarget, $scope.wasAlreadyFlaggedForComplianceCheck);
                    const passedOrNotRequired = ((resArray || []).length > 1 && (resArray[0] || {}).passed && (resArray[1] || {}).passed) || !required;

                    $scope.openComplianceModal = !passedOrNotRequired;
                    $scope.targetLoading = false;
                    $scope.complianceResults = resArray[0].results;
                    $scope.nonCompliantPhrases = TargetComplianceService.getNonCompliantPhrases(resArray[0].results);
                    $scope.nonCompliantPhrasesByTab = passedOrNotRequired ? null :
                        this.getNonCompliantPhrasesByTab($scope.nonCompliantPhrases);
                    $scope.nonCompliantTopics = passedOrNotRequired ? null : resArray[1].GDPR;
                    $scope.nonCompliantText = resArray[0].text !== '' ? resArray[0].text: resArray[1].text;

                    $timeout(()=>$scope.$apply());
                    resolve(passedOrNotRequired);
                })
            } catch(er) {
                $scope.targetLoading = false;
                $timeout(()=>$scope.$apply());
                resolve(false);
            }
        });
    };

    this.updateTarget = async function({skipCompliance = false}) {
        this.setTargetMarket();
        if(!this.validate()){
            return;
        }

        if(false === skipCompliance){
            const isCompliant = await this.checkCompliance();
            if(!isCompliant){
                return;
            }
        }
        $scope.targetLoading = true;

        TargetsCommon.TargetData.targetToServer({target: $scope.currentTarget});

        TargetsCommon.TargetData.targetToUI({
            target: $scope.currentTarget, context: context, action: 'edit-target'
        });

        let url = TargetsCommon.targetURL($scope.currentTarget);

        const accessToken = await ssoService.getAccessToken();
        const headers = { 'SSO-Access-Token' : accessToken.accessToken };
        const method = $scope.isNewTarget ? 'post' : 'put';
        var response;
        try{
            response = await $http({method, url, data: $scope.currentTarget, headers})

            $scope.broadcastRoot('updateTargets', {
                target: response.data,
                action: $scope.isNewTarget ? 'create-target' : 'update-target'
            });

            TargetComplianceService.saveTarget($scope.nonCompliantPhrases);
            TargetsCommon.mixpanelTargets.trackUpdatedData($scope);
            $timeout(()=>{
                $scope.targetLoading = false;
                if($scope.currentTarget.target_type === TARGET_CONSTS.AUDIENCE_TARGET_TYPE){
                    notificator.success({body: `Target "${$scope.currentTarget.name}" created.`});
                }
                this.close();
            });

            //when something was edited in the Dynamic Trends popup, dynamicTempData has values
            if(!me.isDynamicTempDataEmpty() && me.dynamicTrendsEditHelper.dynamicTempData.currentTarget.query.key_trends!= me.dynamicTrendsEditHelper.origQuery.key_trends){
                TargetsCommon.mixpanelTargets.trackKeyTrends(me.dynamicTrendsEditHelper.dynamicTempData.currentTarget);
            }

        }catch(err){
            let error = err.data && err.data.errors ? _.castArray(err.data.errors).join(" ") : "Error saving target '" + $scope.currentTarget.name + "'";
            $scope.targetLoading = false;
            notificator.error({body: error});
        }
    }

    this.getNonCompliantPhrasesByTab = (nonCompliantPhrases) => {

        let PhrasesByTab = Object.keys($scope.currentTarget.results).reduce((obj, key)=>{
            return {...obj, [key]: []}
        }, {});


        Object.keys(nonCompliantPhrases).forEach((phrase)=> {
            let tabs = $scope.phrasesData[phrase].tabs;
            //1 phrase can be in 2 tabs , example: infulencers + audience
            tabs.forEach((tab)=>PhrasesByTab[tab].push(phrase))
        });

        return PhrasesByTab;
    }

    this.delete = function(){
      $scope.deleteTarget = $scope.currentTarget;
    }

    $scope.deleteConfirm = function(){
        TargetsCommon.deleteConfirm($scope);
        close('deleted');
    }

    $scope.mixpanelTrackSort = function (params) {
        MixpanelDiscovery.trackSort(params);
    };

    $scope.isInternal = (user_email) => user_email.endsWith("@amobee.com");

    this.cloneTarget = function () {

        let url = TargetsCommon.targetURL($scope.currentTarget)+'/clone';

        let target = $.extend(true, {}, $scope.currentTarget);
        TargetsCommon.TargetData.targetToServer({target: target});
        $scope.targetLoading = true;

        ssoService.getAccessToken().then(accessToken => {
          let headers = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
          $http.post(url, target, headers).then(function (res) {
              $scope.currentTarget = $.extend(true, {}, res.data);
              $scope.broadcastRoot('updateTargets', {target: $scope.currentTarget, action: 'create-target'});
              notificator.success({body: 'Target "' + $scope.currentTarget.name + '" cloned'});
              close('cloned');
              $scope.targetLoading = false;

          }, (res)=>{
              console.log(res.data);
              notificator.error({body: "Failed to clone target."});
          });
        });
    };

    this.removeManualPhrases = function (itemToDelete) {
        //remove but not save yet

        var queryType = itemToDelete.dynamic? 'dynamic' : 'trends';
        var dataArray = $scope.queries[queryType].dataArray;
        dataArray.splice($.inArray(itemToDelete, dataArray), 1 );

        if(queryType=="trends"){
            $scope.queries[queryType].show(dataArray);
        }else{
            $scope.$broadcast('updateDynamicTrends');
        }

        let arr = $scope.currentTarget.results[$scope.tab];
        const index = $.inArray(itemToDelete, arr);
        $scope.currentTarget.results[$scope.tab] = arr.filter((_, i) => i !== index);


        notificator.success({
            body: '1 '+TargetsCommon.TargetData.typePlural($scope.tab,1)+' removed from target "' + $scope.currentTarget.name + '"'
        });

        if(!me.dynamicTrendsOpened){
           $scope.$broadcast('remove-phrase', {phrase: itemToDelete, tab: $scope.tab});
        }
    };

    this.openCloseEditName = function(editingName) {
        me.editingName = undefined != editingName ? editingName : !me.editingName;

        if(me.editingName){
            $timeout(()=>{
                $(".edit-name").focus();
            });
        }
    }


    this.openDynamicTrendsEdit  = function(targetInfo, dynamicTrendsCtrl) {
        if(!$scope.currentTarget.dynamic) return;

        me.dynamicTrendsEditOpened = true;
        ModalService.showModal({
            template: require('./dynamic-trends-edit.html'),
            controller: require('./dynamic-trends-edit.js'),
            controllerAs: 'dynamicTrendsEditCtrl',
            inputs: {
                parentScope: $scope,
                targetInfo: targetInfo,
                dynamicTrendsCtrl: dynamicTrendsCtrl,
                context: context
            }
        });

    }

    this.displayDate = function(){
        return moment($scope.currentTarget.date_added).format('MMM D YYYY');
    }

    this.checkNameChange = function(e){
        if(e.keyCode==13 && this.validate()){ //enter
            this.nameChange('close');
        }
    }

    this.nameChange = async function(close){

        if(close){
            me.editingName = false;
        }

        if($("#target-name").val() == this.name){
            $scope.nameError = '';
            return;
        }

        const name = $("#target-name").val();
        const isCompliant = await this.checkComplianceName(name);
        if(!isCompliant){
            return;
        }
        this.name = name;
    }

    this.checkComplianceName = function(name){
        return new Promise(resolve => {

            $scope.targetLoading = true;

            ComplianceService.checkPhrases({
                phrases: name.split(" "),
                feature: 'target',
                isTitle: true,
                checkGDPR: true,
                checkNAI: true,
            }).then((res)=>{
                $scope.targetLoading = false;

                if(res.passed){
                    $scope.nameError = '';
                    $scope.currentTarget.name = name;
                }else{
                    $scope.nameError = res.text;
                }

                $timeout(()=>$scope.$apply());
                resolve(res.passed);
            })
        })
    }

    this.genDesc = function(query){
        var desc = {};
        query.columns.forEach(function(col){
            desc[col.title] = {key: col.id, width: 20}
        });
        return desc;
    };

    this.supportDynamic = function(justChannel){
        var support = TargetsCommon.TargetData.supportDynamic($scope.currentTarget);
        if(!justChannel){
            support = support && me.isActivated;
        }
        return support;
    };

    this.isShowExclusion =()=> {
        return $scope.hasPermission('Create Exclusion Target') &&
        $scope.currentTarget.target_type != TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE;
    };

    this.isShowViewTarget =()=> {
      return $scope.isInternal($scope.user.email) &&
             (!$scope.currentTarget._id ||
              ($scope.isInternal($scope.currentTarget.sso_email) && $scope.currentTarget.source == 'bi_target'));
    };

    this.isShowTargetConnected =()=> {
      return $scope.currentTarget.target_type != TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE;
    };

    this.isSupportTargetForecast = () => {
        return $scope.hasPermission('target size estimator') && $scope.currentTarget && $scope.currentTarget.channel === 'web';
    };

    this.showMarketContext = function() {
      return $scope.hasPermission('activate targets');
    };

    this.isShowDynamicMessage = function() {
        let hasPhrases = TargetsCommon.TargetData.hasPhrases($scope.currentTarget);
        me.targsetSeeds = TargetsCommon.TargetData.getQueryPhrases($scope.currentTarget);

        if($scope.currentTarget.dynamic && !hasPhrases && me.targsetSeeds.length){
            me.showDynamicMessgae = true;
        }else{
            me.showDynamicMessgae = false;
        }

        TargetsCommon.TargetData.targetToUI({
            target: $scope.currentTarget, context: context, action: 'edit-target'
        });
    }

    this.toggleDynamic = function() {
        $scope.currentTarget.dynamic = !$scope.currentTarget.dynamic;
        me.isShowDynamicMessage();

        $timeout(()=>{$scope.$apply();});

        if ($scope.currentTarget.dynamic) {
            TargetsService.getDynamicTrends($scope.dynamicTrendsURL()).then(function (res) {
                $scope.currentTarget.results['phrases'] = res['results']['phrases']
                $scope.currentTarget.results[$scope.tab] = uniqBy($scope.currentTarget.results[$scope.tab].concat($scope.queries.trends.dataArray), "id");
                $scope.currentTarget.dynamic = true;
                $scope.showTables();
                TargetsCommon.TargetData.targetToUI({
                    target: $scope.currentTarget, context: context, action: 'edit-target'
                });
            });
        } else {
            $scope.currentTarget.results[$scope.tab] = $scope.currentTarget.results[$scope.tab].filter(phrase => !phrase.dynamic);
        }

        TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Dynamic Trends", $scope.currentTarget.dynamic);

        $scope.$broadcast("toggle-dynamic", {
            on:  $scope.currentTarget.dynamic,
            url: $scope.dynamicTrendsURL(),
            tab: $scope.tab});
    };

    $scope.updateMarketContext = function({market_id, advertiser_id}) {
      let mc = angular.copy($scope.marketContext);
      mc.market_id = market_id;
      mc.advertiser_id = advertiser_id;
      $scope.marketContext = mc;
    };

    $scope.clearMarketContext = function() {
      $scope.updateMarketContext({market_id: undefined, advertiser_id: undefined});
    }

    this.toggleLimitTopics = function(){
        $scope.currentTarget.limitTopics = $scope.currentTarget.limitTopics ? false : true;

        if($scope.hasManualTopics && !$scope.currentTarget.limitTopics && $scope.dataTrees.manualTopicsTree.checkedArray.length){
            me.limitTopicsOpened = false;
            $scope.currentTarget.topics = [];
            $scope.dataTrees.manualTopicsTree.clickAll('');
        }

        $timeout(()=>{$scope.$apply();});

        TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Limit to categories", $scope.currentTarget.limitTopics);
    }

    this.getAudienceID =()=>{
        const channel = context.current.audience_app.current_channel.value;
        return context.current.audience_app[channel].audience_id;
    }

    $scope.toggleTopicsOpened = function(){
        me.limitTopicsOpened = !me.limitTopicsOpened;
    }

    $scope.addManualPhrases = function(manualPhrases){

        $scope.manualPhrases = manualPhrases.slice(0);

        var alreadyExists = '', i, j,
            res = $scope.currentTarget.results[$scope.tab] || [],
            len = res.length,
            lenManual = $scope.manualPhrases.length;

        for(i=0; i<lenManual; i++){
            var x = $scope.manualPhrases[i];

            var manual = $scope.manualPhrases[i].text.toLowerCase();

            for(j=0; j<len; j++){
                var text = res[j].phrase || res[j].text;
                text = text.toLowerCase();
                if(text == manual && !res[j].dynamic){
                    alreadyExists += manual+" , ";
                    $scope.manualPhrases[i].alreadyExists=true;
                }
            }
        }

        if(alreadyExists){
            notificator.notify({body: alreadyExists.substring(0, alreadyExists.length-3)+" already exists"});
            $scope.manualPhrases = $scope.manualPhrases.filter(function(phrase){
                return !phrase.alreadyExists;
            });
        }

        if(!$scope.manualPhrases.length){
            return;
        }

        const defaultManualPhraseProps = {highlighted: true, manual: true, highlightMarkTooltip: 'Manually added'};

        const audience_id = me.getAudienceID();
        $scope.manualPhrases.forEach((phrase) => {
            Object.assign(phrase, defaultManualPhraseProps);
            let target_type = $scope.currentTarget.target_type || '';
            if (target_type.includes(TARGET_CONSTS.AUDIENCE_TARGET_TYPE) ||
                target_type.includes(TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE)) {
                phrase.audience_id = audience_id;
            }
            TargetsCommon.TargetData.convertManualPhrase(phrase);
        });

        $scope.currentTarget.results[$scope.tab] =
            ($scope.currentTarget.results[$scope.tab]||[]).concat($scope.manualPhrases);

        TargetsCommon.TargetData.targetToUI({
            target: $scope.currentTarget, context: context, action: 'edit-target'
        });

        notificator.success({
            body: $scope.manualPhrases.length + ' '+
                TargetsCommon.TargetData.typePlural($scope.tab, $scope.manualPhrases.length)+' added to target "' + $scope.currentTarget.name + '"'
        });

        TargetsCommon.mixpanelTargets.collectUpdatedTargetData("Add Phrase", $scope.manualPhrases);

        $scope.$broadcast("add-phrases", {phrases: $scope.manualPhrases, tab: $scope.tab});

        $scope.showTables();
        $scope.manualPhrases = null;

        $timeout(function(){$(".manual #clear-all-seeds").trigger("click");});

    };

    $scope.broadcastRoot = function(name, params){
        //window may have closed , thus deleting the ref
        if($scope && $scope.$root){
            $scope.$root.$broadcast(name, params);
        }
    }

    this.export = function () {
        return $q.when(true).then(function () {
            let workbook = Excel.builder.createWorkbook();

            workbook.fileName = 'Target ' + $scope.currentTarget.name + ' ' + moment().format('YYYY-MM-DD HH:mm') + '.xlsx';

            $scope.tabs.forEach(function(t){
                if(t.tab != "dynamic" && t.tab != "trends"){

                    var nonDynamic = ($scope.currentTarget.results[t.tab]||[]).filter(function(phrase){
                        return !phrase.dynamic;
                    });

                    Excel.addTableSheet(workbook,
                        nonDynamic,
                        me.genDesc($scope.queries[t.channel_tab]), {
                        name: t.tab
                    });
                }
            });

            if(me.supportDynamic()){
                Excel.addTableSheet(workbook,
                    $scope.queries['dynamic'].dataArray,
                    me.genDesc($scope.queries['dynamic']), {
                    name: 'dynamic'
                });
            }

            return workbook;
        });
    };

    //compliance
    $scope.onCloseCompliance = ()=>{
        $scope.openComplianceModal = false;
        $timeout(()=>$scope.$apply());
    }

    $scope.saveWithNonCompliant = ()=>{
        this.updateTarget({skipCompliance: true});
        $scope.onCloseCompliance();
    }

    $scope.saveWithoutNonCompliant = (nonCompliantPhrasesByTab)=>{
        Object.keys($scope.currentTarget.results).forEach((tab)=>{

            if(TargetComplianceService.isTabRequireCompliance(tab)){

                //remove all non compliant phrases
                $scope.currentTarget.results[tab] = $scope.currentTarget.results[tab].filter((phraseEntity)=>{

                    let phrase = TargetComplianceService.entityText(phraseEntity, tab);
                    let phraseObj = $scope.complianceResults[phrase];
                    return TargetComplianceService.isPhraseObjCompliance(phraseObj);
                });
            }
        });

        if ($scope.nonCompliantTopics?.length > 0) {
            const compliantTopics = $scope.dataTrees.manualTopicsTree.checkedArray.filter(topic => !($scope.nonCompliantTopics.includes(topic.name)));
            $scope.dataTrees.manualTopicsTree.checkedArray = compliantTopics;
            $scope.dataTrees.manualTopicsTree.show();
            $scope.dataTrees.manualTopicsTree.saveChecked(compliantTopics);
        }

        $timeout(()=>{
            $scope.$apply();
            $scope.nonCompliantPhrases = {};
            $scope.saveWithNonCompliant();
        });
    }

    $scope.init();
}

module.exports = editTargetCtrl;
