import React from 'react';
import { Icon } from '@amobee/component-library';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import './ResultsTable.scss';

const ResultsTable = ({ resultsTable, onReset, onRemove }) => (
  <div className="results-table-component">
    <div className="results-table-header">
      <div className="header-title-left">Selected</div>
      <div className="header-title-right" onClick={onReset}>
        Reset
      </div>
    </div>

    <div className="results-table-container">
      {resultsTable.map((resultWrapper) => (
        <div className="results" key={resultWrapper.type}>
          <div className="result-type">{resultWrapper.title}</div>
          {resultWrapper.results.map((result) => (
            <div className="result" key={result.value}>
              <div className="main-text">{result.label}</div>
              <div className="secondary-text">{result.secondaryText}</div>
              <div className="remove-result cli" onClick={() => onRemove(result, resultWrapper.type)}>
                <Icon name="Remove" />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default ResultsTable;

ResultsTable.propTypes = {
  resultsTable: PropTypes.any,
  onRemove: PropTypes.any,
  onReset: PropTypes.any,
};

ResultsTable.defaultProps = {
  resultsTable: [],
  onRemove: noop,
  onReset: noop,
};
