import React from 'react';
import Widget from '../../../../common/Widget/Widget';
import PropTypes from 'prop-types';
import AudiencePreview from '../AudiencePreview/AudiencePreview';
import { createPromiseEvaluatedToComponentProps } from './AudiencePreviewWidgetUtils';
import { noop } from 'lodash';
import AudienceTarget from '../AudienceTarget/AudienceTarget';

const AudiencePreviewWidget = (props) => {
  const audienceTarget = props.isActivateVisible ? (
    <AudienceTarget
      marketsAndAdvertisersPromise={props.marketsAndAdvertisersPromise}
      audienceId={props.audienceId}
      audienceName={props.audienceName}
      audienceSegment={props.audienceSegment}
      isActivateEnabled={props.isActivateEnabled}
      isActivateAudienceEnabled={props.isActivateAudienceEnabled}
      isActivateAudienceVisible={props.isActivateAudienceVisible}
      isActivateTargetEnabled={props.isActivateTargetEnabled}
      activateAudienceDisabledText={props.activateAudienceDisabledText}
      channel={props.channel}
      getSegmentIds={props.getSegmentIds}
      createAudienceTargetTaxonomy={props.createAudienceTargetTaxonomy}
      createAudienceTargetUserList={props.createAudienceTargetUserList}
      createAmplifiedAudience={props.createAmplifiedAudience}
      createAlwaysOnAudience={props.createAlwaysOnAudience}
      createDeterministicAudience={props.createDeterministicAudience}
      createUserListForDeterministicAudience={props.createUserListForDeterministicAudience}
      notificator={props.notificator}
      updateAudienceDeterministicActivated={props.updateAudienceDeterministicActivated}
      updateAudienceAmplifiedActivated={props.updateAudienceAmplifiedActivated}
      updateAudienceAlwaysOnActivated={props.updateAudienceAlwaysOnActivated}
      dspService={props.dspService}
      isAudienceDeterministicActivated={props.isAudienceDeterministicActivated}
      isAudienceAmplifiedActivated={props.isAudienceAmplifiedActivated}
      isAudienceAlwaysOnActivated={props.isAudienceAlwaysOnActivated}
      activatedAmplifiedThreshold={props.activatedAmplifiedThreshold}
      activatedAlwaysOnThreshold={props.activatedAlwaysOnThreshold}
      activatedMarket={props.activatedMarket}
      activatedAdvertiser={props.activatedAdvertiser}
      activatedDataContractId={props.activatedDataContractId}
      activatedDataContractText={props.activatedDataContractText}
      activatedCategoryId={props.activatedCategoryId}
      ssoService={props.ssoService}
      getAmplifiedEstimatedReachGoal={props.getAmplifiedEstimatedReachGoal}
      handleActivatingAudience={props.handleActivatingAudience}
      handleActivatingTarget={props.handleActivatingTarget}
      hasAmplificationModeSelector={props.hasAmplificationModeSelector}
      getDataContract={props.getDataContract}
      getTaxonomyCategory={props.getTaxonomyCategory}
      isDeterministicDynamicAllowed={props.isDeterministicDynamicAllowed}
      isContextualUniquenessError={props.isContextualUniquenessError}
      allowDeterministicSegment={props.allowDeterministicSegment}
    />
  ) : undefined;

  return (
    <Widget
      name="AudiencePreviewWidget"
      dataService={createPromiseEvaluatedToComponentProps}
      dataServiceParams={[
        props.audienceSegment,
        props.channel,
        props.userId,
        props.audienceDistributionService,
        props.isRequestCancelledService,
        props.trackTooNarrow,
      ]}
      renderOnValueChange={[
        props.isActivateEnabled,
        props.isActivateVisible,
        props.isActivateAudienceEnabled,
        props.activateAudienceDisabledText,
        props.isActivateAudienceVisible,
        props.isActivateTargetEnabled,
        props.isAudienceDeterministicActivated,
        props.isAudienceAmplifiedActivated,
        props.isContextualUniquenessError,
      ]}
      onRender={(res) => (
        <AudiencePreview
          onExploreAudience={props.onExploreAudience}
          elementAfterSeparator={audienceTarget}
          isContextualUniquenessError={props.isContextualUniquenessError}
          {...res}
        />
      )}
      style={{ height: '87px' }}
      isSmallSpinner={true}
      onDataLoaded={(data) => props.onAudienceDataLoaded(data.hasData)}
      emptyStateRender={() => (
        <AudiencePreview onExploreAudience={props.onExploreAudience} elementAfterSeparator={audienceTarget} />
      )}
    />
  );
};

AudiencePreviewWidget.propTypes = {
  audienceDistributionService: PropTypes.func.isRequired,
  isRequestCancelledService: PropTypes.func.isRequired,
  channel: PropTypes.any.isRequired,
  userId: PropTypes.number.isRequired,
  trackTooNarrow: PropTypes.func.isRequired,
  onExploreAudience: PropTypes.func.isRequired,
  onAudienceDataLoaded: PropTypes.func,
  marketsAndAdvertisersPromise: PropTypes.object,
  audienceId: PropTypes.string,
  audienceName: PropTypes.string,
  audienceSegment: PropTypes.array,
  isActivateEnabled: PropTypes.bool.isRequired,
  isActivateVisible: PropTypes.bool.isRequired,
  isActivateAudienceEnabled: PropTypes.bool.isRequired,
  isActivateTargetEnabled: PropTypes.bool.isRequired,
  isActivateAudienceVisible: PropTypes.bool.isRequired,
  activateAudienceDisabledText: PropTypes.string,
  onTargetAudienceSubmit: PropTypes.func,
  isTargetModalProcessing: PropTypes.bool,
  getSegmentIds: PropTypes.func,
  createAudienceTargetTaxonomy: PropTypes.func,
  createAudienceTargetUserList: PropTypes.func,
  createAmplifiedAudience: PropTypes.func,
  createAlwaysOnAudience: PropTypes.func,
  createDeterministicAudience: PropTypes.func,
  createUserListForDeterministicAudience: PropTypes.func,
  notificator: PropTypes.object,
  dspService: PropTypes.object,
  updateAudienceDeterministicActivated: PropTypes.func,
  updateAudienceAmplifiedActivated: PropTypes.func,
  updateAudienceAlwaysOnActivated: PropTypes.func,
  isAudienceDeterministicActivated: PropTypes.bool,
  isAudienceAmplifiedActivated: PropTypes.bool,
  isAudienceAlwaysOnActivated: PropTypes.bool,
  activatedAmplifiedThreshold: PropTypes.number,
  activatedAlwaysOnThreshold: PropTypes.number,
  activatedMarket: PropTypes.object,
  activatedAdvertiser: PropTypes.object,
  activatedDataContractId: PropTypes.number,
  activatedDataContractText: PropTypes.string,
  activatedCategoryId: PropTypes.number,
  ssoService: PropTypes.object,
  getAmplifiedEstimatedReachGoal: PropTypes.func.isRequired,
  handleActivatingAudience: PropTypes.func.isRequired,
  handleActivatingTarget: PropTypes.func.isRequired,
  hasAmplificationModeSelector: PropTypes.bool,
  getDataContract: PropTypes.func,
  getTaxonomyCategory: PropTypes.func,
  isDeterministicDynamicAllowed: PropTypes.bool,
  isContextualUniquenessError: PropTypes.bool,
  allowDeterministicSegment: PropTypes.bool,
};

AudiencePreviewWidget.defaultProps = {
  onAudienceDataLoaded: noop,
};

export default AudiencePreviewWidget;
