import 'angular-ui-router';
import moment from 'moment';
import { find } from 'lodash';
import { isTimeframeHidden, showDatePicker } from './timeframe/timeframe-utils';

const timeFrameFilter = require("./timeframe/timeframe"),
    contextModule = require("infra/context/context");

module.exports = angular.module(__filename, [
    timeFrameFilter.name,
    contextModule.name,
    'ui.router',
    require("data/discovery-service").name
]).directive("appCommonElements", ["context", "$state", '$rootScope', 'abiPermissions', 'TIMES', 'notificator', 'INSIGHTS_TIMELINE',
    function (context, $state, $rootScope, abiPermissions, TIMES, notificator, INSIGHTS_TIMELINE) {

        return {
            restrict: "E",
            template: require("./app-common-elements.html"),
            scope: {
                onExport: '='
            },
            link: function (scope) {
                const DATE_FORMAT = 'DD_MM_YY_HH_mm';
                const DISCOVERY_ALLOWED_PAST_MONTHS = 15;

                scope.context = context;
                scope.$state = $state;

                scope.times = [];
                scope.minDate = null;
                scope.subtractDay = false;

                scope.onToggleFilterMenu = $rootScope.toggleFilterBar;

                syncFiltersOnInit();

                scope.hasActiveFilter = function () {
                    const state = $state.current;
                    const display = state.display.toLowerCase();
                    return (['dashboard', 'discovery', 'insights'].includes(display) || state.name.startsWith('warroom')) && $rootScope.hasActiveFilter(state.name);
                };

                scope.hasPermission = abiPermissions.hasPermission;

                scope.hideFiltersMenu = function () {
                    const name = $state.current.name;

                    if (name.includes('warroom.groups')
                        ||name.includes('warroom.trending')) return true;

                    if (!name.startsWith('audience-')) return false;
                    const hasAudienceChannelPermissions = abiPermissions.hasPermission('audience au telco channel') || abiPermissions.hasPermission('audience linkedin channel') || abiPermissions.hasPermission('audience sg telco channel');
                    return !hasAudienceChannelPermissions || name !== 'audience-builder';
                };


                scope.hideExport = function () {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                           || (state.name.startsWith('settings.') && state.name !== 'settings.users' && state.name !== 'settings.targets')
                           || state.name.indexOf('warroom.groups') !== -1
                           || state.name.indexOf('warroom.realtime') !== -1
                           || state.name === 'audience-builder'
                           || state.name === 'alerts-v2'
                           || !scope.hasPermission(['export']);
                };

                scope.hideTimeframe = function () {
                    return isTimeframeHidden($state.current.name, $state.current.display);
                };

                scope.showDatePicker = function () {
                    return showDatePicker($state.current.name, $state.current.display, scope.hasPermission);
                };

                scope.onTimeFrameChanged = function (newValue) {
                    const value = newValue._value;
                    const state = $state.current;
                    if(!state.display.toLowerCase() === 'discovery' && !state.display.toLowerCase() === 'insights') {
                        return;
                    }

                    context.current._insights_timeframe_mold._value = value;
                    context.current._discovery_timeframe_mold._value = value;
                };

                scope.onTimelineIntervalChange = (newValue) => {
                    context.current.insights_timeline_interval = newValue;
                    if(newValue === 'Daily') {
                        context.current.insights_timeline_timezone = INSIGHTS_TIMELINE.DEFAULT_TIMEZONE;
                    }
                };

               scope.onTimelineTimezoneChange = (newValue) => context.current.insights_timeline_timezone = newValue;

                scope.getTimeframeMold = function () {
                    const state = $state.current;
                    if (state.name.indexOf('warroom') !== -1)
                        return context.current._warroom_timeframe_mold;
                    if (state.display.toLowerCase() === 'discovery')
                        return context.current._discovery_timeframe_mold;
                    if (state.display.toLowerCase() === 'insights')
                        return context.current._insights_timeframe_mold;
                    return null;
                };

                scope.showTimelineInterval = () => {
                    const isHourlyChannel = ['articles', 'sg_bidstream'].includes(context.current.insightsChannels.value);
                    const hasHourlyPermission = abiPermissions.hasPermission(['hourly consumption trends']);
                    if($state.current.display.toLowerCase() !== 'insights' || !isHourlyChannel || !$state.includes('*.consumption') || !hasHourlyPermission) {
                        resetTimelineIntervalAndTimezone();
                        return false;
                    }

                    const dateRange = context.current._insights_timeframe_mold._value;
                    if(_.isNumber(dateRange[0])) {
                        return dateRange[0] <= 6 && dateRange[1] === 'day';
                    }
                    const startDate = moment(dateRange[0], DATE_FORMAT);
                    const endDate = moment(dateRange[1], DATE_FORMAT);
                    return moment(endDate).diff(startDate, 'days') <= 6;
                }

                scope.$on('timeframe-vars-update', function(event, times, minDate, subtractDay, fullWeeksDetection, noMaxTimeframeNotification, maxSpan) {
                    if (times) {
                        times = TIMES.getTimes(times);
                        if (!_.isEqual(times, scope.times)) {
                            scope.times = times;
                        }
                    }

                    scope.minDate = minDate;
                    scope.subtractDay = !!subtractDay;
                    scope.fullWeeksDetection = !!fullWeeksDetection;
                    scope.noMaxTimeframeNotification = !!noMaxTimeframeNotification;
                    scope.maxSpan = maxSpan;
                });

              function isDiscoveryRangeInvalid(value) {
                  let startDate;
                  let subtractedToDate;

                  if(_.isNumber(value[0])) {
                      subtractedToDate = moment().subtract(getDiscoveryMaxMonthsRange(), 'month');
                      startDate = moment().subtract(value[1], value[0]);
                  } else {
                      subtractedToDate = moment(value[1], DATE_FORMAT).subtract(getDiscoveryMaxMonthsRange(), 'month');
                      startDate = moment(value[0], DATE_FORMAT);
                  }
                  return startDate.diff(subtractedToDate) < 0;
              }

              function getMaxDiscoveryRange(value) {
                  if(_.isNumber(value[0])) {
                      return [getDiscoveryMaxMonthsRange(), 'month'];
                  }

                  const from = moment(value[1], DATE_FORMAT).subtract(getDiscoveryMaxMonthsRange(), 'month').format(DATE_FORMAT);
                  const to =  moment(value[1], DATE_FORMAT).format(DATE_FORMAT);
                  return [from, to];
              }

              function getDiscoveryAllowedPastRange(value) {
                  const minDate = moment().subtract(DISCOVERY_ALLOWED_PAST_MONTHS, 'month').startOf('day');
                  const startDate = moment(value[0], DATE_FORMAT);
                  const endDate = moment(value[1], DATE_FORMAT);
                  const minDateDiff = moment(minDate).diff(startDate);

                  if(minDateDiff > 0) {
                      notificator.notify({body: 'Maximum look-back is 15 months. Timeframe was adjusted accordingly'});
                      if(moment(minDate).diff(endDate) > 0) {
                          const startEndDateDiff = endDate.diff(startDate);
                          return [minDate.format(DATE_FORMAT), minDate.add(startEndDateDiff).format(DATE_FORMAT)]
                      }
                      return [minDate.format(DATE_FORMAT), value[1]]
                  }
                  return value;
              }

              function syncFiltersOnInit() {
                  if($state.current.display.toLowerCase() === 'discovery') {
                      const value = context.current._discovery_timeframe_mold._value;
                      context.current._discovery_timeframe_mold._value = value;
                      if(isDiscoveryRangeInvalid(value)) {
                          context.current._discovery_timeframe_mold._value = getMaxDiscoveryRange(value);
                          const maxMonthRange = getDiscoveryMaxMonthsRange();
                          notificator.notify({body: `Maximum date span is ${maxMonthRange} month${maxMonthRange > 1 ? 's' : ''}. Timeframe was adjusted accordingly.`});
                        }
                        if(!_.isNumber(value[0])) {
                            context.current._discovery_timeframe_mold._value = getDiscoveryAllowedPastRange(context.current._discovery_timeframe_mold._value);
                        }
                      context.current._insights_timeframe_mold._value = context.current._discovery_timeframe_mold._value
                    }
                }

                function getDiscoveryMaxMonthsRange() {
                  return context.current.gridChannels.value !== 'facebook' ? 3 : 1;
                }

                function resetTimelineIntervalAndTimezone() {
                    context.current.insights_timeline_interval = INSIGHTS_TIMELINE.DEFAULT_INTERVAL;
                    context.current.insights_timeline_timezone = INSIGHTS_TIMELINE.DEFAULT_TIMEZONE;
                }
            }
        }
    }
]);
