var angularUtils = require('common/angularUtils');

module.exports = angular.module(__filename, [
  require("../../../../common/am-switch-button/am-switch-button").name,
  require("../../../../common/am-input-collection/am-input-collection.js").name
]).directive('permissionsPanel', ['$timeout', function ($timeout) {

    const NONE_SELECTED = Symbol.for('None Selected');
    const ALL_SELECTED = Symbol.for('All Selected');
    const SOME_SELECTED = Symbol.for('Some Selected');

    return {
        restrict: 'E',
        require: ['^form', 'ngModel'],
        template: require('./permissions-panel.drv.html'),
        scope: {
          list: '=',
          countries: '=',
          geoModel: '=?',
          fullModel: '=?'
        },
        controller: ["$scope", function($scope) {
          $scope.allSelected = undefined;
          $scope.someSelected = undefined;
          $scope.noneSelected = true;
          $scope.selectAllChecked = undefined;
          $scope.countriesFullModel = {};

          $scope.init = function() {
            var self = this;
            $scope.updateSelectedCounts();
            $scope.initPermissionForest();
            if ($scope.ngModel.$pristine) {
              angularUtils.ngModelSkipDirty($scope.ngModel, $scope.updateForm)();
            } else {
              $scope.updateForm();
            }
            $scope.ngModel.$render = function() {
              var newSet = _.castArray(self.ngModel.$viewValue);
              _.each(self.list, function(permission) {
                permission.on = _.includes(newSet, permission.id);
              });
            };
            $scope.initialized = true;
          };

          var setIndeterminateGroup = function(parent) {
            // set indeterminate when all the children are not all 'off' or all 'on'
            parent.indeterminate = ( (parent.viewableChildrenCount > parent.viewableOnChildrenCount) || (parent.viewableIndeterminateChildrenCount > 0) ) && (parent.viewableOnChildrenCount > 0);
          };

          var initIndeterminateGroup = function(parent) {
            if (parent.initGroup) return;
            parent.viewableChildrenCount = 0;
            parent.viewableOnChildrenCount = 0;
            parent.viewableIndeterminateChildrenCount = 0;
            parent.indeterminate = false;
            parent.initGroup = true;
          };

          var initIndeterminateGroupStep = function(parent, child) {
            initIndeterminateGroup(parent);
            parent.viewableChildrenCount = parent.viewableChildrenCount + 1;
            parent.viewableOnChildrenCount = parent.viewableOnChildrenCount + !!child.on;
            parent.viewableIndeterminateChildrenCount = parent.viewableIndeterminateChildrenCount + !!child.indeterminate;
            setIndeterminateGroup(parent);
          };

          $scope.initPermissionForest = function() {
            if ($scope.list) {
              $scope.forest = _.clone($scope.list);
              var _forest = _($scope.forest);
              var children = _forest.filter(function(o) {return _.has(o,"parent")});
              children.forEach(function(child) {
                var parent = _forest.find({id: child.parent.id});
                if (child.display) {
                  initIndeterminateGroupStep(parent, child);
                }
                if (!_.isArray(parent.children)) {
                  parent.children = [];
                }
                parent.children.push(child);
                _.remove($scope.forest, child);
              });
              $scope.forest = _.groupBy($scope.forest, "group");
              $scope.initForestExternals();
            }
          };

          $scope.initForestExternals = function() {
            $scope.forest['applications.external'] = _.remove($scope.forest['applications'], {name: 'show blocked applications in UI'});
          };

          $scope.updateModel = function() {
            $scope.ngModel.$setViewValue(_($scope.list).filter(function(o){ return o.on && !o.disabled; }).map('id').value());
            $scope.fullModel = _.reduce( $scope.list, function(h, o) {
              if (o.disabled) return h;
              var key;
              if (o.indeterminate) {
                key = "indeterminate";
              } else if (o.on) {
                key = "selected";
              } else {
                key = "unselected";
              }
              h.permissions[key] = _.concat(h.permissions[key] || [], o.id);
              return h} , {permissions: {}});
            $scope.fullModel["countries"] = $scope.countriesFullModel;
          };

          $scope.allMarked = function() {
            if (!_.isObject($scope.list)) {
              return NONE_SELECTED;
            }
            var displayAmount = _($scope.list).filter(function(p) { return p.display; }).size();
            var markedOn = _($scope.list).filter(function(p) { return p.display; }).reduce(function(prev, curr) { return prev + curr.on - !!curr.indeterminate; }, 0);
            var markedIndeterminate = _($scope.list).filter(function(p) { return p.display; }).reduce(function(prev, curr) { return prev + !!curr.indeterminate; }, 0);
            if (markedOn == 0 && markedIndeterminate == 0) {
              return NONE_SELECTED;
            } else if (markedOn == displayAmount) {
              return ALL_SELECTED;
            } else {
              return SOME_SELECTED;
            }
          };

          $scope.updateForm = function(params) {
            $scope.updateSelectedCounts(params);
            $scope.updateModel();
            $scope.validate();
          };

          $scope.updateSelectedCounts = function(params) {
            if (params) {
              $scope.allSelected  = params.allSelected;
              $scope.someSelected = params.someSelected;
              $scope.noneSelected = params.noneSelected;
              $scope.selectAllChecked = params.allSelected;
              $scope.selectAllIndeterminated = false;
            } else {
              var allMarked = $scope.allMarked();
              $scope.allSelected  = (allMarked === ALL_SELECTED);
              $scope.someSelected = (allMarked === SOME_SELECTED);
              $scope.noneSelected = (allMarked === NONE_SELECTED);
              $scope.selectAllChecked = (allMarked === ALL_SELECTED || allMarked === SOME_SELECTED);
              $scope.selectAllIndeterminated = (allMarked === SOME_SELECTED);
            }
          };
          
          $scope.checkHasChannelPermissions = function(permissions){
            let relevantPermission = permissions.filter((permission) => _.includes(["discovery", "dashboard", "insights", "alerts"], permission.name));
            let relevantPermissionOn = relevantPermission.filter((permission) => permission.on);
            if (_.isEmpty(relevantPermissionOn)) return true;
            var data = permissions.filter(function(p){
              return p.group === 'data' && p.name.indexOf("channel") !== -1 
                                        && p.name.indexOf("audience") == -1
            });
            return _.some(data,'on');
          };

          $scope.checkVideoChannelPermissions = function(permissions){
            var data = permissions.filter(function(p){
              return p.group === 'data' && p.name.indexOf("channel") !== -1 
                                        && p.name.indexOf("audience") == -1
                                        && p.name !== "video channel"
            });
            var trend_insight = permissions.filter(function(p){
                return p.name === "trending interests" || p.name === "channel insights";
            });
            var ti = _.some(trend_insight,'on');
            var video = _.filter(permissions,{name: "video channel"});
            var video_result = _.some(video,'on');
            var result = _.some(data,'on');
            
            return !(video_result === true && result === false && ti === true);
          };

          $scope.checkAudiencePermissions = function(permissions){
            let channel_perms = permissions.filter(function(p){
              return p.name === "audience au telco channel" 
                      || p.name === "audience linkedin channel"
                      || p.name === "audience tv channel"
                      || p.name === "audience sg telco channel"
                      || p.name === "audience smart tv inscape channel"
                      || p.name === "web channel"
                      && p.group === "data"
            });

            let has_channel_perms  = _.some(channel_perms,'on');
            let audience = _.find(permissions,{name: "audience", group: "applications"});
            let has_audience = audience && audience.on;

            return !has_audience || has_channel_perms;
          };

          $scope.checkDashboardChannelPermissions = function() {
            return !$scope.isPermissionSelected("dashboard") ||
                   $scope.isPermissionSelected("web channel");
          };

          $scope.checkAlertsChannelsPermissions = function(permissions) {
            if(!$scope.isPermissionSelected("alerts")) return true;
            let channel_perms = permissions.filter(
              (p) => p.name === "web channel"|| p.name === "video channel");
            return _.some(channel_perms,'on');
          };

          $scope.checkLandscapeChannelPermissions = function(permissions) {
            if(!$scope.isPermissionSelected("landscape")) return true;
            let channel_perms = permissions.filter(
              (p) => (p.name === "web channel" || p.name === "sg telco channel" || p.name === "facebook"));
            return _.some(channel_perms,'on');
          };

          $scope.checkGeosChannelPermissions = function(permissions) {
            if(!$scope.isPermissionSelected("geos")) return true;
            let channelPermission = permissions.filter(
              (p) => p.name === "web channel" || p.name === "sg telco channel");
            return _.some(channelPermission,'on');
          };

          $scope.checkTimingChannelPermissions = function(permissions) {
            if(!$scope.isPermissionSelected("timing")) return true;
            let channel_perms = permissions.filter(
              (p) => p.name === "web channel" || p.name === "sg telco channel");
            return _.some(channel_perms,'on');
          };

          $scope.checkSentimentPermissions = function(permissions) {
            if(!$scope.isPermissionSelected("sentiment")) return true;
            const channel_perms = permissions.filter(
              (p) => (p.name === "facebook channel" || p.name === "sg telco channel"));
            const SG_CODE = "702";
            const includes_sg = ($scope.geoModel || []).includes(SG_CODE);
            return includes_sg || _.some(channel_perms,'on');
          };

          $scope.checkCustomSourcesChannelPermissions = function () {
            return !$scope.isPermissionSelected("custom sources") ||
                   $scope.isPermissionSelected("facebook channel") ||
                   $scope.isPermissionSelected("video channel");
          };

          $scope.validateSentimentPermissions = function() {
            const sentiment_valid = $scope.checkSentimentPermissions($scope.list);
            $scope.$formCtrl.$setValidity("sentiment", sentiment_valid);
          };

          $scope.togglePermission = function(permission, value) {
            const has_channel_perms = $scope.checkHasChannelPermissions($scope.list);
            $scope.$formCtrl.$setValidity("channels", has_channel_perms);
            const audience_valid = $scope.checkAudiencePermissions($scope.list);
            $scope.$formCtrl.$setValidity("audience", audience_valid);
            const video_valid = $scope.checkVideoChannelPermissions($scope.list);
            $scope.$formCtrl.$setValidity("video", video_valid);
            const dashboard_valid = $scope.checkDashboardChannelPermissions();
            $scope.$formCtrl.$setValidity("dashboard", dashboard_valid);
            const alerts_valid = $scope.checkAlertsChannelsPermissions($scope.list);
            $scope.$formCtrl.$setValidity("alerts", alerts_valid);
            const landscape_valid = $scope.checkLandscapeChannelPermissions($scope.list);
            $scope.$formCtrl.$setValidity("landscape", landscape_valid);
            const timing_valid = $scope.checkTimingChannelPermissions($scope.list);
            $scope.$formCtrl.$setValidity("timing", timing_valid);
            const custom_sources_valid = $scope.checkCustomSourcesChannelPermissions();
            $scope.$formCtrl.$setValidity("custom_sources", custom_sources_valid);
            $scope.$formCtrl.$setValidity("geos_valid", $scope.checkGeosChannelPermissions($scope.list));
            $scope.validateSentimentPermissions();
            if (_.isString(permission)) {
              permission = _.find($scope.list, {name: permission});
            }
            if (value === undefined) {
              value = permission.on;
            } else {
              permission.on = value;
            }
            if (permission.children) {
              permission.initGroup = false; // reset indeterminate value and recalculate it:
              _(permission.children).filter(function(p) { return p.display; }).each(function(child) {
                child.on = value;
                child.indeterminate = false;
                initIndeterminateGroupStep(permission, child);
              });
              permission.on = !(permission.viewableOnChildrenCount === 0)
            }
            $scope.updateForm();
          };

          $scope.$on('togglePermission', function(event, data) {
            $scope.togglePermission(data.permission, data.value);
          });

          $scope.toggleSubPermission = function(permission) {
            var parent = _.find($scope.list, {id: permission.parent.id});
            parent.viewableOnChildrenCount = (parent.viewableOnChildrenCount || 0) + (permission.on ? 1 : -1);
            parent.viewableIndeterminateChildrenCount = Math.max((parent.viewableIndeterminateChildrenCount || 0) - !!permission.indeterminate, 0);
            permission.indeterminate = false;
            parent.on = !(parent.viewableOnChildrenCount === 0);
            setIndeterminateGroup(parent);
            var video = $scope.checkVideoChannelPermissions($scope.list);
            $scope.$formCtrl.$setValidity("video", video);
            var audience = $scope.checkAudiencePermissions($scope.list);
            $scope.$formCtrl.$setValidity("audience", audience);
            $scope.validateSentimentPermissions();
            const custom_sources_valid = $scope.checkCustomSourcesChannelPermissions();
            $scope.$formCtrl.$setValidity("geos_valid", $scope.checkGeosChannelPermissions($scope.list));
            $scope.$formCtrl.$setValidity("custom_sources", custom_sources_valid);
            $scope.updateForm();
          };

          $scope.selectAllPermissions = function(value) {
            _($scope.list).filter(function(p) { return p.display; }).each(function(p) { p.on = !!value; p.indeterminate = false; } );
            _($scope.list).filter(function(p) { return p.display; }).filter(function(p) { return p.children; }).each(function(p) {
              p.viewableOnChildrenCount = value ? p.viewableChildrenCount : 0;
              p.viewableIndeterminateChildrenCount = 0;
            });
            var params = {allSelected: (value === true), noneSelected: (value === false), someSelected: false};
            $scope.updateForm(params);
          };

          $scope.$on('selectAllPermissions', function(event, value) {
            $scope.selectAllPermissions(value);
          });

          $scope.hasPermission = function(permissionName) {
            return _.find($scope.list, {name: permissionName});
          };

          $scope.hasVisibilePermission = function(permissionName) {
            return _.find($scope.list, {name: permissionName, display: true});
          };

          $scope.filterToDisplay = function(permission) {
            return permission.display;
          };

          $scope.isPermissionSelected = function(permissionName) {
            var p = $scope.hasPermission(permissionName);
            return p && p.on;
          };
        }],

        link: function ($scope, $element, $attr, $ctrls) {
          $scope.$formCtrl = $ctrls[0];
          $scope.ngModel = $ctrls[1];
          $scope.validate = function() {
            $scope.$formCtrl.$setValidity("permissions__none_selected", !$scope.noneSelected);
            $scope.validateSpecialPermissions();
            $scope.validateSubPermissions();
            $scope.validateGeos();
            $scope.permissionsErrors = _.keys($scope.$formCtrl.$error);
          };


          $scope.validateGeos = function() {
            if ($scope.geoModel != undefined) {
              $scope.$formCtrl.$setValidity("geos", $scope.countriesFullModel.status && $scope.countriesFullModel.status != Symbol.for("None Selected"));
            }
          };


          $scope.validateSubPermissions = function() {
            _($scope.list).filter('children').each(function(permission) {
              if (permission.on) {
                $scope.validateSubPermission(permission);
              } else {
                $scope.$formCtrl.$setValidity("permissions__sub_application__" + permission.name, true);
              }
            });
          };

          $scope.validateSubPermission = function(permission) {
            var allSiblings = permission.children;
            var atLeastOneSiblingOn = _.reduce(allSiblings, function(r, s) { return (r || s.on) }, false);
            $scope.$formCtrl.$setValidity("permissions__sub_application__" + permission.name, atLeastOneSiblingOn);
          };

          $scope.validateSpecialPermissions = function() {
            let scoreBreakdownValidity = true,
                normalizeValidity = true,
                insightsGeoAuMapValidity = true;

            if ($scope.hasVisibilePermission('score breakdown')) {
              scoreBreakdownValidity = !$scope.isPermissionSelected('score breakdown') || $scope.isPermissionSelected('discovery');
              $scope.$formCtrl.$setValidity("permissions__score_breakdown", scoreBreakdownValidity);
            }

            if ($scope.hasVisibilePermission('normalize')) {
              normalizeValidity = !$scope.isPermissionSelected('normalize') || $scope.isPermissionSelected('insights');
              $scope.$formCtrl.$setValidity("permissions__normalize", normalizeValidity);
            }

            if ($scope.hasVisibilePermission('insights geo au map')) {
              const AU_CODE = '036';
              insightsGeoAuMapValidity = !$scope.isPermissionSelected('insights geo au map') || ($scope.geoModel || []).includes(AU_CODE);
              $scope.$formCtrl.$setValidity("permissions__insights_geo_au_map", insightsGeoAuMapValidity);
            }
          };


          $scope.$watch('list', function(newval, oldval, $scope) {
            if (newval === undefined) return;
            $scope.init();
          });

          $scope.$watch('geoModel', function(newval, oldval, $scope) {
            _.set($scope.fullModel, "countries", $scope.countriesFullModel);
            $scope.validateGeos();
            $scope.validateSentimentPermissions();
            $scope.validateSpecialPermissions();
          });
        }
    }
}]);
