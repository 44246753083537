"use strict";
import config from 'infra/config';
import c from 'infra/utils/common';
import * as MixpanelDiscovery from '../react/infra/mixpanel/MixpanelDiscovery';

const exportHelper = require('infra/utils/export');

module.exports = angular.module(__filename, [])
    .service('discoveryExportService', ['Discovery', 'Excel', '$rootScope', '$q', "$state", "context", "$parse", "util", 'topicsTree', 'geoService', 'abiPermissions',
        function (Discovery, Excel, $rootScope, $q, $state, context, $parse, util, topicsTree, geoService, permissions) {
            return {
                getDiscoveryExcelWorkbook: function (config) {
                    var workbook = Excel.builder.createWorkbook();
                    var t = Excel.formater(workbook);
                    var def = _.or;
                    var _article0 = $parse('articles[0].url');
                    var _article1 = $parse('articles[1].url');
                    var hasLifestylePermission = permissions.hasPermission('discovery audience overlay');
                    var hasSgTelcoAudiencePermission = permissions.hasPermission('sg telco audience filters');
                    var hasFirstPartyAudiencePermission = permissions.hasPermission('first party segments');
                    var lifestyle = config.config.lifestyle ? config.config.lifestyle.label : 'None';
                    var associationMap = Discovery.getConceptsList(config).then(function (concepts) {
                        var workSheet = workbook.createWorksheet({name: 'Trending Interests'});
                        var titleLine = [
                            t('Phrase', 'bold'),
                            t('Seed', 'bold'),
                            t('Velocity', 'bold'),
                            t('Volume index', 'bold'),
                            t('Specificity Score', 'bold'),
                            t('Example 1', 'bold'),
                            t('Example 2', 'bold')
                        ];

                    var should_add_lifestyle = hasLifestylePermission && lifestyle != 'None'

                        var xls_values = _.map(concepts, 'volume');
                        var max_val = _.max(xls_values);
                        var grid = _.map(concepts, function (c) {
                            var display = c.term && c.term.display ? c.term.display : c.seed;
                            var tableValues = [
                                c.word,
                                display,
                                t(c.score / 100, 'percent'),
                                t((c.volume / max_val) * 100, 'numeric'),
                                t(c.kl || 0, 'numeric'),
                                _article0(c) || '',
                                _article1(c) || ''
                            ];
                            if (should_add_lifestyle) {
                              tableValues.splice(5, 0, t(c.audience, 'numeric'));
                            }
                            return tableValues;
                        });

                        var columnsSize = [{width: 30}, {width: 18}, {width: 9}, {width: 13}, {width: 15}, {width: 100}, {width: 100}];
                        if (should_add_lifestyle) {
                          titleLine.splice(5, 0, t('Audience Skew', 'bold'));
                          columnsSize.splice(5, 0, {width: 14});
                        }
                        grid.unshift(titleLine);
                        workSheet.setData(grid);
                        workSheet.setColumns(columnsSize);
                        MixpanelDiscovery.trackExport();
                        return workSheet;
                    });

                    return $q.all([associationMap]).then(function (sheets) {
                        var summarySheet = workbook.createWorksheet({name: 'summary'});
                        var data = Discovery.lastUsedConfig;
                        var generatedDate = moment().format('YYYY-MM-DD HH:mm');
                        var topics = topicsTree.isAllTopics(data.topics) ? null : data.topics;

                        var grid = [
                            [t('Generated At:', 'bold'), generatedDate],
                            [t('Content From:', 'bold'), c.getTimeframeLabel(data.timeframe, 'YYYY-MM-DD')],
                            [t('Program:', 'bold'), data.program.name],
                            [t('Seeds:', 'bold'), t(def(c.rejectBooleanLogics(data.terms), 'None'), 'display')],
                            [t('Refine interests:', 'bold'), t(def(c.selectBooleanLogics(data.terms), 'None'), 'display')],
                            [t('Selected Topics:', 'bold'), t(def(topics, 'All'), 'name')]
                        ];

                        if (data.bubblesChannels.value == 'articles' ||
                            (['sg_telco', 'sg_bidstream', 'bidstream'].includes(data.bubblesChannels.value) && hasSgTelcoAudiencePermission)) {
                            let audience = t(def(data.audience, 'All'), 'summary');
                            audience['value'] = c.titleize(audience['value']);
                            grid.push([t('Selected Audience:', 'bold'), audience]);
                        }

                        if (data.bubblesChannels.value == 'articles' && hasFirstPartyAudiencePermission && !_.isEmpty(context.current.firstPartyAudience)) {
                            grid.push([t('1st Party Segments: ', 'bold'), t(_.map(context.current.firstPartyAudience, 'summary').join(', '), 'summary')]);
                        }

                        if (hasLifestylePermission) {
                            lifestyle = c.titleize(lifestyle);
                            grid.push([t('Audience Overlay:', 'bold'), lifestyle]);
                        }

                        grid.push([t('Geo:', 'bold'), t(def(data.geos, 'All'), 'label')]);
                        let treeGeo = geoService.geosForChannel(data.geos, $state, context)[0];
                        var indexedSubGeosTreeHelper = geoService.indexedSubGeosTreeHelper($rootScope.subGeosTree, (treeGeo || {}).id);
                        let exportGeos = context.current.discoveryAllSubGeosSelected ? 'All' : def(indexedSubGeosTreeHelper
                            .contextSubGeosHelper(indexedSubGeosTreeHelper.reverseSelectableSubGeosToTopChecked(data.sub_geos))
                            .getOnlyTopCheckedSubGeos(), 'All');
                        grid.push([t('Sub Geo:', 'bold'), t(exportGeos, 'name')]);

                        var lan = context.current._language_mold.getLanguage($state, context).value;
                        grid.push([t('Language:', 'bold'), t(def(lan, 'All'), 'label')]);


                        var bls = util.getTerms(c.selectBooleanLogics(data.terms), true);
                        if (!_.isEmpty(bls)) {
                            var boolSummary = exportHelper.getBooleansLoginSummary(bls, {});
                            grid.push([]); // new line
                            grid.push([t('Refine interests:', 'bold')].concat(boolSummary.title));
                            grid = grid.concat(boolSummary.data);
                        }

                        summarySheet.setColumns([{width: 18}, {width: 18}]);
                        summarySheet.setData(grid);
                        workbook.addWorksheet(summarySheet);

                        sheets.forEach(function (sheet) {
                            workbook.addWorksheet(sheet);
                        });
                        workbook.fileName = 'Bubbles ' + generatedDate + '.xlsx';
                        return workbook;
                    });
                }
            }
        }
    ]
);
