import { getFirstPartyDataByProgram } from '../../react/services/AudienceInsightsService';
import { ETHNICITY_VALUES } from '../../data/audience-segment-builder-helper';

module.exports = require("angular").module(__filename, [])
    .directive("audienceFilter", ["abiPermissions", "filtersPartition", "geoService", "notificator",
        function(abiPermissions, filtersPartition, geoService, notificator) {
        return {
            restrict: 'E',
            scope: {
                context: '=',
                debounceGroup: '<',
                audienceSummaryOrder: '<',
                filter: '<',
                channels: '<',
                onSelect: '&'
            },
            template: require('./audience-filter.html'),
            link: function ($scope, $element, $attrs, $ctrl) {
                const USGeoRecord = geoService.allGeos.find(item => item.cc === 'US');

                updateGeoByAudience();

                $scope.refreshAudienceSummary = 0;
                // temp fix - remove nationalities
                _.remove($scope.context.current.sgTelcoAudience, (a) => a.value.includes("nationality-"));

                var refreshAudienceSummary = function () {
                    $scope.refreshAudienceSummary++;
                };

                function updateGeoByAudience () {
                    if(hasEthnicity() && !isUSSelected()) {
                        $scope.context.current.geo = [...$scope.context.current.geo, USGeoRecord];
                        $scope.context.current._geo_mold._value = $scope.context.current.geo;
                        notificator.notify({body: 'United States geo has been selected as ethnicity is only supported for United States'});
                    }
                }

                function isUSSelected() {
                    return Boolean($scope.context.current.geo.find(item => item.cc === 'US'));
                }

                function hasEthnicity() {
                    return _.find($scope.context.current.audience, ({label}) => _.findIndex(ETHNICITY_VALUES, {label}) > -1);
                }

                $scope.determineMold = function () {
                    if($scope.isSg()){
                        $scope.mold = $scope.context.current._sgTelcoAudience_mold;
                        if(!abiPermissions.hasPermission("sg telco ethnicity")){
                            _.remove($scope.mold._value, (eth) => _.find(filtersPartition.sgEthnicity, {value: eth.value}));
                        }
                        $scope.showFirstPartyAudienceForArticles = false;
                    } else {
                        $scope.mold = $scope.context.current._audience_mold;
                        $scope.showFirstPartyAudienceForArticles = abiPermissions.hasPermission("first party segments");
                    }
                };

                $scope.hideOrShowEthnicity = function () {
                    const hasOnlyRelevantChannels = _.isEmpty(_.difference($scope.channels, ['articles', 'videos']));
                    $scope.showEthnicityForArticles = (!$scope.channels || hasOnlyRelevantChannels) && $scope.isGeoUsSelected() && abiPermissions.hasPermission("geos.US");
                };

                $scope.showDisabledEthnicityMessage = function() {
                    return !this.showEthnicityForArticles && !$scope.isSg() && abiPermissions.hasPermission("geos.US");
                }

                $scope.refreshAudienceFilterDisplay = function () {
                    $scope.channels = _.castArray($scope.channels);
                    $scope.determineMold();
                    $scope.refreshFirstPartyAudience();
                    refreshAudienceSummary();
                    $scope.hideOrShowEthnicity();
                    $scope.showChildren = $scope.showIncome = _.isEmpty(_.difference($scope.channels, ['articles', 'videos']));
                    $scope.showSgUserOriginData = $scope.isSg();
                    $scope.showSgEthnicity = abiPermissions.hasPermission("sg telco ethnicity");
                    $scope.context.current._geo_mold._value = [...$scope.context.current._geo_mold._value];
                };

                $scope.refreshFirstPartyAudience = function() {
                    getFirstPartyDataByProgram($scope.context.program).then(function(res) {
                        $scope.userFirstPartyAudiences = _.map(res, function (segment) {
                            segment.label = segment.summary = segment.name;
                            segment.value = segment.segment_value;
                            return segment;
                        });
                        $scope.noFirstPartySegments = _.isEmpty($scope.userFirstPartyAudiences);
                        var nonexisting_context_segments =
                            _.map(_.filter($scope.context.current.firstPartyAudience,
                                          (segment) => _.every($scope.userFirstPartyAudiences, (s) => s.segment_value != segment.segment_value)), 'segment_value');
                        if(!_.isEmpty(nonexisting_context_segments)){
                            $scope.context.current.firstPartyAudience =
                              _.reject($scope.context.current.firstPartyAudience,
                                      (s) => _.includes(nonexisting_context_segments, s.segment_value));
                        }
                    });
                };

                $scope.isGeoUsSelected = () => $scope.context.current.geo.length === 0 || ($scope.context.current.geo.length == 1 && $scope.context.current.geo[0].cc === "US");

                $scope.onGeoChanged = () => {
                    if(hasEthnicity() && ($scope.context.current.geo.length > 1 || !isUSSelected())) {
                        $scope.context.current.audience = _.filter($scope.context.current.audience, ({label}) => _.findIndex(ETHNICITY_VALUES, {label}) === -1);
                        notificator.notify({body: 'Ethnicity selection was removed as ethnicity is only supported for United States'});
                    }
                    $scope.hideOrShowEthnicity();
                }

                $scope.getSelectedForSummary = function () {
                    let selected = $scope.mold._value;
                    if (!$scope.isSg() && abiPermissions.hasPermission("first party segments")) {
                        selected = _.concat(selected, $scope.context.current.firstPartyAudience);
                    }
                    return selected;
                };

                $scope.isSgTelco = () => _.isEqual($scope.channels, ['sg_telco']);
                $scope.isSgBistream = () => _.isEqual($scope.channels, ['sg_bidstream']);
                $scope.isSg = () => $scope.isSgTelco() || $scope.isSgBistream();

                $scope.filterChanged = () => {
                    updateGeoByAudience();
                    $scope.onSelect();
                };

                $scope.refreshAudienceFilterDisplay();

                $scope.$watch('context.current.geo', $scope.onGeoChanged);
                $scope.$watch('channels', $scope.refreshAudienceFilterDisplay);
                $scope.$watch('context.program', $scope.refreshAudienceFilterDisplay, true);
                $scope.$watch('context.current.audience', refreshAudienceSummary);
                $scope.$watch('context.current.sgTelcoAudience', refreshAudienceSummary);
                $scope.$watch('context.current.firstPartyAudience',refreshAudienceSummary);
                $scope.$watch('userFirstPartyAudiences',$scope.determineMold);
            }
        };
    }]);
