import React from 'react';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import PropTypes from 'prop-types';
import './DropdownWithHeader.scss';
import { convertToArray } from '../../../../utils/ArrayUtils';
import { isEmpty } from 'lodash';

const DropdownWithHeader = (props) => {
  const getSelectedValuesAsArray = () => {
    if (!props.isMulti) return convertToArray(props.selectedValue);
    return props.selectedValues;
  };
  const onReset = () => {
    const selectedValues = getSelectedValuesAsArray();
    if (isEmpty(selectedValues)) return;
    props.onSelect([]);
  };

  return (
    <div className="dropdown-with-header-component">
      <div className="dropdown-header">
        <div className="header-title-left">{props.headerTitle}</div>
        {props.headerResetText && (
          <div className="header-title-right" onClick={onReset}>
            {props.headerResetText}
          </div>
        )}
      </div>
      <BaseDropdown {...props} />
    </div>
  );
};

DropdownWithHeader.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  promise: PropTypes.object,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  shouldCloseOnClickOutside: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
  selectAllOptionsText: PropTypes.string,
  showClearAllOptions: PropTypes.bool,
  clearAllOptionsText: PropTypes.string,
  summaryTextAllSelected: PropTypes.string,
  summaryTextBuilder: PropTypes.func,
  onSelect: PropTypes.func,
  virtualizeThreshold: PropTypes.number,
  isVirtualizeDisabled: PropTypes.bool,
  menuMaxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuPosition: PropTypes.string,
  summaryWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clickTwiceToUnselect: PropTypes.bool,
  maxNumberOfSelectedOptions: PropTypes.number,
  reachMaxSelectedOptionsMsg: PropTypes.string,
  tooltip: PropTypes.string,
  customSearch: PropTypes.func,
  headerTitle: PropTypes.string.isRequired,
  headerResetText: PropTypes.string,
};

DropdownWithHeader.defaultProps = {
  className: 'dropdown-with-header-component',
  shouldCloseOnClickOutside: true,
  menuMaxHeight: 200,
  summaryIconName: 'chevron',
};

export default DropdownWithHeader;
