import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';

const BaseWidget = require("../base_widget"),
    c = require("infra/utils/common"),
    TopChart = require("common/charts/top-chart"),
    widgetConfigurations = require('./insights-geo-widget-configurations');

InsightsGeoController.$inject = ['$scope', '$rootScope', '$window', '$timeout', 'consumptionGeoService', 'util',
                                 'examplesDataModel', 'insightsExportService', 'geoService', 'notificator',
                                 'abiPermissions', 'TIMES', '$location', '$state', 'context'];

//Define variable outside the controller because the controller initialize every time we change state or clear the input bar

function InsightsGeoController($scope, $rootScope, $window, $timeout, consumptionGeoService, util, examplesDataModel,
                               insightsExportService, geoService, notificator, abiPermissions, TIMES, $location, $state,
                               context) {
  var self = this;
  $scope.examplesData = examplesDataModel;
  insightsExportService.setTitle('Geo');
  const topBarElement = angular.element('.top-container');
  $scope.$watch(() => topBarElement.width(), () => $scope.narrowMode = topBarElement.innerWidth() < 900);
  this.$scope = $scope;
  this.util = util;
  this.TIMES = TIMES;
  this.$location = $location;
  this.notificator = notificator;
  this.insightsExportService = insightsExportService;
  this.geoService = geoService;
  this.insightsExportService.addSummaryFields({label: "Geo type", value_f: () => $scope.scale.label});
  this.insightsExportService.addSummaryFields({label: "Metric", value_f: () => $scope.formula.label});

  $scope.cachedGeo = null;
  $scope.heatMapVisible = false;
  $scope.measureOptions = widgetConfigurations.measureOptions;
  $scope.measure = $scope.measureOptions[0];

  $scope.formulaOptions = _.clone(widgetConfigurations.formulaOptions);
  $scope.formula = $scope.formulaOptions[0];

  $scope.visualizationModesOptions = widgetConfigurations.visualizationModesOptions;
  $scope.visualizationMode = $scope.visualizationModesOptions[0];
  $scope.allRegionsSelected = false;
  $scope.selectedZones = [];

  const zoneClickListener = $scope.$on('zoneClicked', ($event, selectedZones) => {
    const prevSelectedZonesLength = $scope.selectedZones.length;
    if (_.isEqual($scope.selectedZones, selectedZones)) return;

    $scope.selectedZones = _.cloneDeep(selectedZones);
    if ($scope.selectedZones.length < 2 && prevSelectedZonesLength < 2) return this.getExamples($scope.selectedZones, $scope.terms, 'name');

    this._doUpdate();
  });

  this.updateScaleOptions = () => {
    const geoForConfig = (geoService.geosForChannel($scope.geo, $state, context) || [])[0] || {};
    const geoCode = geoForConfig.id;
    if (!geoCode) return;
    const scalingOptionByGeo = widgetConfigurations.scalingOptionsByGeo[geoCode];
    if (_.isEqual(scalingOptionByGeo, $scope.scalingOptions)) return;
    $scope.scalingOptions = _.filter(scalingOptionByGeo, scale => abiPermissions.hasPermission(scale.permission));
    $scope.scale = $scope.scalingOptions[0];
  };

  this.updateScaleOptions();

  this.getSupportedGeoKeysByChannel= (channel) => {
    const supportedGeos = widgetConfigurations.supportedChannels[channel];
    return _.map(_.filter(supportedGeos, geo => abiPermissions.hasPermission(geo.permission)), 'id');
  };

  const channelChangedListener = $scope.$watch('insightsChannel', (newChannel, oldChannel) => {
    if (!$state.is('insights.geo')) return;
    const supportedGeoKeys = this.getSupportedGeoKeysByChannel((newChannel || {}).value);
    if (!supportedGeoKeys.includes(($scope.geo[0] || {}).id)) {
      $scope.cachedGeo = $scope.geo = [_.find($scope.$root.Geos.geos, (geo) => supportedGeoKeys.includes(geo.id))];
      this.updateScaleOptions();
      if (_.isEmpty($scope.subGeos) && _.get($scope, '$parent.dataTrees.subGeos')) {
        $scope.$parent.dataTrees.subGeos.clickAll('checked');
      }
    }
    $scope.selectedZones = [];
    if ($scope.scale) $scope.$emit('hideSubGeos', $scope.scale.hideSubGeos);
  });

  $scope.$watch('sub_geos', (newSubGeos, oldSubGeos) => {
    if (!$state.is('insights.geo')) return;
    geoService.getSubGeosPromise().then((subGeosTree) => {
      const supportedGeoKeys = this.getSupportedGeoKeysByChannel(($scope.insightsChannel || {}).value);
      let treeGeo = geoService.geosForChannel($scope.$root.Geos.geos, $state, context)[0];
      const geoForSubGeo = geoService.indexedSubGeosTreeHelper(subGeosTree, (treeGeo || {}).id)
                                     .contextSubGeosHelper(newSubGeos).getGeoFromContextSubGeos()
        || _.first($scope.cachedGeo)
        || _.find($scope.$root.Geos.geos, (geo) => supportedGeoKeys.includes(geo.id));

      if (!$scope.cachedGeo && _.isEmpty(newSubGeos) && _.get($scope, '$parent.dataTrees.subGeos')) {
        $scope.$parent.dataTrees.subGeos.clickAll('checked');
      }

      if (geoForSubGeo && !_.isEqual($scope.geo, [geoForSubGeo])) $scope.geo = [geoForSubGeo];
      $scope.cachedGeo = $scope.geo;
      this.updateScaleOptions();
    });
  });

  $scope.$on('$destroy', () => {
    zoneClickListener();
    channelChangedListener();
    $scope.examplesData.title = null;
  });

  this.getParams = (terms, selectedZones) => {
    const parameters = Object.assign({}, {terms}, _.pick($scope, ['topics', 'geo', 'timeframe', 'channel', 'insightsChannel','sub_geos']));
    const params = this.util.buildInsightsParameters(parameters);
    params.scale = $scope.scale.value;
    params.measure = $scope.measure.value;
    params.formula = $scope.formula.value;
    params.sub_geos = geoService.createRequestSubGeoParam(parameters.sub_geos, $scope.insightsChannel.value);
    if (!_.isEmpty(selectedZones)) params.zoneIds = _.compact(selectedZones.map((zone) => (zone.id)));
    delete params.audience;

    return params;
  };

  $scope.mapChanged = (scale) => {
    MixpanelAudience.trackViewChange(scale, 'Geos', 'Insights');
    if (typeof scale === 'object') {
      $scope.scale = scale;
    } else {
      $scope.scale = _.find($scope.scalingOptions, ['value', scale]) || $scope.scalingOptions[0];
    }
    $scope.selectedZones = [];
    this._doUpdate();
  };

  $scope.formulaChanged = (formula) => {
    MixpanelAudience.trackViewChange(formula, 'Geos', 'Insights');
    $scope.formula = _.find($scope.formulaOptions, ['value', formula]) || $scope.formulaOptions[0];
    this._doUpdate();
  };

  $scope.visualizationModeChanged = (mode) => {
    $scope.visualizationMode = _.find($scope.visualizationModesOptions, ['value', mode]) || $scope.visualizationModesOptions[0];
    this.redrawElements();
  };

  this.getSelectedZonesContentDriversTitle = (selectedZones, data) => {
    let title = null;
    if (!_.isEmpty(selectedZones)) {
      const firstSelectedZone = _.first(selectedZones);
      const multiSelectedZone = firstSelectedZone && _.find(data, { id: firstSelectedZone.id });
      if (multiSelectedZone) title = multiSelectedZone.selectedZonesLabel;
    }
    return title;
  };

  this.performUpdate = (params) => {
    this.startLoader();
    insightsExportService.addReportFunction(getExportReport);
    const scaleText = (_.find($scope.scalingOptions, ['value', $scope.scale.value]) || {}).label;
    return consumptionGeoService.get(params, scaleText).then(results => {
      if (results == undefined) {
        if (error != "No Terms") {
          $scope.$emit('insightsError', "No Data");
        }
        throw "consumptionGeoService no results";
      }
      const data = {};
      $scope.results = results;
      _.each(results.mapData, mapData => {
        const x = mapData.mapData;
        _.each(x, d => data[d.key] = d);
      });
      $scope.data = data;
      $scope.examplesData.title = this.getSelectedZonesContentDriversTitle($scope.selectedZones, $scope.results.chartData);
      this.redrawElements();
      this.updateExamples(results.examples);
      this.setUpHeatMapOptions(results.mapData, params);
      this.stopLoader();
    }).catch(() => {
      this.stopLoader();
      $scope.results = {};
      $scope.data = {};
      this.redrawElements();
    });
  };

  this.redrawElements = () => {
    setTimeout(() => {
      if ($scope.visualizationMode.value == 'chart') {
          widgetConfigurations.topChartConfiguration[$scope.formula.value].topBarGroup.examples.showExamples = (data) => {
            let zoneLabelProperty, zonesData;
            if (data.label === data.selectedZonesLabel) {
              zoneLabelProperty  = 'label';
              zonesData = [data];
            } else {
              zoneLabelProperty = 'selectedZonesLabel';
              if (_.map($scope.selectedZones, 'id').includes(data.id)) zonesData = _.cloneDeep($scope.selectedZones);
              _.each(zonesData, zone => {
                zone.label = data.label;
                zone.selectedZonesLabel = data.selectedZonesLabel;
              });
            }
            this.getExamples(zonesData, $scope.terms, zoneLabelProperty);
          };
          this.chart = new TopChart('normal', null,
          '#geo-bar-chart', widgetConfigurations.topChartConfiguration[$scope.formula.value]);
          if($scope.results && $scope.results.chartData) {
            this.chart.draw($scope.results.chartData, 'normal', 'value', $scope.results.max);
          }
      } else if($scope.results) {
        const data = {};
        _.each($scope.results.mapData, mapData => _.each(mapData.mapData, d => data[d.key] = d));
        $scope.data = data;
      }
    })
  };

  this.getExamples = (data, terms, zoneNameField) => {
    const params = this.getParams(terms, $scope.selectedZones);

    if (data.length >= 1 && data[0][zoneNameField]) {
      params.zoneIds = data.length === 1 ? [ data[0].id ] : _.map(data, 'id');
      $scope.examplesData.title = _.capitalize(data[0][zoneNameField]);
    } else {
      $scope.examplesData.title = null;
    }

    $scope.$root.$broadcast('openContentDrivers', "content_drivers");
    $scope.examplesData.promise = consumptionGeoService.get(params);
    $scope.examplesData.promise.then(data => this.updateExamples(data.examples))
                               .catch(() => {});
  };

  this.updateExamples = examples => {
    $scope.examplesData.terms = $scope.terms.filter(function (t) {
      return ['mention', 'hashTag', 'post'].indexOf(t.type) === -1;
    });
    angular.copy($scope.examplesData.terms, $scope.examplesData.filters);
    var termClasses = {};
    _.each($scope.examplesData.terms, function(trend) {
      termClasses[trend.text] = termClasses[trend.id] = trend.class;
    });
    _.each($scope.examplesData.filters, function(trend) {
        trend.show = true;
    });
    $scope.examplesData.icon = examples.icon;
    $scope.examplesData.examples.consumption = examples;
    _.each($scope.examplesData.examples.consumption, function(ex, i) {
        ex.class = termClasses[ex.keyword];
        ex.withTermContent = true;
        ex.trend_label = ex.keyword;
    });
    $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption, function(example) {
        return termClasses[example.keyword]
    });

    angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
  };

  this.setUpHeatMapOptions = (mapData, params) => {
    const isSkew = $scope.formula.value == 'skew' && params.terms.length == 1;
    const options = _.map(mapData, data => {
      const {min, max} = data;
      const element = angular.element(`.${data.mapData[0].classColor}`)[0];
      if (_.isUndefined(element)) return null;

      const classColor = $window.getComputedStyle(element, null).fill;
      const color = classColor.replace(/[^\d,]/g, '').split(',');

      return {
        min: min === max ? 0 : min,
        max,
        minColor: 0.4,
        maxColor: 1.5,
        blocksColors: [color],
        midValue: isSkew ? 1 : null,
        title: data.mapData[0].term,
        labelFormat: (d) => {
          switch ($scope.formula.value) {
            case 'distribution':
              return (d * 100).toFixed(1) + "%";
            case 'consumption':
              return (d * 100).toFixed(2);
            default:
              return d.toFixed(2);
          }
        }
      }
    });
    $scope.heatMapOptions = _.omit(options, _.isUndefined);
    $scope.heatMapVisible = true;
  };

  this.onResize = ()  => {
    $timeout(() => {
      if(this.chart) {
        this.chart.draw($scope.results.chartData, 'normal', 'value', $scope.results.max);
      }
    }, 0);
  };

  this.validateTimeframe = () => {
    let timeframe = _.clone($scope.timeframe);
    if ($scope.insightsChannel.value == 'articles') {
      if(moment(timeframe[0], TIMES.FORMAT) < moment($scope.$root.minDate, TIMES.FORMAT)) {
        $scope.timeframe[0] = $scope.$root.minDate;
      }
      if (!_.isEqual($scope.timeframe, timeframe)){
        $rootScope.$broadcast('timeframe-update', $scope.timeframe, false);
      }
    }
  };

  this.setAllRegionsSelected = () => {
    $timeout(() => {
      if (_.has($scope.insightsChannel, 'value') && !$scope.$root.subGeosTree.loading && !_.isEmpty($scope.geo)) {
        const geoId = geoService.geosForChannel($scope.geo, $state, context)[0];
        $scope.allRegionsSelected = this.geoService.indexedSubGeosTreeHelper($scope.$root.subGeosTree, geoId)
            .contextSubGeosHelper($scope.sub_geos)
            .isAllStateSubGeosSelected(geoId);
      }
    }, 0);
  };

  this.validateGeo = () => {
    if (!_.isEmpty($scope.cachedGeo) && _.isEmpty($scope.geo)) {
      $scope.geo = $scope.cachedGeo;
    }
  };

  this.validateChannel = () => {
    if (!this.$scope.$root.context.current.insightsChannels.hasOwnProperty('value') ||
    !_.map($rootScope.geoInsightsChannelsFilter, 'value').includes(this.$scope.insightsChannel.value) ||
    _.keys(widgetConfigurations.supportedChannels).indexOf(this.$scope.insightsChannel.value) == -1) {
      this.$scope.$root.context.current.geoInsightsChannels = $scope.insightsChannel = $rootScope.geoInsightsChannelsFilter[0];
    }
  };

  this.setFormulaOptions = () => {
    $scope.formulaOptions = _.clone(widgetConfigurations.formulaOptions);
    _.each($scope.formulaOptions, (option) => {
      option.tooltip = _.isEmpty($scope.sub_geos) ? option.tooltipText.all || '' :
                                                    option.tooltipText.subGeos || option.tooltipText.all || '';
    });
  };

  const getExportReport = (format) => {
    const firstLine = [format('Zone', 'bold')];
    _.each($scope.terms, (t) => firstLine.push(format(t.display, 'bold')));
    const columns = [{width: 35}];
    const table = [firstLine];
      _.each($scope.results.chartData, ({label, values}) => {
        const arr = new Array($scope.terms.length + 1);
        arr[0] = label;

        _.each(values, ({id, value}) => {
          const index = _.findIndex($scope.terms, (t) => t.id == id);
          arr[index + 1] = value;
        });
        table.push(arr);
    });
    return {
        name: 'Geo',
        columns: columns,
        table: table
    };
  };

  insightsExportService.clear();
  insightsExportService.addReportFunction(getExportReport);
}

InsightsGeoController.prototype._doUpdate = function(values, changedVals) {
  const $scope = this.$scope;
  const $location = this.$location;

  this.validateChannel();
  this.validateGeo();
  this.validateTimeframe();
  this.setAllRegionsSelected();
  this.setFormulaOptions();

  if ($location.path() == "/insights/geo") { //Don't set timeframe vars if we're stepping out of widget
    let times = ["1M", "3M", "6M", "1Y"], min_date = this.TIMES.INSIGHTS_OFFSET;
    $scope.$root.$broadcast('timeframe-vars-update', times, min_date);
  }

  $scope.heatMapVisible = false;
  $scope.examplesData.visible = true;
  $scope.examplesData.alphabetized = false;
  $scope.examplesData.filter_type = 'trend';
  $scope.examplesData.title = null;
  c.validateNonPhrases($scope.terms, null, this.notificator);
  const params = this.getParams($scope.terms, $scope.selectedZones);
  const promise = this.performUpdate(params);
  this.insightsExportService.setParams(params);
  $scope.examplesData.promise = promise;
  return promise;
};

module.exports = angular.module(__filename, [
  require('data/insights/consumption-geo-service').name,
  require('common/map-component/countries/australia/australia-map-component').name,
  require('common/map-component/countries/usa/usa-map-component').name,
  require('common/map-component/countries/singapore/singapore-map-component').name

])
.directive("insightsGeoWidget", [function() {
    return BaseWidget({
        restrict: "E",
        template: require('./insights-geo-widget.html'),
        scope: {
            timeframe: "=",
            terms: "=",
            geo: "=",
            sub_geos: "=subGeos",
            topics: "=",
            insightsChannel: '=',
            cacheBaster: "="
        },
        controller: InsightsGeoController
    });
}]);
