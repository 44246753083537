import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';

var tmpl = require("./menu.html"),
    svgInline = require("common/svg-inline"),
    ModalService = require("angular-modal-service"),
    contactUs = require('common/modals/contact_us/contact_us.js'),
    amNavBar = require('common/am-nav-bar/am-nav-bar');

MenuController.$inject = ["$scope", '$state', 'abiPermissions'];

function MenuController($scope, $state, abiPermissions) {
  $scope.$state = $state;
  var getPermissions = _.property("data.permissions");

  $scope.hasPermission = abiPermissions.hasPermission;

  $scope.firstAutherizedState = function(states) {
    var self = this;
    return _.find(states, function(stateName) {
      var state = self.$state.get(stateName);
      var permissions = getPermissions(state);
      return _.isUndefined(permissions) || abiPermissions.hasPermission(permissions);
    });
  };

  $scope.activeTab = function(app) {
    var tabs = _.map($state.get(app).children, function(s) {return s.name; });
    return this.firstAutherizedState(tabs);
  };

  $scope.amountSubApps = function(app) {
    var self = this;
    var subApps = _(_.map(self.$state.get(app).children));
    var activeSubApps = subApps.map(function(s) { return getPermissions(self.$state.get(s)); })
                               .map(function(p) { return +abiPermissions.hasPermission(p); }).sum();
    return activeSubApps;
  };

  $scope.channelInsightsView = function () {
    var currentChannel = $scope.context.current?.gridChannels.value;
    if (currentChannel === 'articles') {
      return "discovery.grid.phrases.web";
    } else if (currentChannel === 'sg_telco') {
      return "discovery.grid.phrases.web";
    } else if (currentChannel === 'facebook') {
      return "discovery.grid.interests.facebook";
    } else {
      return "discovery.grid.phrases.social";
    }
  };

  $scope.fixDiscoveryTab = function(tab) {
    if (tab != "discovery.grid") return tab;
    return $scope.channelInsightsView();
  }

  $scope.insightsState = $scope.activeTab("insights");
  $scope.discoveryState = $scope.fixDiscoveryTab($scope.activeTab("discovery"));
  $scope.channelInsightsState = $scope.channelInsightsView();
  $scope.$watch('context.current.bubblesChannels.value', function(nv, ov) {
    if (nv === undefined) return;
    $scope.channelInsightsState = $scope.channelInsightsView();
  });
  $scope.discoveryChildren = $scope.amountSubApps("discovery");
  $scope.disableDiscoveryTip = ($scope.discoveryChildren != 1);
}

module.exports = angular.module(__filename, [
    svgInline.name,
    contactUs.name,
    amNavBar.name,
    'ui.router'
]).directive("menubar", ['authentication', 'contactUs', 'abiPermissions', '$rootScope', '$timeout',
    function (authentication, contactUs, abiPermissions, $rootScope, $timeout) {
        return {
            restrict: "E",
            template: tmpl,
            controller: MenuController,
            link: function ($scope) {
                var t = {}; // cancel timeout helper
                var SHOW_TIMEOUT = 100;
                var HIDE_TIMEOUT = 600;

                $scope.showSub = {};
                $scope.hasPermission = abiPermissions.hasPermission;

                $scope.logout = function () {
                    //true for sso hard logout
                    authentication.logout(true);
                };

                $scope.showSubMenu = function (show, subMenu) {
                    // show = true;
                    $timeout.cancel(t[subMenu]);

                    t[subMenu] = $timeout(function () {
                        $scope.showSub[subMenu] = show;
                    }, show ? SHOW_TIMEOUT : HIDE_TIMEOUT);
                };

                $scope.showContactUs = function ($event) {
                    contactUs.show();
                    $event.stopPropagation();
                };

                $scope.helpCenterClicked = function ($event) {
                    const currentChannel = $scope.context.current.gridChannels.label;
                    MixpanelCommon.trackHelpCenter('getting started', currentChannel);
                    $event.stopPropagation();
                };

                $scope.goToAudienceInlineWrapper = function ($event) {
                    window.location.replace('/audience-inline-wrapper.html');
                    $event.stopPropagation();
                };

                $rootScope.$on('resizeTransitionEnd', function () {
                    $scope.isMenuOpen = $rootScope.menuOpen;
                });
            }
        }
    }
]).directive('eatClickIf', ['$parse', '$rootScope', function ($parse, $rootScope) {
    return {
        // this ensure eatClickIf be compiled before ngClick
        priority: 100,
        restrict: 'A',
        compile: function ($element, attr) {
            var fn = $parse(attr.eatClickIf);
            return {
                pre: function link(scope, element) {
                    var eventName = 'click';
                    element.on(eventName, function (event) {
                        var callback = function () {
                            if (fn(scope, {$event: event})) {
                                // prevents ng-click to be executed
                                event.stopImmediatePropagation();
                                // prevents href
                                event.preventDefault();
                                return false;
                            }
                        };
                        if ($rootScope.$$phase) {
                            scope.$evalAsync(callback);
                        } else {
                            scope.$apply(callback);
                        }
                    });
                },
                post: function () {
                }
            }
        }
    }
}]);
