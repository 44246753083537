import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  compact,
  debounce,
  difference,
  filter,
  find,
  findIndex,
  flatMap,
  groupBy,
  includes,
  intersection,
  isEmpty,
  join,
  keys,
  map,
  reject,
  union,
  uniqBy,
} from 'lodash';
import './CommercialsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import WindowEventListener from '../../../../../common/WindowEventListener/WindowEventListener';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import SelectToggle from '../../../../../common/SelectToggle/SelectToggle';
import ClickableIcon from '../../../../../common/ClickableIcon/ClickableIcon';
import {
  dateToString,
  isSmartTvChannel,
  LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP,
  LEVELS_OF_EXPOSURE,
  VIEWERSHIP_MODES,
  VIEWING_TYPES,
} from '../../../../../../../data/audience-segment-builder-helper';
import SingleSelectToggle from '../../../../../common/SingleSelectToggle/SingleSelectToggle';
import { getKeywords } from '../../../../../../services/TvService';
import ResultsTable from '../../../../../common/ResultsTable/ResultsTable';

const CommercialsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  commercialsInput,
  commercialsMetadataPromise,
  channel,
  isTimeframeVisible,
}) => {
  /* eslint-enable camelcase */
  const SEGMENT_TYPE = 'smartTvCommercials';
  const CHANNELS_WITHOUT_BRAND_PARENT = ['smart_tv_inscape', 'hisense'];
  const WITH_BRAND_PARENT = !includes(CHANNELS_WITHOUT_BRAND_PARENT, channel);
  const IS_ADVANCED_TV_CHANNEL = includes(['tivo', 'hisense'], channel);
  const RESPONSE_ATTRIBUTES = WITH_BRAND_PARENT
    ? ['brand_parents', 'brands', 'creatives', 'brand_parent_to_brands', 'brand_to_creatives', 'brand_to_brand_parents']
    : ['brands', 'creatives', 'brand_to_creatives'];
  const FULL_SCREEN_MAX_HEIGHT = '600px';
  const SMALL_SCREEN_MAX_HEIGHT = '515px';
  const TOOLTIP_MSG = 'Currently streaming data does not have commercials';

  // mappings between levels (parent<->brand->creative)
  const [brandParentToBrands, setBrandParentToBrands] = useState({});
  const [brandToCreatives, setBrandToCreatives] = useState({});
  const [brandToBrandParents, setBrandToBrandParents] = useState({});
  const [brandsToProducts, setBrandsToProducts] = useState({});
  const [productsToBrands, setProductsToBrands] = useState({});

  // all available values
  const [allBrands, setAllBrands] = useState([]);
  const [allCreatives, setAllCreatives] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  // values currently in dropdown
  const [brandParentValues, setBrandParentValues] = useState([]);
  const [brandValues, setBrandValues] = useState([]);
  const [creativeValues, setCreativeValues] = useState([]);
  const [productsValues, setProductsValues] = useState([]);

  // values selected in dropdown
  const [selectedBrandParents, setSelectedBrandParents] = useState(commercialsInput.brandParents || []);
  const [selectedBrands, setSelectedBrands] = useState(commercialsInput.brands || []);
  const [selectedCreatives, setSelectedCreatives] = useState(commercialsInput.creatives || []);
  const [selectedProducts, setSelectedProducts] = useState(commercialsInput.products || []);

  const [showSpinner, setShowSpinner] = useState(true);
  const [modalMaxHeight, setModalMaxHeight] = useState(FULL_SCREEN_MAX_HEIGHT);
  const [selectedStartDate, setSelectedStartDate] = useState(commercialsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(commercialsInput.endDate);
  const [selectedTimeframe, setSelectedTimeframe] = useState(commercialsInput.timeframe);
  const [levelOfExposure, setLevelOfExposure] = useState(commercialsInput.exposure);
  const [viewingType, setViewingType] = useState(
    channel === 'tivo' ? 'linear' : commercialsInput.viewingType?.value || 'both'
  );
  const [viewershipModes, setViewershipModes] = useState(commercialsInput.viewModes || []);
  const TIVO_COMMERCIAL_VIEWING_TYPES = [
    { label: 'Both', value: 'both', disabled: true, tooltip: TOOLTIP_MSG },
    { label: 'Streaming', value: 'streaming', disabled: true, tooltip: TOOLTIP_MSG },
    { label: 'Linear', value: 'linear' },
  ];
  const setModalMaxHeights = () => {
    setModalMaxHeight(window.innerHeight > 900 || WITH_BRAND_PARENT ? FULL_SCREEN_MAX_HEIGHT : SMALL_SCREEN_MAX_HEIGHT);
  };

  useEffect(() => {
    setModalMaxHeights();
  });

  const fetchCommercialsMetadata = async () => {
    const res = await commercialsMetadataPromise;
    const missingProperties = difference(RESPONSE_ATTRIBUTES, keys(res));
    const noSelectedBrandParents = !WITH_BRAND_PARENT || isEmpty(selectedBrandParents);
    const products = map(res.products, (productItem) => ({
      value: productItem,
      label: productItem,
    }));
    if (missingProperties.length)
      throw new Error(`The following properties are missing: ${join(missingProperties, ', ')}`);

    setBrandParentToBrands(res.brand_parent_to_brands);
    setBrandToCreatives(res.brand_to_creatives);
    setBrandToBrandParents(res.brand_to_brand_parents);
    setBrandsToProducts(res.brands_to_products);
    setProductsToBrands(res.products_to_brands);

    setAllBrands(res.brands);
    setAllCreatives(res.creatives);
    setAllProducts(res.products);

    setBrandParentValues(res.brand_parents);
    setBrandValues(res.brands);
    setCreativeValues(res.creatives);
    setProductsValues(products);

    if (isEmpty(selectedCreatives) && isEmpty(selectedBrands) && noSelectedBrandParents) {
      setShowSpinner(false);
      return;
    }

    const parentValues = map(selectedBrandParents, 'value');
    const getSelectedValues = (resMappingValues) => {
      resMappingValues = resMappingValues || {};
      const selectedValues = new Set(
        WITH_BRAND_PARENT
          ? flatMap(selectedBrands, (brand) => {
              const currentBrandBrandParentIds = isEmpty(parentValues)
                ? res.brand_to_brand_parents[brand.value]
                : intersection(parentValues, res.brand_to_brand_parents[brand.value]);
              return flatMap(
                currentBrandBrandParentIds,
                (brandParentId) => resMappingValues[`${brandParentId}_${brand.value}`]
              );
            })
          : flatMap(selectedBrands, (brand) => resMappingValues[brand.value])
      );
      return selectedValues;
    };
    const selectedCreativeValues = getSelectedValues(res.brand_to_creatives);
    const creatives = res.creatives.filter((creative) => selectedCreativeValues.has(creative.value));
    const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({
      label,
      value: map(values, 'value'),
    }));
    setCreativeValues(mergedCreatives);
    const selectedProducts = getSelectedValues(res.brands_to_products);
    const productsValues = (res.products || []).filter((product) => selectedProducts.has(product));
    const mergedProducts = map(productsValues, (productItem) => ({
      value: productItem,
      label: productItem,
    }));
    setProductsValues(mergedProducts);

    if (noSelectedBrandParents) {
      setShowSpinner(false);
      return;
    }

    const selectedBrandsValues = new Set(
      flatMap(selectedBrandParents, (parent) => res.brand_parent_to_brands[parent.value])
    );
    const brands = res.brands.filter((brand) => selectedBrandsValues.has(brand.value));
    setBrandValues(brands);
    setShowSpinner(false);
  };

  useEffect(() => {
    fetchCommercialsMetadata();
  }, [commercialsMetadataPromise]);

  const handleSubmit = useCallback(() => {
    const segment = {
      ...commercialsInput,
      type: SEGMENT_TYPE,
      channel,
      queryKeys: selectQueryKeys(),
      ...(WITH_BRAND_PARENT && { brandParents: selectedBrandParents }),
      brands: selectedBrands,
      creatives: selectedCreatives,
      ...(selectedStartDate && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && { endDate: dateToString(selectedEndDate) }),
      ...(selectedTimeframe && { timeframe: selectedTimeframe }),
      ...(isSmartTvChannel(channel) && { exposure: levelOfExposure }),
    };
    if (!selectedTimeframe) {
      delete segment.timeframe;
    }

    if (IS_ADVANCED_TV_CHANNEL) {
      segment.viewingType = viewingType;
      segment.viewershipModes = viewershipModes;
    }
    onSubmit(segment);
  }, [
    selectedCreatives,
    selectedBrands,
    selectedBrandParents,
    selectedProducts,
    brandParentValues,
    brandValues,
    creativeValues,
    selectedStartDate,
    selectedEndDate,
    selectedTimeframe,
    levelOfExposure,
  ]);

  const handleCancel = () => {
    onCancel(SEGMENT_TYPE);
  };

  const onParentsSelect = useCallback(
    (parents) => {
      const selectedBrandsValues = new Set(flatMap(parents, (parent) => brandParentToBrands[parent.value]));
      const selectedBrands = allBrands.filter((brand) => selectedBrandsValues.has(brand.value));
      setSelectedBrandParents(parents);
      setBrandValues(selectedBrands.length ? selectedBrands : allBrands);
      onBrandsSelect(selectedBrands, undefined, parents);
    },
    [
      allBrands,
      allCreatives,
      creativeValues,
      selectedCreatives,
      brandParentToBrands,
      brandToCreatives,
      brandToBrandParents,
    ]
  );

  const onBrandsSelect = useCallback(
    (brands, oldSelectedBrands, parents) => {
      const creativesSet = createCreativesSet(brands, parents);
      const creatives = allCreatives.filter((creative) => creativesSet.has(creative.value));
      const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({
        label,
        value: map(values, 'value'),
      }));
      const productsSet = createProductsSet(brands, parents);
      const products = (allProducts || []).filter((product) => productsSet.has(product));
      const mergedProducts = map(products, (productItem) => ({
        value: productItem,
        label: productItem,
      }));
      setSelectedBrands(brands);
      setCreativeValues(mergedCreatives);
      setSelectedCreatives(mergedCreatives);
      setProductsValues(mergedProducts);
      setSelectedProducts(mergedProducts);
    },
    [
      allCreatives,
      creativeValues,
      selectedCreatives,
      brandToCreatives,
      allProducts,
      productsValues,
      brandsToProducts,
      brandToBrandParents,
    ]
  );

  const getSelectedObjectSetFunc = (mapping) => (brands, parents) => {
    mapping = mapping || {};
    const parentValues = parents && map(parents, 'value');
    const selectedObjectValueMemory = new Set();
    brands.forEach((brand) => {
      if (WITH_BRAND_PARENT) {
        const currentBrandBrandParentIds = parents
          ? intersection(parentValues, brandToBrandParents[brand.value])
          : brandToBrandParents[brand.value];
        return currentBrandBrandParentIds.forEach((brandParentId) =>
          (mapping[`${brandParentId}_${brand.value}`] || []).forEach((value) => selectedObjectValueMemory.add(value))
        );
      }

      return (mapping[brand.value] || []).forEach((value) => selectedObjectValueMemory.add(value));
    });
    return selectedObjectValueMemory;
  };

  const createCreativesSet = useCallback(getSelectedObjectSetFunc(brandToCreatives), [
    brandToCreatives,
    brandToBrandParents,
  ]);
  const createProductsSet = useCallback(getSelectedObjectSetFunc(brandsToProducts), [
    brandsToProducts,
    brandToBrandParents,
  ]);

  const onProductsSelect = useCallback(
    (products) => {
      const newSelectedBrandIds = new Set(
        flatMap(products, (product) => map(productsToBrands[product.value], 'brand_id'))
      );
      const newSelectedBrands = allBrands.filter((brand) => newSelectedBrandIds.has(brand.value));
      const creativesSet = createCreativesSet(newSelectedBrands);
      const creatives = allCreatives.filter((creative) => creativesSet.has(creative.value));
      const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({
        label,
        value: map(values, 'value'),
      }));
      setSelectedBrands(newSelectedBrands);
      setSelectedCreatives(mergedCreatives);
      setSelectedProducts(products);
    },
    [productsToBrands, allBrands, allCreatives]
  );

  const getDropdownSummaryTextBuilder = (allSelectedText, noneSelectedText, emptySelectedText = 'Select') => (
    selectedValues,
    values
  ) => {
    if (!selectedValues.length) return noneSelectedText;
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return emptySelectedText;
    if (selectedValues.length === values.length) return allSelectedText;
    return map(selectedValues, 'label').join(', ');
  };

  const parentsSummaryTextBuilder = getDropdownSummaryTextBuilder('Select parent brands', 'Select parent brands');
  const brandsSummaryTextBuilder = getDropdownSummaryTextBuilder('All brands', 'Select brands', 'Select brands');
  const creativesSummaryTextBuilder = getDropdownSummaryTextBuilder('All creatives', 'All creatives');
  const productsSummaryTextBuilder = getDropdownSummaryTextBuilder('All products', 'All products');

  const keywordsSearchDebounce = debounce((searchString, resolve) => {
    const reduceFunction = (param, item) => `${param ? param + ',' : ''}${item.value}`;
    const brand = selectedBrands.reduce(reduceFunction, '');
    const parentBrand = selectedBrandParents.reduce(reduceFunction, '');
    const paramsObj = { text: searchString };
    if (parentBrand) {
      paramsObj['parent_brand'] = parentBrand;
    }
    if (brand) {
      paramsObj.brand = brand;
    }
    let queryParams = new URLSearchParams(paramsObj).toString();
    queryParams = decodeURIComponent(queryParams);
    getKeywords(queryParams).then((res) =>
      resolve(
        res.map((item) => ({
          label: `${item.creative} | ${item.product_name}, ${item.brand_parent}, ${item.brand}`,
          value: item.ad_id,
          product: item.product_name,
          brand: item.brand,
          brand_parent: item.brand_parent,
          secondaryText: `${item.brand_parent} | ${item.brand}`,
        }))
      )
    );
  }, 300);

  const keywordsSearch = (searchString) => new Promise((resolve) => keywordsSearchDebounce(searchString, resolve));

  const resetSelection = () => {
    setSelectedBrandParents([]);
    setSelectedCreatives([]);
    setSelectedBrands([]);
  };

  const onRemove = (item, type) => {
    const resultsWrapper = find(resultsTable, { type });
    const selectedResults = reject(resultsWrapper.results, { value: item.value });
    switch (type) {
      case 'creative':
        setSelectedCreatives(selectedResults);
        break;
      case 'parent_brand':
        setSelectedBrandParents(selectedResults);
        break;
      case 'brand':
        setSelectedBrands(selectedResults);
        break;
    }
  };

  const selectQueryKeys = useCallback(() => {
    if (!isAllSelected(selectedCreatives, creativeValues)) return ['brands', 'creatives'];
    if (!isAllSelected(selectedBrands, brandValues)) return ['brands'];
    return ['brandParents'];
  }, [selectedCreatives, selectedBrands, brandValues, creativeValues]);

  const isAllSelected = (selectedValues, values) => !selectedValues.length || selectedValues.length === values.length;

  const maxNumberOfSelectedBrands = WITH_BRAND_PARENT ? 50 : 7;
  const reachMaxSelectedBrandsMsg = `Sorry, this field is limited to ${maxNumberOfSelectedBrands} brands`;

  const resultsTable = useMemo(
    () =>
      compact([
        !isEmpty(selectedCreatives) && {
          type: 'creative',
          title: 'Creative',
          results: selectedCreatives,
        },
        !isEmpty(selectedBrandParents) && {
          type: 'parent_brand',
          title: 'Parent brand',
          results: selectedBrandParents,
        },
        !isEmpty(selectedBrands) && {
          type: 'brand',
          title: 'Brand',
          results: selectedBrands,
        },
      ]),
    [selectedCreatives, selectedBrandParents, selectedBrands]
  );

  const onSelectKeywords = (newKeywords) => {
    newKeywords.forEach((keywords) => (keywords.label = keywords.label.split(' |')[0]));
    setSelectedCreatives((oldKeywords) => uniqBy(union(oldKeywords, newKeywords), 'value'));
  };

  return (
    <div className="commercials-modal-component">
      <WindowEventListener events="resize" eventHandlerFunction={setModalMaxHeights} />
      <ModalWithConfirmationButtons
        width="800px"
        maxHeightBeforeScroll={modalMaxHeight}
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        showSpinner={showSpinner}
        isSubmitDisabled={isEmpty(selectedCreatives)}
        isDisabledEnterKeyPress={isEmpty(selectedCreatives)}
        isLightTheme
        isYAutoScrollEnabled={false}
      >
        <div className="commercials-modal-content">
          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row viewer-type-wrapper">
              <div className="viewing-type">
                <div className="row-label">Viewing Type:</div>
                <SingleSelectToggle
                  values={channel === 'tivo' ? TIVO_COMMERCIAL_VIEWING_TYPES : VIEWING_TYPES}
                  selectedValue={viewingType}
                  onToggle={setViewingType}
                  isModalTheme
                />
              </div>
              {viewingType != 'streaming' && (
                <div className="viewership-mode">
                  <div className="row-label">Viewership Mode:</div>
                  <Dropdown
                    values={VIEWERSHIP_MODES}
                    selectedValues={viewershipModes}
                    isMulti
                    summaryTextBuilder={getDropdownSummaryTextBuilder('All viewership modes')}
                    onSelect={setViewershipModes}
                  />
                </div>
              )}
            </div>
          )}

          {WITH_BRAND_PARENT && (
            <div className="modal-row parent-brands">
              <div className="row-label">Parent brand:</div>
              <Dropdown
                selectedValues={selectedBrandParents}
                isMulti
                isSearchable
                summaryTextBuilder={parentsSummaryTextBuilder}
                onSelect={onParentsSelect}
                values={brandParentValues}
                maxNumberOfSelectedOptions={7}
                reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 parent brands"
              />
            </div>
          )}
          <div className="modal-row brands">
            <div className="row-label">Brand:</div>
            <Dropdown
              selectedValues={selectedBrands}
              isMulti
              isSearchable
              summaryTextBuilder={brandsSummaryTextBuilder}
              onSelect={onBrandsSelect}
              showSelectAllOptions={!isEmpty(selectedBrandParents)}
              values={brandValues}
              isOpen={isEmpty(selectedBrandParents) ? false : undefined}
              maxNumberOfSelectedOptions={maxNumberOfSelectedBrands}
              reachMaxSelectedOptionsMsg={reachMaxSelectedBrandsMsg}
            />
          </div>

          <div className="modal-row creatives">
            <div className="row-label">Creative:</div>
            <Dropdown
              selectedValues={selectedCreatives}
              isMulti
              isSearchable
              isDisabled={isEmpty(selectedBrands)}
              summaryTextBuilder={creativesSummaryTextBuilder}
              onSelect={setSelectedCreatives}
              showSelectAllOptions
              values={creativeValues}
              isOpen={
                isEmpty(selectedBrands) || (WITH_BRAND_PARENT && isEmpty(selectedBrandParents)) ? false : undefined
              }
            />
          </div>

          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row products">
              <div className="row-label">Products:</div>
              <Dropdown
                selectedValues={selectedProducts}
                isMulti
                isSearchable
                summaryTextBuilder={productsSummaryTextBuilder}
                onSelect={onProductsSelect}
                showSelectAllOptions
                values={productsValues}
                isOpen={
                  isEmpty(selectedBrands) || (WITH_BRAND_PARENT && isEmpty(selectedBrandParents)) ? false : undefined
                }
              />
            </div>
          )}

          {IS_ADVANCED_TV_CHANNEL && (
            <div className="modal-row keywords">
              <div className="row-label">Keyword Search:</div>
              <Dropdown
                selectedValues={selectedCreatives}
                customSearch={keywordsSearch}
                isMulti
                isSearchable
                summaryTextBuilder={() => 'Search for Comma-separated Keywords / Phrases'}
                onSelect={onSelectKeywords}
                maxNumberOfSelectedOptions={7}
                reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 keywords"
              />
            </div>
          )}
          {!isEmpty(resultsTable) && (
            <div className="modal-row selected-items">
              <ResultsTable resultsTable={resultsTable} onReset={resetSelection} onRemove={onRemove} />
            </div>
          )}

          {isTimeframeVisible && isSmartTvChannel(channel) && (
            <div className="modal-row">
              <div className="row-label">Timeframe:</div>
              <TvCustomTimeframe
                startDateString={selectedStartDate}
                endDateString={selectedEndDate}
                timeframe={selectedTimeframe}
                selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
                selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
                selectedTimeframeCB={setSelectedTimeframe}
              />
            </div>
          )}
          {isSmartTvChannel(channel) && (
            <div className="modal-row level-of-exposure">
              <div className="row-label">
                <span className="label">Level of Exposure:</span>
                <ClickableIcon iconId="info-italic" tooltip={LEVEL_OF_EXPOSURE_COMMERCIALS_TOOLTIP} />
              </div>
              <SelectToggle
                values={LEVELS_OF_EXPOSURE}
                selectedValues={levelOfExposure}
                onChange={setLevelOfExposure}
              />
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

CommercialsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  commercialsMetadataPromise: PropTypes.object,
  commercialsInput: PropTypes.object,
  channel: PropTypes.string,
  isTimeframeVisible: PropTypes.bool,
};

CommercialsModal.defaultProps = {
  commercialsInput: {},
  channel: '',
  isTimeframeVisible: true,
};

export default CommercialsModal;
