module.exports = angular.module(__filename, [
    require('./am-select-tree.drv/select-tree.drv').name,
    require('../am-container-collection/am-container-collection').name,
    require('infra/directiveBuildHelper').name,
    require('infra/debouncer').name
]).directive('amInput', ["$parse", "$compile", "utils", "$q", "directiveBuildHelper", "debouncer", "util",
    /* base directive for interface for all am-input family */
    function ($parse, $compile, utils, $q, directiveBuildHelper, debouncer, util) {
        var template = {
            'dropdown': require('./am-dropdown.drv/dropdown.drv.html'),
            'radio-group': require('./am-radio-group.drv/radio-group.drv.html'),
            'switch': require('./am-switch.drv/switch.drv.html'),
            'checkbox-group': require('./am-checkbox-group.drv/checkbox-group.drv.html'),
            'radio-list': require('./am-radio-list.drv/radio-list.drv.html'),
            'checkbox-list': require('./am-radio-list.drv/radio-list.drv.html'),
            'checkbox-text': require('./am-checkbox-text.drv/checkbox-text.html'),
            'checkbox': '',
            'checked': require('./am-checked/am-checked.html')
        };

        var postLink = {
            oneFromOne: require('./postLink-OneFromOne'),
            oneFromMany: require('./postLink-OneFromMany'),
            ManyFromMany: require('./postLink-ManyFromMany.js')
        };

        var postLinkPerType = {
            'dropdown': postLink.oneFromMany,
            'radio-group': postLink.oneFromMany,
            'radio-list': postLink.oneFromMany,
            'checkbox-list': postLink.ManyFromMany,
            'checkbox-group': postLink.ManyFromMany,
            'checkbox-text': postLink.oneFromOne,
            'switch': postLink.oneFromOne,
            'checkbox': postLink.oneFromOne,
            'checked': postLink.oneFromOne
        };

        var idSeq = 1;

        return {
            restrict: "E",
            require: ['ngModel', '^?amContainer'],
            priority: 30,
            scope: {
                selectAll: '=',
                modelIsArray: '=',
                icon: '@',
                onSelected: '&',
                onEdit: '&',
                onNew: '&',
                placeholder: '@',
                iconToLabelMap: '=',
                className: '@'
            },
            transclude: true,
            controller: require('./am-input-controller'),
            controllerAs: 'inputCtrl',
            bindToController: false,
            template: function (element, attr) {
                return template[attr.type];
            },
            /* todo: add ignore attribute */
            compile: function (element, attr, ctrls, transcludeFn) {
                return {
                    pre: function (scope, element, attr, ctrls, transcludeFn) {
                        var internal = false;
                        var modelCtrl = ctrls[0];
                        var containerCtrl = ctrls[1];
                        var id = idSeq++;
                        var debounce = attr.debounce ? attr.debounce * 1 : null;

                        scope.ctrl = modelCtrl;
                        /* for debugging */
                        var inputCtrl = scope.inputCtrl,
                            parser = inputCtrl.parser,
                            getSelectedOptionFromOutside = inputCtrl.getSelectedOptionFromOutside,
                        /* helper function */
                            setAll = scope.setAll = inputCtrl.setAll;

                        if (containerCtrl && scope.modelIsArray) {
                            containerCtrl.add(inputCtrl);
                        }

                        var setViewValue = modelCtrl.$setViewValue;
                        if (attr.debounceGroup) {
                            modelCtrl.$setViewValue = function () {
                                var value = arguments;
                                inputCtrl.inUpdateState = true;
                                debouncer.debounce(id, attr.debounceGroup, function () {
                                    inputCtrl.inUpdateState = false;
                                    setViewValue.apply(modelCtrl, value);
                                }, debounce);
                            };
                        } else if (attr.debounce) {
                            var $setViewValue = modelCtrl.$setViewValue;
                            var update = _.debounce(function () {
                                inputCtrl.inUpdateState = false;
                                $setViewValue.apply(modelCtrl, arguments);
                            }, attr.debounce * 1, {trailing: true});

                            modelCtrl.$setViewValue = function () {
                                inputCtrl.inUpdateState = true;
                                update.apply(modelCtrl, arguments);
                            };
                        }

                        scope.dontShowTooltip = function(str, maxLength){
                            return util.isTextOverflow(str, maxLength);
                        };

                        scope.$watch('::modelIsArray', function (nv) {
                            if (!nv) return;
                            inputCtrl.returingArray = nv;
                        });

                        /* init the ng model ctrl list */
                        if (attr.data) {
                            /* model -> (to internal objects) input.model */
                            modelCtrl.$formatters.unshift(function (models) {
                                inputCtrl.modelValue = _.map(_.array(modelCtrl.$modelValue), parser.converter);
                            });

                            /* model -> view */
                            modelCtrl.$formatters.unshift(function (models) {
                                if (inputCtrl.inUpdateState) return modelCtrl.$viewValue;
                                if (inputCtrl.options.length == 0) return modelCtrl.$viewValue;
                                return getSelectedOptionFromOutside();
                            });

                            /* options() -> view */
                            parser.onSourceUpdate(function () {
                                modelCtrl.$viewValue = getSelectedOptionFromOutside();
                                modelCtrl.$render();
                            });

                            /* view -> model */
                            //todo: change it to just remove and add to array;
                            modelCtrl.$parsers.push(inputCtrl.commitSelection);
                        }
                        /************************************************
                         * view -> model array of value
                         * fire event for every am-input
                         ********************************************** */
                        modelCtrl.$parsers.push(function (vals) {
                            vals = inputCtrl.returingArray ? vals : vals[0];
                            return vals;
                        });

                        modelCtrl.$viewChangeListeners.push(function (vals) {
                            attr.onSelected && scope.onSelected({
                                $value: modelCtrl.$modelValue,
                                $event: inputCtrl.$event
                            });
                            modelCtrl.$render();
                            return vals;
                        });

                        /* for SELECT ALL attribute, is meaningful just with data */
                        /* must be at end for 'onSourceUpdate' run last */
                        if (attr.data) {
                            /* outer selectAll -> inner selectAll */
                            scope.$watch('selectAll', function (nv, ol) {
                                if (internal) return internal = false;
                                if (!!nv ^ !!ol) { // xor it mean change
                                    setAll(inputCtrl.options, 'selected', nv);
                                    modelCtrl.$setViewValue(nv ? inputCtrl.options.concat() : []);
                                }
                            });

                            scope.$watch(function(){return inputCtrl.options}, syncSelectAllAttr);

                            modelCtrl.$viewChangeListeners.push(syncSelectAllAttr);
                            parser.onSourceUpdate(syncSelectAllAttr);
                        }

                        /* inner selectAll -> outer selectAll */
                        function syncSelectAllAttr() {
                            if (attr.selectAll && inputCtrl.options.length > 0) {
                                var selectedAll = _.every(inputCtrl.options, {selected: true});
                                internal = (selectedAll != scope.selectAll);
                                scope.selectAll = selectedAll;
                            }
                        }
                    },
                    post: postLinkPerType[attr.type]
                }
            }
        }
    }
]);
