import TvApi from '../api/TvApi';
import { executeHttpRequest } from '../infra/HttpClient';
import { cacheAsyncMethod } from '../infra/cache/CacheUtils';
import { each, sortBy, values } from 'lodash';

export function getGenres() {
  return executeHttpRequest(TvApi.getGenres());
}

export function getNetworks(channel) {
  return executeHttpRequest(TvApi.getNetworks(channel));
}

export function getShows(channel) {
  return executeHttpRequest(TvApi.getShows(channel));
}

export function getCommercials(channel) {
  return executeHttpRequest(TvApi.getCommercials(channel));
}

export function getHierarchy(channel) {
  return executeHttpRequest(TvApi.getHierarchy(channel));
}

export function getShowsMetadataByids(ids, channel) {
  return executeHttpRequest(TvApi.getShowsMetadataByids(ids, channel));
}

export function getNielsenNetworksMap() {
  return executeHttpRequest(TvApi.getNielsenNetworksMap());
}

export function getKeywords(queryParams) {
  return executeHttpRequest(TvApi.getKeyWords(queryParams));
}

export async function getNetworksShows(networkIds, channel) {
  const networksShows = await executeHttpRequest(TvApi.getNetworksShows(networkIds, channel));
  return new Set(networksShows);
}

export function getAdvancedShows(channel, networks, genres) {
  const promise = executeHttpRequest(TvApi.getAdvancedShows(channel, networks, genres));
  return promise.then((data) => transformAdvancedTvShowsFromApi(data.shows));
}

export function getAdvancedGenres() {
  return executeHttpRequest(TvApi.getAdvancedGenres());
}

export function getAdvancedNetworks(channel) {
  return executeHttpRequest(TvApi.getAdvancedNetworks(channel));
}

export function transformAdvancedTvShowsFromApi(shows) {
  let res = {};
  each(shows, (show) => {
    res[show.series_id] = res[show.series_id] || {
      id: show.series_id,
      title: show.series_title,
      is_movie: show.is_movie,
      seasons: {},
    };
    res[show.series_id].seasons[show.season_number] = res[show.series_id].seasons[show.season_number] || {
      id: show.season_number,
      title: show.season_number ? `S${show.season_number}` : '(no season)',
      episodes: {},
    };
    const titlePrefix = show.episode_number ? `E${show.episode_number}` : show.date || '';
    res[show.series_id].seasons[show.season_number].episodes[show.program_id] = {
      id: show.program_id,
      title: titlePrefix + (titlePrefix && show.program_title ? ' - ' : '') + (show.program_title || ''),
      episode_number: show.episode_number,
      date: show.release_date,
    };
  });
  each(res, (show) => {
    each(
      show.seasons,
      (season) => (season.episodes = sortBy(values(season.episodes), ['episode_number', 'date', 'title']))
    );
    show.seasons = sortBy(values(show.seasons), 'season_number');
  });
  return sortBy(values(res), 'title');
}

// Cache

export const getCachedGenres = cacheAsyncMethod('TvService.getGenres', getGenres);

export const getCachedNetworks = cacheAsyncMethod('TvService.getNetworks', getNetworks, {
  cacheSingleEntryForMethod: false,
});

export const getCachedHierarchy = cacheAsyncMethod('TvService.getHierarchy', getHierarchy, {
  cacheSingleEntryForMethod: false,
});

export const getCachedShows = cacheAsyncMethod('TvService.getShows', getShows, {
  cacheSingleEntryForMethod: false,
});

export const getCachedCommercials = cacheAsyncMethod('TvService.getCommercials', getCommercials, {
  cacheSingleEntryForMethod: false,
});

export const getCachedShowsMetadataByids = cacheAsyncMethod('TvService.getShowsMetadataByids', getShowsMetadataByids);

export const getCachedNetworksShows = cacheAsyncMethod('TvService.getNetworksShows', getNetworksShows, {
  cacheSingleEntryForMethod: false,
});

export const getCachedAdvancedShows = cacheAsyncMethod('TvService.getAdvancedShows', getAdvancedShows, {
  cacheSingleEntryForMethod: false,
});

export const getCachedAdvancedGenres = cacheAsyncMethod('TvService.getAdvancedGenres', getAdvancedGenres);

export const getCachedAdvancedNetworks = cacheAsyncMethod('TvService.getAdvancedNetworks', getAdvancedNetworks, {
  cacheSingleEntryForMethod: false,
});
