import axios from 'axios';
import {
  displayErrorBannerIfNeeded,
  openNewVersionModal,
  redirectToLoginPage,
} from '../middleware/AngularExportedMiddleware';
import { difference, isEmpty } from 'lodash';
import * as Mixpanel from './mixpanel/Mixpanel';

const DEFAULT_OPTIONS = { returnOnlyData: true, displayRedBannerOnError: true, redirectOnUnauthorized: true };

const HttpStatus = {
  accepted: 202,
  resetContent: 205,
  unauthorized: 401,
};

/**
 * @param {number} delay The number of milliseconds to sleep.
 * @return {Promise<void>} A promise that will be resolved after {delay} milliseconds.
 */
function sleep(delay = 0) {
  return new Promise((resolve) => setTimeout(() => resolve(), delay));
}

/**
 * @return {Promise<void>} A promise that will be resolved on the next loop of the event loop.
 */
const nextTick = () => sleep();

const headerGet = (headers, key) => {
  headers = headers || {};
  key = key.toLowerCase();
  const [_, value] = Object.entries(headers).find(([hKey, _]) => hKey.toLowerCase() === key.toLowerCase()) || [];
  return value;
};

const ProxyTowerTokenHeader = 'X-PT-Token';

const responseValue = (response, { returnOnlyData }) => (returnOnlyData ? response.data : response);

export function executeWithProxyTower(request, options = DEFAULT_OPTIONS) {
  const { returnOnlyData } = options;
  return executeHttpRequest(request, { ...options, returnOnlyData: false }).then((response) => {
    if (response.status !== HttpStatus.accepted) return responseValue(response, { returnOnlyData });

    // If the token is missing, then the proxied server was the one to return the Accepted status.
    const token = headerGet(response.headers, ProxyTowerTokenHeader);
    if (!token) return responseValue(response, { returnOnlyData });

    request.headers = request.headers || {};
    request.headers[ProxyTowerTokenHeader] = token;
    return nextTick().then(() => executeWithProxyTower(request, options));
  });
}

/**
 *
 * @param {AxiosRequestConfig} request
 * @param {Options} options
 * @return {Promise<unknown>}
 */
export function executeHttpRequest(request, options = DEFAULT_OPTIONS) {
  validate(request, options);
  options = { ...DEFAULT_OPTIONS, ...options };
  const cancelToken = axios.CancelToken.source();
  const axiosRequest = transformRequestToAxiosFormat(request, cancelToken);

  const promise = axios(axiosRequest)
    .then((response) => {
      if (response.status === HttpStatus.resetContent) {
        openNewVersionModal();
        return Promise.reject(response);
      }

      return responseValue(response, options);
    })
    .catch((error) => {
      if (axios.isCancel(error) || error.status === HttpStatus.resetContent) return Promise.reject(error); // request has been canceled or rejected due to new version
      if (error.response && error.response.status == HttpStatus.unauthorized) {
        Mixpanel.track('401 Unauthorized', { requested: request.url });
        if (options.redirectOnUnauthorized) redirectToLoginPage();
        return Promise.reject(); // to be on the safe side: should be already redirected to login page
      }
      // In case of timeout there is no response and no status code
      if (options.displayRedBannerOnError) displayErrorBannerIfNeeded(error.response || error);
      throw error;
    });

  promise.cancel = cancelToken.cancel;
  return promise;
}

export function setCommonHeader(headerName, headerValue) {
  axios.defaults.headers.common[headerName] = headerValue;
}

function validate(request, options) {
  if (!request) throw Error('executeHttpRequest: missing request argument');
  if (!request.url) throw Error("executeHttpRequest: missing request's url");
  if (request.method && !['get', 'post', 'delete', 'put'].includes(request.method))
    throw Error(`executeHttpRequest: unknown request method ${request.method}`);
  if (request.payload && (request.method === 'get' || request.method === 'delete'))
    throw Error(`executeHttpRequest: payload is not allowed for method ${request.method}`);
  const unknownRequestKeys = difference(Object.keys(request), ['url', 'method', 'params', 'payload', 'headers']);
  if (!isEmpty(unknownRequestKeys))
    throw Error(`executeHttpRequest: unknown request keys: ${unknownRequestKeys.join(', ')}`);
  const unknownOptionsKeys = difference(Object.keys(options), Object.keys(DEFAULT_OPTIONS));
  if (!isEmpty(unknownOptionsKeys))
    throw Error(`executeHttpRequest: unknown options keys: ${unknownOptionsKeys.join(', ')}`);
}

function transformRequestToAxiosFormat(request, cancelToken) {
  // Support for url params that may be included to the request
  let params;
  if (request.params) {
    params = request.params;
  }

  if (request.payload) {
    const { payload: data, ...rest } = request;
    request = { data, ...rest };
  }
  if (!request.method) {
    request = { ...request, method: request.data ? 'post' : 'get' };
  }
  request = { ...request, cancelToken: cancelToken.token };
  if (params) {
    request = { ...request, params };
  }
  return request;
}
