import common from 'infra/utils/common';
import { QUERY_NAMES, QUERY_NAMES_V2 } from '../../react/services/AudienceServiceHelper';
import {
  getSegmentIds,
  getSegmentInterestsData,
  getSegmentInterestsDataByIds,
  getSegmentAdvancedInterestsDataByQueries,
  getFullDemographicsDataWithGenderAgeBySegment,
  getDataContract,
  getTaxonomyCategory,
  getAmplifiedEstimatedReachGoal,
  createAudienceTargetTaxonomy,
  createAudienceTargetUserList,
  createAmplifiedAudience,
  createAlwaysOnAudience,
  createDeterministicAudience,
  createUserListForDeterministicAudience,
} from '../../react/services/AudienceInsightsService';
import { isActivateAudienceEnabled, isDeterministicDynamicActivationAllowed, deterministicPermissionName } from '../../data/audience-segment-builder-helper';

import * as TargetComplianceService from 'react/services/TargetComplianceService';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import { convertStringToFloat } from '../../react/utils/NumberUtils';

const angular = require("angular"),
  BaseWidget = require("../base_widget");

AudienceInterestsWidgetController.$inject = ['TargetsCommon', 'util', 'TermsMold', 'context', '$state',
    'filtersPartition', 'segmentInterestExportService', 'abiPermissions', 'audienceTableStructure', 'confirmAction',
    'notificator', '$timeout', '$q', 'CHANNEL', 'TARGET_CONSTS', 'dspService', 'ssoService'];

function AudienceInterestsWidgetController (TargetsCommon, infraUtil, termsMold, context, $state,
                                            filtersPartition, segmentInterestExportService, abiPermissions,
                                            audienceTableStructure, confirmAction, notificator, $timeout, $q, CHANNEL,
                                            TARGET_CONSTS, dspService, ssoService) {
  const thisCtrl = this;
  const $scope = this.$scope;

  const TELCO_CHANNELS = ['data_spark', 'au_telco'];
  const VIEW_TYPES_NAMES = $scope.VIEW_TYPES_NAMES = {bars: 'bars', chart: 'bars', table: 'table'};
  const ALL_INTERESTS = $scope.ALL_INTERESTS = 'All Interests';
  const DEFAULT_SORT = 'composition';

  const audienceChannel = context.current.audience_app.current_channel.value;
  const segment = context.current.audience_app[audienceChannel].audience_segment;
  const isFirstParty = _.some(segment, {type: '1st party'});
  $scope.audienceIsFirstParty = isFirstParty;
  $scope.hasPermission = abiPermissions.hasPermission;
  $scope.isShowTargets = ()=> $scope.tab !== 'websites' && abiPermissions.hasPermission('activate targets');
  thisCtrl.all_ages = filtersPartition.age.map((a) => a.summary);

  const audienceTables = audienceTableStructure.tableStructure;
  thisCtrl.infraUtil = infraUtil;
  thisCtrl.segmentInterestExportService = segmentInterestExportService;
  $scope.termsMold = new termsMold();

  thisCtrl.exportButton = angular.element(document.querySelector('.export'))[0];

  const includeSearches = abiPermissions.hasPermission(['search']) && !isFirstParty &&
                          !TELCO_CHANNELS.includes(audienceChannel) && !['smart_tv_inscape', 'tivo', 'hisense'].includes(audienceChannel);
  $scope.interestTypes = _.compact([
    {value: 'websites', label: 'Websites', plural: 'websites', singular: 'website'},
    {value: 'phrases', label: 'Phrases', plural: 'phrases', singular: 'phrase'},
    includeSearches && {value: 'searches', label: 'Searches', plural: 'searches', singular: 'search term'}
  ]);
  $scope.interestTypesMap = _.keyBy($scope.interestTypes, 'value');
  $scope.$watch('interestType', () => { $scope.interestTypeInfo = $scope.interestTypesMap[$scope.interestType] });

  $scope.viewTypes = [
    {value: 'bars', icon_v2: 'list-hierarchical', label: '', width: "16px", height: "14px"},
    {value: 'table', icon_v2: 'table', label: '', width: "16px", height: "14px"}
  ];

  $scope.sortTypes = [
    {
      value: 'portion',
      label: 'Consumption',
      tooltip: 'Sort by how popular the interest is among the audience'
    },
    {
      value: 'composition',
      label: 'Skew',
      tooltip: 'Sort by the extent to which the interest is over-indexed within the audience'
    }
  ];
  $scope.sortType = $scope.sortType || DEFAULT_SORT;
  MixpanelAudience.trackDemographicsInterestsView($scope.sortType, 'interests', audienceChannel);

  const sortTypeToTopicKey = {portion: "percents", composition: "value"};

  $scope.query = audienceTables[audienceChannel];
  $scope.query.sortCB = 'mixpanelTrackSort';
  $scope.mixpanelTrackSort = (params) => {
    MixpanelAudience.trackSort(params, audienceChannel);
  };
  // default view option
  $scope.viewType = VIEW_TYPES_NAMES.chart;
  $scope.lastViewType = VIEW_TYPES_NAMES.chart;

  // targets handling
  $scope.tab = $scope.interestTypes[0].value;
  $scope.tabs = [$scope.tab];
  $scope.currentTarget = {};
  $scope.targets = [];
  $scope.navBackModal = { isOpen: false };

  //audience target
  $scope.channel = audienceChannel;
  $scope.audienceSegment = segment;
  const audienceAppForChannel = context.current.audience_app[audienceChannel];
  $scope.audienceId = audienceAppForChannel.audience_id;
  $scope.audienceName = audienceAppForChannel.audience_name;
  $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled;
  $scope.isActivateAudienceEnabled = false;
  $scope.activateAudienceDisabledText = '';
  $scope.isActivateAudienceVisible = false;
  $scope.isActivateTargetEnabled = audienceAppForChannel.is_activate_target_enabled;
  $scope.showTargets = false;
  $scope.isAudienceDeterministicActivated = audienceAppForChannel.is_audience_deterministic_activated;
  $scope.isAudienceAmplifiedActivated = audienceAppForChannel.is_audience_amplified_activated;
  $scope.isAudienceAlwaysOnActivated = audienceAppForChannel.is_audience_always_on_activated;
  $scope.activatedAmplifiedThreshold = audienceAppForChannel.activated_amplified_threshold;
  $scope.activatedAlwaysOnThreshold = audienceAppForChannel.activated_always_on_threshold;
  $scope.activatedMarket = audienceAppForChannel.activated_market;
  $scope.activatedAdvertiser = audienceAppForChannel.activated_advertiser;
  $scope.activatedDataContractId = audienceAppForChannel.activated_data_contract_id;
  $scope.activatedDataContractText = audienceAppForChannel.activated_data_contract_text;
  $scope.activatedCategoryId = audienceAppForChannel.activated_category_id;
  $scope.getSegmentIds = getSegmentIds;
  $scope.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
  $scope.createAudienceTargetUserList = createAudienceTargetUserList;
  $scope.createAmplifiedAudience = createAmplifiedAudience;
  $scope.createAlwaysOnAudience = createAlwaysOnAudience;
  $scope.createDeterministicAudience = createDeterministicAudience;
  $scope.createUserListForDeterministicAudience = createUserListForDeterministicAudience;
  $scope.dspService = dspService;
  $scope.notificator = notificator;
  $scope.ssoService = ssoService;
  $scope.openComplianceModal = false;
  $scope.nonCompliantPhrases = {};
  $scope.nonCompliantPhrasesByTab = {};
  $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
  $scope.getDataContract = getDataContract;
  $scope.getTaxonomyCategory = getTaxonomyCategory;
  $scope.isDeterministicDynamicAllowed = isDeterministicDynamicActivationAllowed(this.segment);
  $scope.allowDeterministicSegment = abiPermissions.hasPermission(deterministicPermissionName);
  $scope.getAmplifiedEstimatedReachGoal = getAmplifiedEstimatedReachGoal;
  updateAudienceActivationUi();

  async function updateAudienceActivationUi() {
    const channel = $scope.channel;
    const channelAudience = context.current.audience_app[channel];
    const tempAudience = {
      id: channelAudience.audience_id,
      name: channelAudience.audience_name,
      segment: channelAudience.audience_segment,
      activation: channelAudience.audience_activation,
    };
    $scope.isActivateAudienceEnabled = false;
    $scope.activateAudienceDisabledText = '';
    const {isEnabled, isVisible, disabledText} = await isActivateAudienceEnabled(abiPermissions, channel, tempAudience, $scope.$root.user.id);
    $scope.isActivateAudienceEnabled = isEnabled;
    if ($scope.isActivateAudienceEnabled) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');

    $scope.isActivateAudienceVisible = isVisible;
    $scope.activateAudienceDisabledTextisActivateEnabled = disabledText;
    $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled || isVisible;
    $scope.$digest();
  }

  $scope.updateAudienceDeterministicActivated = function (activatedMarket, activatedAdvertiser) {
    common.updateAudienceDeterministicActivated(activatedMarket, activatedAdvertiser, context, $scope, $scope.channel);
    updateAudienceActivationUi();
  };

  $scope.updateAudienceAmplifiedActivated = function (activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold) {
    common.updateAudienceAmplifiedActivated(activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold,
                                            context, $scope, $scope.channel);
    updateAudienceActivationUi();
  };

  $scope.updateAudienceAlwaysOnActivated = function (activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                     activatedAlwaysOnThreshold) {
    common.updateAudienceAlwaysOnActivated(activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                           activatedAlwaysOnThreshold, context, $scope, $scope.channel);
    updateAudienceActivationUi();
  };

  if($scope.showTargets){
    TargetsCommon.getTargets({scope: $scope, $state: $state, tab: $scope.tab});
  }

  const updateTargetsHandlerWatch = $scope.$root.$on('updateTargets', (event, obj) => {
    TargetsCommon.updateTargetsHandler(obj, $scope);
  });

  $scope.$on('$destroy', () => {
    updateTargetsHandlerWatch(); //clears watch
    TargetComplianceService.unloadTarget();
  });

  $scope.$on('remove-all-from-target-cb', () => {
    resetSelection();
  });

  $scope.openTargets = () => {
    TargetsCommon.openTargets({scope: $scope});
  };

  $scope.editTarget = () => {
    if ($scope.currentTarget) TargetsCommon.edit({target: $scope.currentTarget, $scope});
  };

  $scope.validateAddToTarget = function() {
    $scope.allowedAddToTarget = TargetsCommon.validateAddToTarget($state, $scope);
    if (!$scope.allowedAddToTarget) {
      const advertiser_id = $scope.currentTarget.advertiser_id;
      notificator.notify({body: `Adding 1st Party audience phrases will change this Target type. This Target is activated to advertiser ${advertiser_id} and can not be changed at the moment.`});
    }
  };

  $scope.onCloseCompliance = () => {
    $scope.openComplianceModal = false;
    $timeout(()=>$scope.$apply());
  };

  $scope.saveWithNonCompliant = () => {
      if($scope.query.selected.length){
          TargetsCommon.addToCurrentTarget({state: $state, scope: $scope, skipCompliance: true});
          TargetComplianceService.addNonCompliantPhrases($scope.nonCompliantPhrases);
      }else{
          TargetsCommon.clearSelection($scope);
      }
      $scope.onCloseCompliance();
  };

  $scope.saveWithoutNonCompliant = ()=> {
      $scope.query.selected = $scope.query.selected.filter((p)=> {
          let phrase = TargetComplianceService.entityText(p, $scope.tab);
          let phraseObj = $scope.complianceResults[phrase];
          return TargetComplianceService.isPhraseObjCompliance(phraseObj);
      });

      $scope.nonCompliantPhrases = {};
      $scope.saveWithNonCompliant();
  };

  $scope.addToCurrentTarget = () => {
    const currentChannel = context.current.audience_app.current_channel.value,
          audience_id = context.current.audience_app[currentChannel].audience_id;

    $scope.query.selected.forEach((s) => { s.audience_id = audience_id });

    TargetsCommon.addToCurrentTarget({state: $state, scope: $scope});
  };

  $scope.toggleSelected = (row, $event) => {
    TargetsCommon.toggleSelected($scope, row, $event);
    $scope.validateAddToTarget();
  };

  $scope.toggleSelectedBar = (bar) => {
    const row = $scope.query.dataArray.find((o) => o.id === bar.id);
    if (!row) return;
    row.selected = bar.selected;
    $scope.toggleSelected(row, null);
  };

  $scope.toggleSelectedAllBar = (selectAll, bars) => {
    if (selectAll) {
      const barIds = bars.map((bar) => bar.id);
      $scope.query.selected = $scope.query.dataArray.filter((o) => barIds.includes(o.id));
      $scope.query.selected.forEach((row) => row.selected = true);
    } else {
      $scope.query.dataArray.forEach((row) => row.selected = false);
      $scope.query.selected = [];
    }

    TargetsCommon.mixpanelTargets.toggleSelectAll(selectAll);
  };

  // interests data

  const targetRatio = context.current.audience_segment_target_ratio,
    targetRatioObj = targetRatio ? {'wanted-intenders-ratio': targetRatio} : undefined;

  // * topics

  const topicsPromise = getSegmentInterestsData(segment, QUERY_NAMES.topics, audienceChannel, targetRatioObj)
    .then(throwForInvalidDataResponse).catch(expelUserIfMissingData)
    .then((data) => data.words || []);

  function transformTopicsData (topics) {
    return topics.map((topic) => Object.assign({}, topic, {
      id: topic["phrase-id"],
      percents: topic["interest-portion"],
      percentValue: topic["interest-portion"].toFixed(1),
      value: topic["uniqueness-index"].toFixed(1),
      tooltip: genInterestTooltipStr(topic)
    }));
  }

  $scope.topicsPromise = topicsPromise.then(transformTopicsData).then((data) => _.orderBy(data, sortTypeToTopicKey[$scope.sortType], 'desc'));
  $scope.topicsPromise.then((topicsData) => {
    // Due to issue with 'onTopicChange' method invoke timing (no thisCtrl.topic data available) we should handle this case manually
    thisCtrl.topic = thisCtrl.topic || topicsData[0]; // Provide default topic value for correct initial render
    $scope.topicsData = topicsData;
    $scope.onSortTypeChange($scope.sortType);
    publishAllInterestsToView(true);
  });

  // * phrases/websites

  thisCtrl.topInterestsPromises = {};
  thisCtrl.testedInterestsByIdPromises = {}; // holds promise of tested phrases for delaying other promises until it resolves
  thisCtrl.readyTestedInterestsByIdPromises = {}; // holds only the last resolved tested phrases promise for getting

  context.current.audience_app[audienceChannel].audience_interestsToTest = context.current.audience_app[audienceChannel].audience_interestsToTest || {};

  // initial interests data retrievals

  $scope.interestTypes.map(t => t.value).reduce((promise, type) => {
    return promise.then(() => getTopInterestsDataByType(type).then(() => getPrevTestedInterestsDataByType(type)));
  }, $q.resolve());

  function getTopInterestsDataByType(type) {
    return thisCtrl.topInterestsPromises[type] = thisCtrl.topInterestsPromises[type] || getSegmentInterestsData(
      segment, QUERY_NAMES[type], audienceChannel, targetRatioObj
    ).then(throwForInvalidDataResponse).catch(expelUserIfMissingData)
      .then((data) => transformInterestsData(data.words || [], type === 'websites'));
  }

  function getPrevTestedInterestsDataByType(type) {
    return thisCtrl.testedInterestsByIdPromises[type] = thisCtrl.testedInterestsByIdPromises[type] || getTopInterestsDataByType(type).then(() => {
      if (!_.isEmpty(context.current.audience_app[audienceChannel].audience_interestsToTest[type]))
        return getAllTestedInterestsByIdByViewAndUpdateCache(
          context.current.audience_app[audienceChannel].audience_interestsToTest[type],
          {type, topicId: ALL_INTERESTS, refInterestsPromise: getPromiseOfInterestsByTypeAndTopic(type, ALL_INTERESTS)}
        );
      else
        return {forChart: [], forTable: []}
    }).then(indexByIdAndMergeByView);
  }

  function getPromiseOfInterestsByTypeAndTopic (type, topicId) {
    return getTopInterestsDataByType(type).then((dataByView) => _.mapValues(dataByView, (data) => data[topicId] || []));
  }

  // processing and publishing interest data

  function transformInterestsData (interests, addUrlsBasedOnInterestText = false) {
    const forChart = interests.map((interest) => ({
      ...interest,
      id: interest['id'] || interest['phrase-id'],
      title: interest['phrase'],
      portion: interest['interest-portion'],
      composition: interest['uniqueness-index'],
      value: interest['interest-portion'].toFixed(1) + '% | ' + interest['uniqueness-index'].toFixed(1),
      url: addUrlsBasedOnInterestText ? 'http://' + interest['phrase'] : undefined,
      tooltip: genInterestTooltipStr(interest)
    }));
    const forTable = interests.map((interest) => ({
      ...interest,
      id: interest['id'] || interest["phrase-id"],
      skewTooltip: genInterestSkewTooltipStr(interest)
    }));

    // group by topic for quick sort by user selection
    return _.mapValues({forChart, forTable}, (objs) => Object.assign(_.groupBy(objs, 'topic'), {[ALL_INTERESTS]: objs}));
  }

  function sortAndNormalizeInterests ({forChart, forTable}) {
    if (_.isEmpty(forChart) || _.isEmpty(forTable)) return {forChart, forTable};
    forChart.forEach((i) => i ? i.percents = i[$scope.sortType] : null);
    forChart = _.orderBy(_.orderBy(forChart, $scope.sortType, 'desc'), 'highlighted', 'asc');
    forChart = forChart.slice(0, 100); // chart cannot handle all interests - too slow.
    forChart = thisCtrl.infraUtil.normalize(forChart, 'percents', 'percents');
    return {forChart, forTable};
  }

  function allInterestsByTypeAndTopic (type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id) {
    if (topicId === ALL_INTERESTS)
      return getPromiseOfInterestsByTypeAndTopic(type, topicId).then((topInterestsByView) => {
        return getPromiseOfTestedInterestsByType(type).then((testedInterestsByView) => {
          return _.mergeWith(testedInterestsByView, topInterestsByView, (arr1, arr2) => _.uniqBy(arr1.concat(arr2), 'id'));
        });
      });
    return getPromiseOfInterestsByTypeAndTopic(type, topicId);
  }

  function publishAllInterestsToView (updateQueryObj, type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id) {
    if(!topicId || !type) return;

    // symbolize loading is performed.
    $scope.interests = null;
    $scope.tablesData = null;

    return allInterestsByTypeAndTopic(type, topicId)
      .then(sortAndNormalizeInterests)
      .then(({forChart, forTable}) => {
      // no need for publishing if the interestType is not the current one (if changed while loading)
      if(type !== $scope.interestType) return;

      $scope.interests = forChart;
      $scope.tablesData = forTable;
      if(!forChart.length) $scope.noDataMsg = $scope.getNoDataMsg();

      $scope.viewType = thisCtrl.topic && thisCtrl.topic.id === ALL_INTERESTS ? $scope.lastViewType : VIEW_TYPES_NAMES.chart;
      showBars($scope.viewType === VIEW_TYPES_NAMES.chart);
      if (updateQueryObj) $timeout(() => {
        $scope.query.show(forTable);
      });

      resetSelection();

    });
  }

  // error handling

  function throwForInvalidDataResponse (data) {
    if (!data) throw 'noData';
    switch (data.status) {
      case 'noData':
        throw 'noData';
      case 'segment too wide':
      case 'tooWide':
        throw 'tooWide';
      case 'insufficient data':
      case 'tooNarrow':
        throw 'tooNarrow';
    }
    if (_.isEmpty(data.words)) throw 'noData';

    return data;
  }

  const tooWideMsg = 'The audience you have selected is too wide for Interests analysis. Please refine your audience criteria.';
  const tooNarrowMsg = 'The audience you have selected is too narrow for Interests analysis. Please refine your audience criteria.';

  function expelUserIfMissingData (reason) {
    switch (reason) {
      case 'tooNarrow':
        openNavBackModal(tooNarrowMsg, 'Audience is too narrow');
        break;
      case 'tooWide':
        openNavBackModal(tooWideMsg, 'Audience is too wide');
        break;
    }
    return $q.reject(reason);
  }

  function openNavBackModal(msg, title) {
    $scope.navBackModal = {
        isOpen: true,
        title: title,
        message: msg
    };
  }

  // view prep: vars & fns

  function showBars (show) {
    let bars = angular.element(document.querySelector('selectable-bar-chart div.scroll-container'));
    show ? bars.show() : bars.hide();
  }

  function setTableHead (type) {
    // Dynamically set quick-table properties.
    $scope.query.columns[0].title = type;
    $scope.query.title = getTabType(type);
    $scope.isCheckboxesNeeded = $scope.showTargets;

    switch ($scope.interestType) {
      case 'websites':
        $scope.query.columns[0].template = 'partials/audience-domain-select.partial.html';
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience visits the website';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience visits the website';
        break;
      case 'phrases':
        $scope.query.columns[0].template = null;
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';
        $scope.query.doSummary = () => {};
        break;
      case 'searches':
      case 'searchesBidStream':
        $scope.query.columns[0].template = null;
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the search term';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the search term';
        $scope.query.doSummary = () => {};
        break;
    }
  }

  const genInterestSkewTooltipStr = ({phrase, "uniqueness-index": skew}) => {
    switch (+skew.toFixed(1)) {
      case 0:
        return `Consumers of ${phrase} are unlikely to be in the target audience`;
      case 1:
        return `Consumers of ${phrase} are as likely to be in the target audience as the average consumer`;
      default:
        const [multiplier, compare] = skew > 1 ? [skew, 'more'] : [1 / skew, 'less'];
        return `Consumers of ${phrase} are ×${multiplier.toFixed(1)} times ${compare} likely to be in the target audience than the average consumer`;
    }
  };

  const genInterestConsumptionTooltipStr = ({phrase, "interest-portion": consumption}) => {
    const clientsVerb = $scope.interestType === 'websites' ? 'consumed' : 'are interested in';
    return `${consumption.toFixed(1)}% of the target audience ${clientsVerb} ${phrase}`;
  };

  function genInterestTooltipStr (obj) {
    return genInterestConsumptionTooltipStr(obj) + '<br/>' + genInterestSkewTooltipStr(obj);
  }

  function resetSelection () {
    if ($scope.query && $scope.query.selected && $scope.query.selected.length) {
      $scope.query.selected = [];
      $scope.query.dataArray.forEach((row) => row.selected = false);
      ($scope.interests||[]).forEach((row) => row.selected = false);
    }
    setTimeout(() => {
      $scope.selectedMax = $scope.viewType === VIEW_TYPES_NAMES.chart ? ($scope.interests || []).length : $scope.query.dataArray.length;
      $scope.$digest();
    });

    const selectAllCheckboxEl = $("#select-all-checkbox");
    if (selectAllCheckboxEl.attr("checked") === "checked") selectAllCheckboxEl.trigger("click");
  }

  function getTabType(tab){
    return tab == 'searches' ? 'phrases' : tab; //same tab for targets
  }

  $scope.getNoDataMsg = (type = $scope.interestType) => {
    return `Sorry, we couldn't find relevant ${type && $scope.interestTypesMap[type].plural} on this topic for your target audience.`
  };

  $scope.disableSearches = () => false;

  $scope.calcLeft = () => {
    let barTitle = angular.element(document.querySelector('selectable-bar-chart .chart-title'));
    return `${$scope.title ? barTitle.width() + 20 + 20 : 0}px`;
  };

  // view data handling events/callbacks

  $scope.onTopicChange = (topic) => {
    thisCtrl.topic = topic;
    $scope.title = topic.id;

    MixpanelAudience.trackAudienceExplorerTopicClick($scope.title, 'audience-explorer', audienceChannel);

    return publishAllInterestsToView(true);
  };

  $scope.onInterestTypeChange = (type) => {
    $scope.tab = getTabType(type);
    $scope.tabs = [$scope.tab];
    $scope.showTargets = $scope.isShowTargets();
    setTableHead(type);
    if (thisCtrl.topic) publishAllInterestsToView(true);

    if($scope.showTargets) {
      if($scope.tab != $scope.prevTab){
        TargetsCommon.getTargets({scope: $scope, $state: $state, tab: $scope.tab});
      }
    }
    $scope.prevTab = $scope.tab;

    resetSelection();

    MixpanelAudience.trackInterestTypeChanged(type, audienceChannel);
  };

  $scope.onViewTypeChange = (type) => {
    $scope.lastViewType = type;
    resetSelection();
    if (type === VIEW_TYPES_NAMES.table) $scope.query.show($scope.tablesData);
    showBars(type === VIEW_TYPES_NAMES.chart);
  };

  $scope.onInterestPhrasesSelectAll = (value) => {
    MixpanelAudience.trackInterestPhrasesSelectAll(value, 'audience interests phrases', audienceChannel);
  };

  $scope.onSortTypeChange = (type) => {
    MixpanelAudience.trackViewChange(type, 'audience interests', '', audienceChannel);
    MixpanelAudience.trackDemographicsInterestsView(type, 'interests', audienceChannel);
    $scope.sortType = type;
    if ($scope.topicsData) {
      $scope.topicsData.forEach((i) => i.percents = type === 'portion' ? i.percentValue : i.value);
      thisCtrl.infraUtil.normalize($scope.topicsData, 'percents', 'percents');
      const compareFunction = (obj) => convertStringToFloat(obj[sortTypeToTopicKey[type]]);
      $scope.topicsData = _.orderBy($scope.topicsData, compareFunction , 'desc');
    }

    publishAllInterestsToView(false);

    resetSelection();
  };

  // test-an-interest

  $scope.newlyAddedInterestsToTest = {}; // by type; bound to the test-an-interest input-bars, to contain its values
  $scope.testedInterestsLoadingPromise = {}; // last promise by type

  context.current.audience_app[audienceChannel].audience_interestsToTest =  // searched interests to test saved by type
    context.current.audience_app[audienceChannel].audience_interestsToTest || {};

  function getPromiseOfTestedInterestsByType (type = $scope.interestType) {
    return (thisCtrl.readyTestedInterestsByIdPromises[type] || getPrevTestedInterestsDataByType(type))
      .then((testedInterestsCache) => _.mapValues(testedInterestsCache, Object.values));
  }

  function getAllTestedInterestsByIdByViewAndUpdateCache (searchResults, {type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id, refInterestsPromise = allInterestsByTypeAndTopic(type, topicId)}) {
    return refInterestsPromise.then((refInterestsByView) => {
      // get from api only interests that do not already exist in top interests
      const existingTestedInterests = _.mapValues(refInterestsByView, (interests) => interests.filter((i) => searchResults.some((r) => r.id === i.id)));
      const existingTestedInterestIds = existingTestedInterests.forTable.map((i) => i.id);
      const nonexistingInterestsToTest = searchResults.filter((r) => !existingTestedInterestIds.includes(r.id));

      // calc min and max g values in current interests (of type) for score calculation in server; prepare obj for sending
      const refInterestGValues = refInterestsByView.forTable.map((w) => w.g);
      const gInfoObj = {"max-g": Math.max(...refInterestGValues), "min-g": Math.min(...refInterestGValues)};
      const extraParams = {...gInfoObj, ...targetRatioObj};

      const {[true]: advancedInterestsToTest, [false]: regularInterestsToTest} = _.groupBy(nonexistingInterestsToTest, (r) => r.type === 'programBL' || r.type === 'booleanLogic');
      const testedRegularInterestsPromise = testRegularInterests(type, regularInterestsToTest, extraParams) || $q.resolve([]);
      const testedAdvancedInterestsPromise = testAdvancedInterests(type, advancedInterestsToTest, extraParams) || $q.resolve([]);

      return $q.all([testedRegularInterestsPromise, testedAdvancedInterestsPromise]).then(([testedRegularInterests, testedAdvancedInterests]) => {
        const allTestedInterests = testedRegularInterests.concat(testedAdvancedInterests);
        const newTestedInterestsByTopic = transformInterestsData(allTestedInterests, type === 'websites');
        const newTestedInterests = _.mapValues(newTestedInterestsByTopic, (interests) => interests[topicId]);

        // combine new-found interests from api and top existing, and mark as highlighted for view
        const defaultTestedInterestProps = {highlighted: true, deletable: true, highlightMarkTooltip: 'Manually added'};
        const allTestedInterestsByView = _.mergeWith(existingTestedInterests, newTestedInterests, (arr1, arr2) => arr2.concat(arr1 || []));
        return _.mapValues(allTestedInterestsByView, (interests) => interests.map((i) => Object.assign({}, i, defaultTestedInterestProps)));
      });
    });
  }

  function testRegularInterests(type, interestsToTest, extraParams = {}) {
    if(_.isEmpty(interestsToTest)) return;

    const idToTextMap = _.mapValues(_.keyBy(interestsToTest, 'id'), 'text');
    return getSegmentInterestsDataByIds(
      segment, QUERY_NAMES_V2[type], audienceChannel, interestsToTest.map((r) => r.id), extraParams
    ).then(throwForInvalidDataResponse).catch(continueIfNoData)
      .then(({words = []}) => words.map((i) => Object.assign(i, {phrase: idToTextMap[i.id] || i.id})));
  }

  function testAdvancedInterests(type, interestsToTest, extraParams = {}) {
    if(_.isEmpty(interestsToTest)) return;
    // mixpanel event
    MixpanelAudience.trackTestingInterests(type, interestsToTest, true, audienceChannel);

    const idToTextMap = _.mapValues(_.keyBy(interestsToTest, 'id'), 'text');
    const advancedInterestsObjsForReq = interestsToTest.map((o) => ({
      id: o.id, required: (o.required || []).map((r) => r.id), included: (o.included || []).map((i) => i.id), excluded: (o.excluded || []).map((e) => e.id)
    })); // api expects getting only id (which returned back), required (= and), included (= or), excluded (= not); values are ids only
    return getSegmentAdvancedInterestsDataByQueries(
      segment, QUERY_NAMES_V2[type], audienceChannel, advancedInterestsObjsForReq, extraParams
    ).then(throwForInvalidDataResponse).catch(continueIfNoData)
      .then(({words: interests = []}) => {
        const disabledTooltip = "Refined interests are inapplicable for targeting.";
        return interests.map((i) => Object.assign(i, {phrase: idToTextMap[i.id], disabled: true, disabledTooltip}));
      });
  }

  const continueIfNoData = (errMsg) => errMsg === 'noData' || errMsg === 'tooNarrow' ? $q.resolve({}) : $q.reject(errMsg);

  function indexByIdAndMergeByView (interestsByView, lastInterestsByIdByView) {
    const interestsByIdByView = _.mapValues(interestsByView, (interests) => _.keyBy(interests, 'id'));
    if (!lastInterestsByIdByView) return interestsByIdByView;
    return _.mergeWith(lastInterestsByIdByView, interestsByIdByView, (o1, o2) => Object.assign(o1, o2));
  }

  function notifyForMissingInterestsToTest (testedInterestsByView, searchResults) {
    const isAnyTested = testedInterestsByView.forTable && !_.isEmpty(testedInterestsByView.forTable);
    const missingInterestsToTest = isAnyTested ? searchResults.filter((r) => !testedInterestsByView.forTable.find((i) => i.id === r.id)) : searchResults;

    if (missingInterestsToTest && missingInterestsToTest.length)
      notificator.notify(`Sorry, insufficient data about ${missingInterestsToTest.map((r) => r.text).join(', ')}`);

    return testedInterestsByView;
  }

  for (let {value: type} of $scope.interestTypes) {
    $scope.$watch(`newlyAddedInterestsToTest.${type}`, (searchResults) => {
      if (_.isEmpty(searchResults)) return;

      const pastInterestsToTest = context.current.audience_app[audienceChannel].audience_interestsToTest[type];
      context.current.audience_app[audienceChannel].audience_interestsToTest[type] = (pastInterestsToTest || [])
        .concat(_.differenceBy(searchResults, pastInterestsToTest, 'id'));

      thisCtrl.readyTestedInterestsByIdPromises[type] = thisCtrl.testedInterestsByIdPromises[type];
      const testedInterestsPromise = getAllTestedInterestsByIdByViewAndUpdateCache(searchResults, {type, topicId: ALL_INTERESTS});

      thisCtrl.testedInterestsByIdPromises[type] = $q.all([testedInterestsPromise, thisCtrl.testedInterestsByIdPromises[$scope.interestType]])
        .then(([testedInterestsByView, lastTestedInterestsByIdByView]) => indexByIdAndMergeByView(testedInterestsByView, lastTestedInterestsByIdByView));

      $scope.testedInterestsLoadingPromise[type] = $q.all([testedInterestsPromise, thisCtrl.testedInterestsByIdPromises[type]])
        .then(([testedInterestsByView]) => notifyForMissingInterestsToTest(testedInterestsByView, searchResults))
        .then(() => publishAllInterestsToView(true))
        .then(() => { $scope.newlyAddedInterestsToTest[type] = [] }); // clear input-bar

      // mixpanel event
      MixpanelAudience.trackTestingInterests(type, searchResults, false, audienceChannel);
    });
  }

  $scope.deleteATestedInterest = (id, interest) => {
    const deleteInterestPromise = thisCtrl.testedInterestsByIdPromises[$scope.interestType].then(testedInterestsById => {
      // remove from tested interests pools
      delete testedInterestsById.forChart[id];
      delete testedInterestsById.forTable[id];
      // remove from 'snapshot'/'context'
      _.remove(context.current.audience_app[audienceChannel].audience_interestsToTest[$scope.interestType], (r) => r.id === id);

      return testedInterestsById;
    });
    thisCtrl.testedInterestsByIdPromises[$scope.interestType] = deleteInterestPromise; // chain
    // refresh view
    deleteInterestPromise.then(() => { publishAllInterestsToView(false) });
  };

  // export file

  const demographicsPromise = getFullDemographicsDataWithGenderAgeBySegment(segment, {channel: audienceChannel});

  $scope.export = () => {
    const allPhrasesPromise = allInterestsByTypeAndTopic('phrases', ALL_INTERESTS).then(d => d.forTable);
    const allWebsitesPromise = allInterestsByTypeAndTopic('websites', ALL_INTERESTS).then(d => d.forTable);
    const allSearchesPromise = includeSearches ? allInterestsByTypeAndTopic(isFirstParty ? 'searchesBidStream' : 'searches', ALL_INTERESTS).then(d => d.forTable) : $q.resolve();
    $q.all([topicsPromise, allWebsitesPromise, allPhrasesPromise, allSearchesPromise, demographicsPromise])
      .then(([topics, websites, phrases, searches, distribution]) => {
        topics = _.orderBy(topics, 'interest-portion', 'desc');
        let excel = segmentInterestExportService.exportToExcel(topics, websites, phrases, searches, segment, distribution, audienceChannel);
        segmentInterestExportService.downloadExcel(excel);
        MixpanelAudience.trackExport(audienceChannel);
      })
      .catch(expelUserIfMissingData);
  };

  $scope.exportControl.getAudienceInterestsExportDependencies = () => {
    const allPhrasesPromise = allInterestsByTypeAndTopic('phrases', ALL_INTERESTS).then(d => {
      return d.forTable;
    });
    const allWebsitesPromise = allInterestsByTypeAndTopic('websites', ALL_INTERESTS).then(d => d.forTable);
    const allSearchesPromise = includeSearches ? allInterestsByTypeAndTopic(isFirstParty ? 'searchesBidStream' : 'searches', ALL_INTERESTS).then(d => d.forTable) : $q.resolve();

    return $q.all([topicsPromise, allWebsitesPromise, allPhrasesPromise, allSearchesPromise, demographicsPromise]);
  }

  $scope.$root.createExcelWorkbook = $scope.exportControl.export || $scope.export;
}

module.exports = require("angular").module(__filename, [
  require('data/segment-interest-export-service').name,
  require("../../common/topic-selector.drv/topic-selector.drv").name,
  require("widgets/audience-table-view-widget/audience-table-struct").name,
  require("../../common/selectable-bar-chart.drv/selectable-bar-chart.drv").name,
  require('common/modals/confirmation/confirm-action.modal.service').name,
])
  .directive("audienceInterestsWidget", [() => BaseWidget({
    restrict: "E",
    template: require("./audience-interests-widget.html"),
    scope: {
      handleActivatingAudience: '&',
      handleActivatingTarget: '&',
      navToPrevTab: '&',
      navToAudienceBuilder: '&',
      exportControl: '=',
      selectedTab: '=',
    },
    controller: AudienceInterestsWidgetController,
  })]);
